<template>
  <div>
    <div class="container is-fullheight" style="background-color: #f2f2f2">
      <div
        class="section pt-2"
        style="background-color: white; height: 100%"
        ref="createRequestType"
      >
        <b-collapse
          class="card my-2"
          animation="slide"
          :open="true"
          aria-id="contentIdForA11y3"
          style="background-color: #f9f9f9"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              aria-controls="contentIdForA11y3"
              :aria-expanded="props.open"
            >
              <p class="card-header-title has-text-secondary">
                <b-icon icon="info-circle"></b-icon>Información
              </p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'">
                </b-icon>
              </a>
            </div>
          </template>

          <div class="card-content py-0">
            <div class="content has-text-secondary">
              <ul>
                <li>
                  <b>Estudiante Titulado(a) FING:</b> Estudiante que haya
                  finalizado sus estudios y aprobado el examen de grado en su
                  Departamento Académico de la Facultad de Ingeniería de la
                  USACH.
                </li>
                <li>
                  <b>Estudiante No Titulado(a):</b> Estudiante que no dio
                  término a sus estudios.
                </li>
                <li>
                  <b>Estudiante Regular FING:</b> Estudiante que se encuentra
                  matriculado(a) en la Facultad de Ingeniería
                </li>
                <li>
                  <b>Estudiante No Regular FING:</b> Estudiante que no se
                  encuentra matriculado(a) en la Facultad de Ingeniería.
                </li>
                <li>
                  <b>Planes y programas:</b> Tipo de solicitud común, cualquier
                  estudiante puede realizar la solicitud.
                </li>
              </ul>
              Para más información dirigete a la
              <a href="./faq"><u>página de preguntas frecuentes.</u></a
              ><br /><br />
            </div>
          </div>
        </b-collapse>

        <!-- Titulo de la sección -->

        <div class="pt-2">
          <div class="columns is-centered mt-2">
            <div class="column is-3 has-text-centered">
              <b-button
                class="is-gcc is-fullwidth is-border-black"
                icon-left="shopping-cart"
                @click="isModalPaymentInfoOpen = !isModalPaymentInfoOpen"
              >
                Información de Pagos
              </b-button>
            </div>
          </div>

          <h1
            class="subtitle is-2 is-bold has-text-secondary has-text-centered mb-1"
          >
            Solicitudes disponibles
          </h1>
          <hr class="solid mt-0 px-5" />

          <div
            v-if="requestTypes.length === 0 && !isLoading"
            class="columns is-multiline mt-2"
          >
            <b-message
              title="No hay solicitudes"
              type="is-warning"
              has-icon
              aria-close-label="Close message"
            >
              No hay solicitudes disponibles para iniciar con su cargo principal
            </b-message>
          </div>
          <div v-if="!moduleEnabled" class="columns is-centered mt-2">
            <b-message
              class="column is-vcentered"
              type="is-danger"
              icon-size="is-medium"
              has-icon
            >
              Actualmente este módulo no se encuentra recibiendo solicitudes.
            </b-message>
          </div>

          <div v-else>
            <div v-if="requestTypes.length > 0" class="columns mb-0">
              <div class="column has-text-centered">
                <p class="title is-5">Seleccione una opción:</p>
              </div>
            </div>
            <b-tabs
              type="is-toggle mb-0"
              v-model="roleTab"
              position="is-centered"
              @input="set_default_state()"
              :destroy-on-hide="true"
            >
              <b-tab-item
                v-if="
                  get_origin_request_types('PREGRADO - TITULADO', null).length >
                    0 ||
                  get_origin_request_types('PREGRADO - NO TITULADO', 'REGULAR')
                    .length > 0 ||
                  get_origin_request_types(
                    'PREGRADO - NO TITULADO',
                    'NO REGULAR'
                  ).length > 0
                "
                label="Pregrado"
                value="PREGRADO"
              >
                <b-tabs
                  type="is-toggle"
                  class="mytabs"
                  v-model="secondLevel"
                  position="is-centered"
                  @input="set_default_state()"
                >
                  <b-tab-item
                    v-if="
                      get_origin_request_types('PREGRADO - TITULADO', null)
                        .length > 0
                    "
                    label="Titulado"
                    value="PREGRADO - TITULADO"
                  >
                    <ListRequestTypeCard
                      :request-types="
                        this.get_origin_request_types(
                          'PREGRADO - TITULADO',
                          null
                        )
                      "
                      background-color="has-background-white"
                      title-text-color="has-text-gcc"
                      border-color="is-border-gcc"
                      button-type="is-secondary"
                      border-top-content-color="is-border-top-gcc"
                    ></ListRequestTypeCard>
                  </b-tab-item>
                  <b-tab-item
                    v-if="
                      get_origin_request_types(
                        'PREGRADO - NO TITULADO',
                        'REGULAR'
                      ).length > 0 ||
                      get_origin_request_types(
                        'PREGRADO - NO TITULADO',
                        'NO REGULAR'
                      ).length > 0
                    "
                    label="No titulado"
                    value="PREGRADO - NO TITULADO"
                  >
                    <b-tabs
                      v-model="roleStateTab"
                      position="is-centered"
                      type="is-toggle-rounded"
                    >
                      <b-tab-item label="Regular" value="REGULAR" class="">
                        <ListRequestTypeCard
                          :request-types="
                            this.get_origin_request_types(
                              'PREGRADO - NO TITULADO',
                              'REGULAR'
                            )
                          "
                          background-color="has-background-white"
                          title-text-color="has-text-gcc"
                          border-color="is-border-gcc"
                          button-type="is-secondary"
                          border-top-content-color="is-border-top-gcc"
                        ></ListRequestTypeCard>
                      </b-tab-item>
                      <b-tab-item label="No regular" value="NO REGULAR">
                        <ListRequestTypeCard
                          :request-types="
                            this.get_origin_request_types(
                              'PREGRADO - NO TITULADO',
                              'NO REGULAR'
                            )
                          "
                          background-color="has-background-white"
                          title-text-color="has-text-gcc"
                          border-color="is-border-gcc"
                          button-type="is-secondary"
                          border-top-content-color="is-border-top-gcc"
                        ></ListRequestTypeCard>
                      </b-tab-item>
                    </b-tabs>
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
              <b-tab-item
                v-if="
                  get_origin_request_types('POSTGRADO', 'REGULAR').length > 0 ||
                  get_origin_request_types('POSTGRADO', 'NO REGULAR').length > 0
                "
                label="Postgrado"
                value="POSTGRADO"
              >
                <b-tabs
                  v-model="roleStateTab"
                  position="is-centered"
                  type="is-toggle-rounded"
                  v-if="
                    get_origin_request_types('POSTGRADO', 'REGULAR').length >
                      0 ||
                    get_origin_request_types('POSTGRADO', 'NO REGULAR').length >
                      0
                  "
                >
                  <b-tab-item
                    v-if="
                      get_origin_request_types('POSTGRADO', 'REGULAR').length >
                      0
                    "
                    label="Regular"
                    value="REGULAR"
                  >
                    <ListRequestTypeCard
                      :request-types="
                        this.get_origin_request_types('POSTGRADO', 'REGULAR')
                      "
                      background-color="has-background-white"
                      title-text-color="has-text-gcc"
                      border-color="is-border-gcc"
                      button-type="is-secondary"
                      border-top-content-color="is-border-top-gcc"
                    ></ListRequestTypeCard>
                  </b-tab-item>
                  <b-tab-item
                    v-if="
                      get_origin_request_types('POSTGRADO', 'NO REGULAR')
                        .length > 0
                    "
                    label="No regular"
                    value="NO REGULAR"
                  >
                    <ListRequestTypeCard
                      :request-types="
                        this.get_origin_request_types('POSTGRADO', 'NO REGULAR')
                      "
                      background-color="has-background-white"
                      title-text-color="has-text-gcc"
                      border-color="is-border-gcc"
                      button-type="is-secondary"
                      border-top-content-color="is-border-top-gcc"
                    ></ListRequestTypeCard>
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
              <b-tab-item
                v-if="
                  get_origin_request_types('EDUCO', 'REGULAR').length > 0 ||
                  get_origin_request_types('EDUCO', 'NO REGULAR').length > 0
                "
                label="EDUCO"
                value="EDUCO"
              >
                <b-tabs
                  v-model="roleStateTab"
                  position="is-centered"
                  type="is-toggle-rounded"
                >
                  <b-tab-item
                    v-if="
                      get_origin_request_types('EDUCO', 'REGULAR').length > 0
                    "
                    label="Regular"
                    value="REGULAR"
                  >
                    <ListRequestTypeCard
                      :request-types="
                        this.get_origin_request_types('EDUCO', 'REGULAR')
                      "
                      background-color="has-background-white"
                      title-text-color="has-text-gcc"
                      border-color="is-border-gcc"
                      button-type="is-secondary"
                      border-top-content-color="is-border-top-gcc"
                    ></ListRequestTypeCard>
                  </b-tab-item>

                  <b-tab-item
                    v-if="
                      get_origin_request_types('EDUCO', 'NO REGULAR').length > 0
                    "
                    label="No regular"
                    value="NO REGULAR"
                  >
                    <ListRequestTypeCard
                      :request-types="
                        this.get_origin_request_types('EDUCO', 'NO REGULAR')
                      "
                      background-color="has-background-white"
                      title-text-color="has-text-gcc"
                      border-color="is-border-gcc"
                      button-type="is-secondary"
                      border-top-content-color="is-border-top-gcc"
                    ></ListRequestTypeCard>
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
              <b-tab-item
                v-if="
                  get_origin_request_types('PLANES Y PROGRAMAS', null).length >
                  0
                "
                label="Planes y programas"
                value="PLANES Y PROGRAMAS"
              >
                <ListRequestTypeCard
                  :request-types="
                    this.get_origin_request_types('PLANES Y PROGRAMAS', null)
                  "
                  background-color="has-background-white"
                  title-text-color="has-text-gcc"
                  border-color="is-border-gcc"
                  button-type="is-secondary"
                  border-top-content-color="is-border-top-gcc"
                ></ListRequestTypeCard>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>

        <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>
      </div>
    </div>

    <b-modal
      v-model="isModalPaymentInfoOpen"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <PaymentInfoModal @close="props.close"></PaymentInfoModal>
      </template>
    </b-modal>
  </div>
</template>
<style>
.tabs.is-toggle a {
  border-color: black;
}
.tabs.is-toggle a:hover {
  border-color: black;
}
</style>
<script>
import axios from "axios";
import RequestTypeCard from "./RequestTypeCard.vue";
import ListRequestTypeCard from "./ListRequestTypeCard.vue";

export default {
  name: "ChooseRequestTypeGcc",
  components: {
    ListRequestTypeCard,
    PaymentInfoModal: () => import("../RequestType/PaymentInfoModal.vue"),
    RequestTypeCard,
  },
  data: () => {
    return {
      requestTypes: [],
      isLoading: false,
      roleTab: null,
      secondLevel: null,
      roleStateTab: null,
      moduleEnabled: false,
      isModalPaymentInfoOpen: false,
    };
  },
  created() {
    document.title = "GCC | Iniciar una solicitud";

    this.isLoading = true;

    axios
      .get("/gcc/request_types.json")
      .then((response) => {
        this.requestTypes = response.data.request_types;
        this.moduleEnabled = response.data.module_enabled;
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
  methods: {
    set_default_state() {
      this.secondLevel = null;
      this.roleStateTab = null;
    },
    get_origin_request_types(role_type, role_state) {
      let request_types = this.requestTypes.map(function (request_type) {
        if (request_type.origin_info.role_type === role_type) {
          if (
            request_type.origin_info.role_type !== "PREGRADO - TITULADO" &&
            request_type.origin_info.role_type !== "PLANES Y PROGRAMAS"
          ) {
            if (request_type.origin_info.role_state === role_state) {
              return request_type;
            }
          } else {
            return request_type;
          }
        }
      }, this);
      request_types = request_types.filter((element) => {
        return element !== undefined;
      });
      return request_types;
    },
  },
};
</script>
<style scoped></style>
