<template>
  <div>
    <div class="modal-card" style="width: 100%">
      <header class="modal-card-head">
        <p class="modal-card-title">Respuesta a comentarios</p>

        <button
          type="button"
          class="delete"
          @click="$emit('close')"
          style="background: #ff3860"
        />
      </header>

      <!---->
      <section class="modal-card-body">
        <div v-if="canAppeal()">
          <div class="columns is-multiline pt-2 is-vcentered">
            <div class="column is-full mb-2">
              <div class="card px-2">
                <div class="columns card-content p-0 is-multiline">
                  <div class="column is-12 pb-1">
                    <b>Último comentario solicitante:</b>
                    {{
                      appeals.length > 0
                        ? appeals[0].applicant_observation
                        : "No existe observación"
                    }}
                  </div>
                  <div class="column is-12 pt-0">
                    <b>Fecha del último comentario:</b>
                    {{
                      appeals.length > 0
                        ? formatCreatedAt(appeals[0].created_at)
                        : "No existe observación"
                    }}
                  </div>
                </div>
                <div class="columns">
                  <div class="column has-text-left">
                    <template v-if="appeals.length > 0">
                      <template v-if="appeals[0].id_drive !== null">
                        <b-button
                          type="is-primary mx-1"
                          icon-right="eye"
                          @click="openModalShowDocument(appeals[0].id_drive)"
                        >
                          Ver Documento
                        </b-button>
                        <b-button
                          class="is-centered"
                          type="is-secondary"
                          @click="downloadFile(appeals[0].id_drive)"
                        >
                          Descargar documento<i
                            class="fas fa-download ml-1"
                          ></i>
                        </b-button>
                      </template>

                      <b-button
                        v-else
                        class="is-centered"
                        type="is-primary"
                        disabled
                      >
                        No se adjuntó documento
                        <i class="fas fa-download ml-1"></i>
                      </b-button>
                    </template>
                    <b-button
                      v-else
                      class="is-centered"
                      type="is-primary"
                      disabled
                    >
                      No se adjuntó documento<i
                        class="fas fa-download ml-1"
                      ></i>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <b-field label="Comentario a la corrección de quien solicita*">
                <ValidationProvider
                  rules="required|max:4000"
                  v-slot="{ errors }"
                >
                  <b-input
                    class="mx-0"
                    type="textarea"
                    maxlength="4000"
                    v-model="inputObservation"
                    placeholder="Se acepta/rechaza porque..."
                  >
                  </b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column is-full" style="margin-top: -30px">
              <b-button
                type="is-ghost is-border-light-grey"
                @click="inputObservation += 'Se aprueba la corrección.'"
                >Se aprueba la corrección.</b-button
              >
              <b-button
                type="is-ghost is-border-light-grey"
                @click="inputObservation += 'Se rechaza porque...'"
                >Se rechaza porque...</b-button
              >
            </div>

            <div class="column is-one-quarter">
              <b-button
                class="is-secondary is-light is-border-darkgreen"
                v-if="!showHistory"
                icon-left="eye"
                @click="showHistory = !showHistory"
              >
                Mostrar historial de respuestas
              </b-button>
              <b-button
                v-else
                class="is-info is-light is-border-cornflowerblue"
                icon-left="eye-slash"
                @click="showHistory = !showHistory"
              >
                Ocultar historial de respuestas
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="showHistory || !canAppeal()">
          <hr v-if="canAppeal()" class="solid" />
          <div class="columns is-multiline pt-2">
            <div class="column is-full has-text-centered">
              <h1 class="subtitle is-3 has-text-secondary">
                Historial de respuestas
              </h1>
            </div>
          </div>
          <b-table
            :paginated="appeals.length < 5 ? false : true"
            :pagination-simple="false"
            pagination-position="bottom"
            :current-page="currentPageTable"
            per-page="5"
            :data="appeals"
            :bordered="false"
            :striped="true"
            :narrowed="false"
            :hoverable="true"
            :focusable="false"
            :mobile-cards="true"
          >
            <b-table-column
              field="applicant_observation"
              :visible="true"
              label="Comentario solicitante"
              v-slot="props"
            >
              {{ props.row.applicant_observation }}
            </b-table-column>
            <b-table-column
              field="reviewer_observation"
              :visible="true"
              label="Comentario revisor"
              v-slot="props"
            >
              {{
                props.row.reviewer_observation != null
                  ? props.row.reviewer_observation
                  : "La corrección aún no ha sido contestada"
              }}
            </b-table-column>
            <b-table-column
              centered
              field="is_accepted"
              :visible="true"
              label="¿Aceptado?"
              v-slot="props"
            >
              <b-tag
                v-if="props.row.reviewer_observation === null"
                icon="clock"
                type="is-warning"
                rounded
                size="is-medium"
              >
                Pendiente
              </b-tag>
              <b-tag
                v-else-if="props.row.is_accepted"
                icon="check"
                type="is-success"
                rounded
                size="is-medium"
              >
                Sí
              </b-tag>
              <b-tag
                v-else
                icon="times"
                type="is-danger"
                rounded
                size="is-medium"
              >
                No
              </b-tag>
            </b-table-column>
            <b-table-column
              field="id_drive"
              :visible="true"
              label="Documento subido"
              v-slot="props"
              centered
            >
              <template class="is-centered" v-if="props.row.id_drive !== null">
                <b-button
                  type="is-primary mx-1"
                  icon-right="eye"
                  @click="openModalShowDocument(props.row.id_drive)"
                >
                  Ver Documento
                </b-button>
                <b-button
                  type="is-secondary"
                  @click="downloadFile(props.row.id_drive)"
                >
                  Descargar documento <i class="fas fa-download ml-1"></i>
                </b-button>
              </template>
              <div v-else>
                <b-button class="is-centered" type="is-primary" disabled>
                  No se adjuntó documento <i class="fas fa-download ml-1"></i>
                </b-button>
              </div>
            </b-table-column>
          </b-table>
        </div>
      </section>

      <footer class="modal-card-foot columns">
        <div class="column has-text-right">
          <b-button
            type="is-danger"
            icon-left="times"
            native-type="submit"
            @click="confirmObservation(false)"
            :disabled="!canAppeal() || disableButton || inputObservation === ''"
          >
            Rechazar correcciones
          </b-button>
          <b-button
            type="is-success"
            icon-left="file-import"
            native-type="submit"
            @click="confirmObservation(true)"
            :disabled="!canAppeal() || disableButton || inputObservation === ''"
          >
            Aceptar correcciones
          </b-button>
        </div>
      </footer>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-modal
      v-model="isModalShowDocumentActive"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <preview-program-modal :docId="src" @close="props.close">
        </preview-program-modal>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import PreviewProgramModal from "../../Documents/PreviewProgramModal.vue";
import moment from "moment";

export default {
  name: "ModalAnswerObservationReviewer",
  props: ["request_state_id", "in_progress", "request_code", "applicant_run"],
  components: { PreviewProgramModal },
  data: function () {
    return {
      inputObservation: "",
      responseData: [],
      //---------------------------------
      validationErrors: {},
      isLoading: false,
      form_link: "",
      document: [],
      appeals: [],
      formData: {},
      currentPageTable: 1,
      disableButton: false,
      accepted: false,
      showHistory: false,
      isModalShowDocumentActive: false,
      src: "",
    };
  },

  async created() {
    this.isLoading = true;
    await this.getAppeals();
  },

  methods: {
    confirmObservation(accepted) {
      this.accepted = accepted;
      let text = this.accepted ? "aceptar" : "rechazar";
      this.$buefy.dialog.confirm({
        title: `Confirmación de ${this.accepted ? "aceptación" : "rechazo"}`,
        message: `¿Desea  ${text} esta observación? Luego de confirmar no podrá modificar la respuesta`,
        cancelText: "Cerrar",
        confirmText: `Enviar ${this.accepted ? "aceptación" : "rechazo"}`,
        type: this.accepted ? "is-success" : "is-danger",
        onConfirm: () => this.answerObservation(),
      });
    },
    async getAppeals() {
      return await axios
        .get("/appeals/get_state_appeals/" + this.request_state_id + ".json")
        .then((response) => {
          this.appeals = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.appeals = [];
          this.isLoading = false;
        });
    },
    formatDate(date) {
      if (date != null && date !== "" && date !== undefined) {
        var arreglo = date.split("-");
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
      } else {
        return "";
      }
    },
    async openModalShowDocument(driveId) {
      this.src = driveId;
      this.isModalShowDocumentActive = true;
    },
    downloadFile(document_id) {
      this.isLoading = true;
      axios
        .request({
          url: "/documents/download_file/" + document_id,
          responseType: "blob",
        })
        .then((response) => {
          //var blobUrl = URL.createObjectURL(response.data);
          //window.open(blobUrl)

          let info = response.headers["content-disposition"];
          let name = info.split('"')[1];
          name = name.replace(
            /ID_\d+_IDSOL_\d+_P_\d+_\d+/,
            `SOL-${this.request_code}`
          );
          // let name = `${this.request_code}_${this.applicant_run}_Respuesta-Detención.${decodeURIComponent(info.split('"')[1]).split(".").at(-1)}`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        });
    },
    canAppeal() {
      if (this.isLoading) return false;
      // si no hay apelaciones puede apelar
      if (this.appeals.length === 0 || !this.in_progress) {
        return false;
      }
      let last_appeal = this.appeals[0];

      //si la ultima de las apelaciones no tiene observaciones esta pendiente
      if (last_appeal.reviewer_observation === null) {
        return true;
      }
      return false;
    },
    formatCreatedAt(date) {
      if (!date) return null;
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    answerObservation() {
      this.isLoading = true;
      this.disableButton = true;
      let last_appeal = this.appeals[0];
      this.formData = new FormData();
      this.formData.append("reviewer_observation", this.inputObservation);
      this.formData.append("is_accepted", this.accepted);

      axios
        .patch(`/appeals/${last_appeal.id}.json`, this.formData)
        .then((response) => {
          this.$buefy.dialog.confirm({
            title: "Listo",
            message: "La observación se ha respondido exitosamente.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            canCancel: false,
          });
        })
        .catch((error) => {
          this.validationErrors = error.response.data;
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();

          this.$buefy.dialog.alert({
            title: "Error",
            message: "error:" + error,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .finally(() => {
          this.getAppeals();
          this.disableButton = false;
          this.isLoading = false;
        });
    },
  },
};
</script>
