<template>
  <section>
    <div class="modal-card custom-visibility" style="width: auto">
      <header class="modal-card-head custom-visibility">
        <p class="modal-card-title">Editar usuario</p>

        <b-button
          native-type="button"
          class="delete"
          @click="close"
          style="background: #ff3860"
        />
      </header>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(updateInfo)">
          <div class="modal-card-body custom-visibility">
            <div class="columns is-multiline">
              <b-field class="column is-one-third" label="Nombres*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="user.name"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field class="column is-one-third" label="Apellido*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="user.last_name"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field class="column is-one-third" label="Segundo apellido">
                <b-input v-model="user.sur_name"></b-input>
              </b-field>
              <b-field class="column is-one-third" label="Correo corporativo*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input type="email" v-model="user.email"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field class="column is-one-third" label="Rut">
                <ValidationProvider
                  :rules="{
                    required: user.run.dv !== null && user.run.dv !== '',
                    regex: '^([0-9]+)$',
                  }"
                  v-slot="{ errors }"
                >
                  <b-input v-model="user.run.rut"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field
                class="column is-one-third"
                label="Dígito verificador (dv) "
              >
                <ValidationProvider
                  :rules="{
                    required: user.run.rut !== null && user.run.rut !== '',
                    regex: /^[0-9kK]$/,
                  }"
                  v-slot="{ errors }"
                >
                  <b-input v-model="user.run.dv"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field class="column is-half" label="Rol">
                <multiselect
                  class="custom-multiselect"
                  v-model="selectedInternalPosition"
                  :options="internalPositions"
                  :custom-label="
                    (opt) =>
                      internalPositions.find((x) => x.id === opt.id) != null
                        ? internalPositions.find((x) => x.id === opt.id).name
                        : null
                  "
                  :multiple="false"
                  placeholder="Seleccione un rol"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione para deseleccionar"
                  @input="trackModified"
                  track-by="id"
                  label="name"
                  aria-expanded="true"
                >
                  <template v-slot:noOptions> No existen datos </template>
                  <span slot="noResult"> No se encontraron elementos. </span>
                </multiselect>
              </b-field>

              <b-field
                class="column is-half"
                :label="'Descripción' + (selectedInternalPosition ? '*' : '')"
              >
                <ValidationProvider :rules="requiredRole()" v-slot="{ errors }">
                  <b-input
                    v-model="description"
                    placeholder="Ingrese el motivo o descripción del rol"
                    :disabled="!selectedInternalPosition"
                  >
                  </b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field
                class="column is-half"
                :label="
                  'Inicio del rol' + (selectedInternalPosition ? '*' : '')
                "
              >
                <ValidationProvider :rules="requiredRole()" v-slot="{ errors }">
                  <b-datepicker
                    v-model="start_date"
                    icon="calendar-alt"
                    placeholder="Formato de Fecha: dd/mm/aaaa"
                    :disabled="!selectedInternalPosition"
                    position="is-top-right"
                    :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                    locale="es"
                  >
                  </b-datepicker>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field class="column is-half" label="Fin del rol">
                <b-datepicker
                  v-model="end_date"
                  trap-focus
                  icon="calendar-alt"
                  placeholder="Formato de Fecha: dd/mm/aaaa"
                  :disabled="!selectedInternalPosition"
                  position="is-top-right"
                  :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                  locale="es"
                >
                </b-datepicker>
              </b-field>
              <b-table
                class="column is-full"
                :data="userInternalPositions"
                :row-class="
                  (row, index) =>
                    selectedInternalPosition &&
                    row.id === selectedInternalPosition.id &&
                    'selected-row'
                "
                @click="selectRole"
              >
                <b-table-column
                  field="name"
                  label="Cargos asignados"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column
                  field="description"
                  label="Descripción"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.description }}
                </b-table-column>
                <b-table-column
                  field="start_date"
                  label="Fecha inicio"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.start_date }}
                </b-table-column>
                <b-table-column
                  field="name"
                  label="Fecha termino"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.end_date ? props.row.end_date : "Indefinida" }}
                </b-table-column>
              </b-table>
            </div>
          </div>
          <footer class="modal-card-foot custom-visibility actions">
            <b-button type="is-danger" @click="close">Cancelar</b-button>
            <b-button native-type="submit" type="is-success "
              >Guardar cambios</b-button
            >
          </footer>
        </form>
      </ValidationObserver>
    </div>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { isNil } from "buefy";
import { validate } from "vee-validate";

export default {
  name: "ModalEditUser",
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    assignedInternalPositions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      validate: false,
      selectedInternalPosition: null,
      user: null,
      internalPositions: [],
      optionsInternalPositions: [],
      start_date: null,
      end_date: null,
      formData: null,
      modifiedRoles: [],
      userInternalPositions: [],
      allErrors: [],
      description: null,
    };
  },
  async created() {
    this.user = JSON.parse(JSON.stringify(this.usuario));
    this.userInternalPositions = JSON.parse(
      JSON.stringify(this.assignedInternalPositions)
    ).map((role) => {
      var newRoles = role;
      newRoles["start_date"] =
        newRoles["start_date"] !== null
          ? new moment(newRoles["start_date"]).format("DD-MM-YYYY")
          : null;
      newRoles["end_date"] =
        newRoles["end_date"] !== null
          ? new moment(newRoles["end_date"]).format("DD-MM-YYYY")
          : null;
      return newRoles;
    });
    await this.getInternalPositions();
  },
  methods: {
    requiredRole() {
      if (this.selectedInternalPosition) {
        return "required";
      }
      return "";
    },
    close() {
      this.$emit("close");
    },
    async getInternalPositions() {
      return axios
        .get("/internal_positions.json")
        .then((response) => {
          this.internalPositions = response.data;
        })
        .catch((error) => {
          this.internalPositions = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateInfo() {
      this.allErrors = [];
      await this.updateUser();
      await this.updateRoles();
      await this.updateRun();
      if (this.allErrors.length == 0) {
        this.$buefy.dialog.confirm({
          title: "Listo",
          message: "Se han realizado todos los cambios correctamente",
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          canCancel: false,
        });
      } else {
        this.$buefy.dialog.alert({
          title: "Error",
          message: this.allErrors.join(".<br> "),
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
      this.isLoading = false;
    },
    updateUser() {
      //Si el usuario entregado se vio modificado
      if (
        this.usuario.full_name !== this.user.full_name ||
        this.usuario.name !== this.user.name ||
        this.usuario.last_name !== this.user.last_name ||
        this.usuario.sur_name !== this.user.sur_name ||
        this.usuario.email !== this.user.email
      ) {
        this.isLoading = true;
        let full_name =
          this.user.name + " " + this.user.last_name + " " + this.user.sur_name;
        let formData = new FormData();
        formData.append("email", this.user.email);
        formData.append("id", this.user.id);
        formData.append("full_name", full_name);
        formData.append("name", this.user.name);
        formData.append("last_name", this.user.last_name);
        formData.append("sur_name", this.user.sur_name);
        return axios
          .patch(`/users/${this.user.id}.json`, formData)
          .then((response) => {})
          .catch((error) => {
            this.validationErrors = error.response.data;
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            const errorKeys = Object.keys(error.response.data);
            let errorMessage =
              "Hubo errores en los siguientes campos relacionados a la persona: <br>";

            errorKeys.forEach((key) => {
              const errorMessages = error.response.data[key];
              errorMessage += `${key}: ${errorMessages.join(",")}.<br>  `;
            });
            this.allErrors.push(errorMessage);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      return;
    },
    updateRun() {
      this.isLoading = true;
      let formData = new FormData();
      if (
        this.usuario.run.rut !== this.user.run.rut ||
        this.usuario.run.dv !== this.user.run.dv
      ) {
        formData.append("rut", this.user.run.rut);
        formData.append("user_id", this.user.id);
        formData.append("dv", this.user.run.dv);
        return axios
          .post(`/runs.json`, formData)
          .then((response) => {})
          .catch((error) => {
            const errorKeys = Object.keys(error.response.data);
            let errorMessage =
              "Hubo errores en los siguientes campos relacionados a la creación de run: <br>";

            errorKeys.forEach((key) => {
              const errorMessages = error.response.data[key];
              errorMessage += `${key}: ${errorMessages.join(",")}.<br>  `;
            });
            this.allErrors.push(errorMessage);
            var elmnt = document.getElementById("app");

            elmnt.scrollIntoView();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      return;
    },
    trackModified() {
      let foundPosition = this.assignedInternalPositions.find(
        (assignedPosition) =>
          assignedPosition.id === this.selectedInternalPosition.id
      );
      if (foundPosition) {
        this.start_date = foundPosition.start_date;
        this.end_date = foundPosition.end_date;
        this.description = foundPosition.description;
      } else {
        this.start_date = null;
        this.end_date = null;
        this.description = null;
      }
    },
    selectRole(row) {
      let foundPosition = this.assignedInternalPositions.find(
        (position) => position.id === row.id
      );
      this.start_date = foundPosition.start_date;
      this.end_date = foundPosition.end_date;
      this.description = foundPosition.description;
      this.selectedInternalPosition = foundPosition;
    },
    updateRoles() {
      //Si no se seleccionó rol no se hace nada
      if (this.selectedInternalPosition == null) {
        return;
      }
      this.isLoading = true;
      //si se encuentra el rol en las assignadas manda a actualizar
      let foundPosition = this.assignedInternalPositions.find(
        (position) => position.id === this.selectedInternalPosition.id
      );
      if (foundPosition) {
        let formData = {
          description: this.description,
          start_date: this.start_date,
          end_date: this.end_date,
        };
        return axios
          .patch(
            `/user_internal_positions/${foundPosition.user_internal_position_id}.json`,
            formData
          )
          .then((response) => {
            const indexToUpdate = this.userInternalPositions.findIndex(
              (position) => position.id === foundPosition.id
            );
            if (indexToUpdate !== -1) {
              this.userInternalPositions[indexToUpdate].start_date = new moment(
                this.start_date
              ).format("DD-MM-YYYY");
              this.userInternalPositions[indexToUpdate].description =
                this.description;
              this.userInternalPositions[indexToUpdate].end_date =
                this.end_date !== null
                  ? new moment(this.end_date).format("DD-MM-YYYY")
                  : null;
            }
          })
          .catch((error) => {
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            let errorMessage =
              "Hubo errores en los siguientes campos relacionados a la actualización de roles: <br>";
            errorMessage += error.response.data;
            this.allErrors.push(errorMessage);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.formData = {
          user_id: this.user.id,
          internal_position_id: this.selectedInternalPosition.id,
          description: this.description,
          start_date: this.start_date,
          end_date: this.end_date,
        };
        return axios
          .post(`/user_internal_positions.json`, this.formData)
          .then((response) => {
            const indexToUpdate = this.userInternalPositions.findIndex(
              (position) => position.id === foundPosition.id
            );
            if (indexToUpdate !== -1) {
              this.userInternalPositions[indexToUpdate].start_date = new moment(
                this.start_date
              ).format("DD-MM-YYYY");
              this.userInternalPositions[indexToUpdate].description =
                this.description;
              this.userInternalPositions[indexToUpdate].end_date =
                this.end_date !== null
                  ? new moment(this.end_date).format("DD-MM-YYYY")
                  : null;
            }
          })
          .catch((error) => {
            let errorMessage =
              "Hubo errores en los siguientes campos relacionados a la creación de roles: <br>";
            errorMessage += error.response.data;
            this.allErrors.push(errorMessage);
          })
          .finally(() => {
            this.isLoading = false;
            this.close();
          });
      }
    },
  },
};
</script>
<style>
.custom-visibility {
  overflow: visible !important;
}
tr.selected-row {
  background: #167df0;
  color: #fff;
}
</style>
