<template>
  <div>
    <div class="title is-3 my-5">Precios de Planes y Programas</div>
    <b-message class="mt-3" type="is-info" icon="info-circle" has-icon>
      Las solicitudes de planes y programas deben ser pagadas desde la primera
      solicitud, su precio dependerá de qué tipo de programa se solicite y cuantas
      asignaturas sean solicitadas. Ante cualquier duda contactarse con
      <b>registrocurricular.fing@usach.cl</b>.
    </b-message>
    <b-table class="is-border-light-grey" :data="paymentProgramInfo">
      <b-table-column label="Tipo de Solicitud" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column label="Precio" v-slot="props">
        {{ props.row.price }} (CLP)
      </b-table-column>
      <b-table-column label="Pago desde" v-slot="props">
        {{
          props.row.paid_immediately
            ? "La primera solicitud en el año"
            : "La tercera solicitud en el año"
        }}
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      paymentProgramInfo: [
        {
          name: "Programa Completo Carreras de 12 o más semestres (Civil)",
          price: "129.400",
          paid_immediately: true,
        },
        {
          name: "Programa Completo Carreras de 9 a 11 semestres (Ing. Biotecnología y Ing. Ambiental)",
          price: "102.300",
          paid_immediately: true,
        },
        {
          name: "Programa Completo Carreras de 8 semestres o equivalentes (Ejecución)",
          price: "81.200",
          paid_immediately: true,
        },
        {
          name: "Programa por Asignatura de Pregrado (Si la suma de los cobros de las asignaturas excede el monto de Civil o Ejecución se debe pagar como programa completo).",
          price: "21.200",
          paid_immediately: true,
        },
        {
          name: "Programa de Postítulo",
          price: "135.200",
          paid_immediately: true,
        },
        {
          name: "Programa de Postgrado",
          price: "224.700",
          paid_immediately: true,
        },
        {
          name: "Programa por Asignatura de Postgrado",
          price: "31.800",
          paid_immediately: true,
        },
        {
          name: "Plan de Estudios",
          price: "31.800",
          paid_immediately: true,
        },
      ],
    };
  },
};
</script>
