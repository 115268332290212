<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitFormResponse)">
                <!-- Box de Formulario Appointment -->
                <div class="modal-card" style="width: auto;height: 600px;">
                    <header class="modal-card-head has-background-secondary">
                        <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                    </header>
                    <section class="modal-card-body" ref="ModalCreateState">
                        <div v-if="Object.keys(validationErrors).length !== 0" class="columns">
                            <div class="column">
                                <div class="mb-2" >
                                    <b-message type="is-danger" has-icon>
                                        <div v-for="(item,error_index) in validationErrors" :key="error_index">
                                            {{ item[0] }}
                                        </div>
                                    </b-message>
                                </div>
                            </div>
                        </div>

                        <div class="columns is-multiline">
                            <div v-for="(element, index) in formToFill" :key="index" class="field column is-full">

                                <div v-if="element.itemType === 'TEXT' " class="control">
                                    <label :class="{'label':true,'is-grey': element.prefilled}">{{ element.item_title }} </label>

                                    <div class="control">
                                        <ValidationProvider
                                                :rules="element.is_required ? 'required|max:250' : 'max:250'"
                                                v-slot="{errors}">
                                            <input class="input" v-model="element.item_response" type="text"
                                                   :placeholder="element.item_title "
                                                   :disabled="element.prefilled">
                                            <span class="validation-alert">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div v-if="element.itemType === 'DATE'" class="control">
                                    <label :class="{'label':true,'is-grey': element.prefilled}">{{ element.item_title }} </label>
                                    <ValidationProvider :rules="element.is_required ? 'required' : ''"
                                                        v-slot="{errors}">
                                        <b-datepicker v-model="element.item_response" placeholder="Ingrese una fecha"
                                                      icon="calendar" :icon-right="selected ? 'close-circle' : ''"
                                                      icon-right-clickable
                                                      :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                                                      locale="es"
                                                      @icon-right-click="element.item_response = null" trap-focus
                                                      :disabled="element.prefilled">
                                        </b-datepicker>
                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'MULTIPLE_CHOICE'" class="control">
                                    <label :class="{'label':true,'is-grey': element.prefilled}">{{ element.item_title }} </label>
                                    <ValidationProvider :rules="element.is_required ? 'required' : ''"
                                                        v-slot="{errors}">
                                        <multiselect v-model="element.item_response" :options="element.options"
                                                     :multiple="false" placeholder="Seleccione una opción"
                                                     selectLabel="Presione para seleccionar"
                                                     selectedLabel="Seleccionado"
                                                     deselectLabel="Presione para deseleccionar"
                                                     :disabled="element.prefilled">
                                            <template v-slot:noOptions>
                                                No existen datos
                                            </template>
                                            <span slot="noResult">
                                            No se encontraron elementos.
                                        </span>
                                        </multiselect>

                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'LIST'" class="control">
                                    <label :class="{'label':true,'is-grey': element.prefilled}">{{ element.item_title }} </label>
                                    <ValidationProvider :rules="element.is_required ? 'required' : ''"
                                                        v-slot="{errors}">
                                        <multiselect v-model="element.item_response"
                                                     :options="element.options" :multiple="false"
                                                     placeholder="Seleccione una opción"
                                                     selectLabel="Presione para seleccionar"
                                                     selectedLabel="Seleccionado"
                                                     deselectLabel="Presione para deseleccionar"
                                                     :disabled="element.prefilled">
                                            <template v-slot:noOptions>
                                                No existen datos
                                            </template>
                                            <span slot="noResult">
                                            No se encontraron elementos.
                                        </span>
                                        </multiselect>

                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'CHECKBOX'" class="control">
                                    <label :class="{'label':true,'is-grey': element.prefilled}">{{ element.item_title }} </label>
                                    <ValidationProvider :rules="element.is_required ? 'required' : ''"
                                                        v-slot="{errors}">
                                        <div class="">
                                            <b-checkbox v-for="(option, ch_index) in element.options" :key="ch_index"
                                                        v-model="element.item_response" :native-value="option"
                                                        :disabled="element.prefilled">
                                                {{ option }}
                                            </b-checkbox>
                                        </div>
                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'PARAGRAPH_TEXT'" class="control">
                                    <label :class="{'label':true,'is-grey': element.prefilled}">{{ element.item_title }} </label>
                                    <ValidationProvider :rules="element.is_required ? 'required|max:500' : 'max:500'"
                                                        v-slot="{errors}">
                                        <input class="input" v-model="element.item_response" type="textarea"
                                               :placeholder="element.item_title "
                                               :disabled="element.prefilled">
                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

                <footer class="modal-card-foot actions has-background-secondary">
                    <div class="field is-grouped is-pulled-right">
                        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                        <button class="button is-primary" type="submit">Guardar</button>
                    </div>
                </footer>
            </form>
        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalAddFormResponseString",
    props: ["modalHeader", "form_id", "request_type_id"],
    components: {},
    data: function () {
        return {
            validationErrors: {},
            selected: false,
            isLoading: false,
            formToFill: [],
            actualFilled: 0,
            notPrefilled: 0
        }

    },

    created() {
        this.isLoading = true;

        axios
            .get("/google_forms/get_form/" + this.form_id + ".json", {
                    params: {
                        request_type_id: this.request_type_id
                    }
                }
            )
            .then(response => {
                this.formToFill = response.data;
                this.isLoading = false;
            })
            .catch(error => {
                console.log(error)
                this.isLoading = false;
            });
    },

    methods: {
      submitFormResponse() {
        this.$emit('clicked', this.formToFill)
        this.$emit('close')
      },
      countNotPrefilled() {
        let count = 0;

        this.formToFill.forEach(element => {
          if (element.prefilled === false) {
            count++;
          }
        });

        return count;
      },
      countEmptyResponse() {
        return this.countNotPrefilled()- this.formToFill.filter(element => element.item_response=="" && !element.prefilled ).length;
      }
    }
}
</script>
