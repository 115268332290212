<template>
  <div>
    <div class="columns">
      <div class="column"></div>
      <div class="column is-4 has-text-right">
        <b-button
          v-if="allCollapsed"
          class="is-secondary is-light is-border-cornflowerblue"
          @click="switchCollapse"
          icon-left="plus-circle"
        >
          Expandir todas las solicitudes
        </b-button>
        <b-button
          v-else
          class="is-warning is-light is-border-darkgoldenrod"
          @click="switchCollapse"
          icon-left="minus-circle"
        >
          Reducir todas las solicitudes
        </b-button>
      </div>
    </div>

    <div class="columns is-multiline">
      <div
        class="column is-6"
        v-for="(requestType, index) in this.requestTypes"
        :key="index"
      >
        <b-collapse
          :open="!allCollapsed"
          :class="`card card-hover-animation custom-border ${borderColor}`"
          animation="slide"
        >
          <template #trigger="props">
            <div class="card-header">
              <p :class="`card-header-title ${titleTextColor} has-text-left`" style="font-size: 1.2em;">
                <b-icon
                  class="mr-3 pr-2"
                  icon="file-alt"
                  pack="far"
                  size="is-large"
                ></b-icon>
                {{ requestType.real_name }}
              </p>
              <a class="card-header-icon">
                <b-icon
                  :class="titleTextColor"
                  :icon="props.open ? 'minus-circle' : 'plus-circle'"
                  size="is-medium"
                ></b-icon>
              </a>
            </div>
          </template>
          <div :class="`card-content ${borderTopContentColor}`">
            <div class="content">
              <p class="has-text-black">{{ requestType.long_description }}</p>
              <div class="has-text-right">
                <b-button
                  icon-left="paper-plane"
                  :type="buttonType"
                  @click="redirect(requestType.id)"
                >
                  Iniciar Solicitud
                </b-button>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "requestTypes",
    "titleTextColor",
    "backgroundColor",
    "borderColor",
    "buttonType",
    "borderTopContentColor",
  ],
  data() {
    return {
      allCollapsed: true,
    };
  },
  methods: {
    redirect(requestTypeId) {
      window.open(`/requests/new/${requestTypeId}`, "_blank");
    },
    switchCollapse() {
      this.allCollapsed = !this.allCollapsed;
    },
  },
};
</script>
<style scoped>
.card-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: background-color 0.2s ease-in-out;
}

.card-content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card-header-title {
  height: 6em;
}

.card-hover-animation {
  transition: transform 0.2s ease-in-out;
}

.card-hover-animation:hover {
  transform: scale(1.05);
}

.is-border-gcc {
  border: 2px solid #6356a1 !important;
  border-radius: 0.3rem;
}
</style>
