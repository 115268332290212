<template>
  <div>
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>

    <div class="container box my-5">
      <h1 class="title">Firma digital simple masiva</h1>
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <b-notification
              class="is-primary is-light"
              has-icon
              icon="exclamation-circle"
          >
            <b>¡IMPORTANTE!</b> Se recomienda utilizar este módulo con el navegador
            Google Chrome para evitar problemas de rendimiento o bloqueos de su computador.
          </b-notification>
        </div>
        <div class="column is-1"></div>
      </div>
      <p class="mb-4">
        Esta herramienta permite insertar una firmar digital simple a múltiples
        páginas de un documento PDF simultáneamente. La firma debe estar en
        formato PNG y tener un fondo transparente.
      </p>

      <b-steps v-model="activeStep" class="pt-4">
        <b-step-item
          step="1"
          label="Subir Archivos"
          :type="step1Complete ? 'is-success' : 'is-primary'"
        >
          <div class="columns mt-4">
            <div class="column">
              <b-field extended label="Archivo (formato PDF)*">
                <b-upload
                  v-model="pdfFile"
                  expanded
                  drag-drop
                  accept=".pdf"
                  required
                  validation-message="El archivo debe tener extensión PDF"
                  @input="onAddPdfFile"
                >
                  <div class="content has-text-centered p-4">
                    <p>
                      <b-icon icon="file-pdf" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu PDF o haz clic para subir.</p>
                    <b-tag
                      v-if="pdfFile"
                      icon="file-pdf"
                      type="is-success is-light"
                    >
                      {{ shortTextFile(pdfFile.name, 48) }}
                    </b-tag>
                  </div>
                </b-upload>
              </b-field>
              <div v-if="pdfFile">
                <hr />
                <div class="has-text-weight-bold mb-2">
                  Documento a firmar (vista previa)
                </div>
                <div class="pdf-container">
                  <pdf
                    :src="pdfSource"
                    :page="currentPage"
                    @num-pages="pageCount = $event"
                  />
                  <div class="columns is-mobile is-centered">
                    <div
                      class="column is-half has-text-centered is-size-7 mt-2"
                    >
                      Página: {{ currentPage }} de {{ pageCount }}
                    </div>
                  </div>
                  <div class="columns is-mobile is-centered">
                    <div class="column is-narrow has-text-right">
                      <b-button
                        icon-left="arrow-left"
                        type="is-primary is-outlined is-small"
                        :disabled="currentPage === 1"
                        @click="backPage"
                      >
                        Página anterior
                      </b-button>
                    </div>
                    <div class="column is-narrow has-text-left">
                      <b-button
                        icon-right="arrow-right"
                        type="is-primary is-outlined is-small"
                        :disabled="currentPage === pageCount"
                        @click="nextPage"
                      >
                        Página siguiente
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <b-field extended label="Firma (formato PNG)*">
                <b-upload
                  v-model="signatureFile"
                  expanded
                  required
                  accept=".png"
                  drag-drop
                  @input="onAddSignatureImage"
                >
                  <div class="content has-text-centered p-4">
                    <p>
                      <b-icon icon="image" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu firma o haz clic para subir.</p>
                    <b-tag
                      v-if="signatureFile"
                      icon="image"
                      type="is-success is-light"
                    >
                      {{ shortTextFile(signatureFile.name, 48) }}
                    </b-tag>
                  </div>
                </b-upload>
              </b-field>
              <div v-if="signatureFile">
                <hr />
                <div class="has-text-weight-bold mb-2">
                  Firma a insertar (vista previa)
                </div>
                <div class="signature-container">
                  <img
                    :src="signatureFileUrl"
                    class="signature-preview"
                    :style="{ height: `${signatureImageHeight}px` }"
                  />
                  <div>
                    <div class="columns is-desktop is-centered">
                      <div
                        class="column has-text-centered is-size-7 mt-2 mb-0 pb-0"
                      >
                        Altura: {{ signatureImageHeight }}px
                        <div
                          :style="{
                            color:
                              signatureImageHeight === 60 ? 'green' : 'red',
                            fontSize: '0.75rem',
                          }"
                        >
                          {{ signatureImageHeight === 60 ? "(Sugerida)" : "" }}
                        </div>
                      </div>
                    </div>

                    <div class="columns is-mobile is-centered">
                      <div class="column is-narrow has-text-right">
                        <b-button
                          icon-left="minus"
                          type="is-primary is-outlined is-small"
                          :disabled="signatureImageHeight === 50"
                          @click="scaleDown"
                        >
                          Disminuir
                        </b-button>
                      </div>
                      <div class="column is-narrow has-text-left">
                        <b-button
                          icon-right="plus"
                          type="is-primary is-outlined is-small"
                          :disabled="signatureImageHeight === 100"
                          @click="scaleUp"
                        >
                          Aumentar
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="2"
          label="Ubicación de Firma"
          :clickable="step1Complete"
          :type="step2Complete ? 'is-success' : 'is-primary'"
        >
          <h2 class="mt-4 mb-2">
            Se han identificado
            <span class="has-text-weight-bold"
              >{{ signatureImages.length }} posibles ubicaciones</span
            >
            para la firma en el documento. Por favor, selecciona la ubicación
            donde deseas aplicar tu firma.
          </h2>
          <div class="columns is-variable is-2 is-multiline">
            <div
              v-for="(image, index) in signatureImages"
              :key="index"
              class="column is-one-third-widescreen is-half-desktop is-half-tablet is-full-mobile"
            >
              <div class="image-container">
                <img
                  :src="image"
                  alt="Vista previa de ubicación para la firma"
                  class="signature-preview"
                  :style="imageStyle(index)"
                  @click="imageSelected = index"
                />
                <span class="image-index">{{ index + 1 }}</span>
              </div>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="3"
          label="Consentimiento y Descarga"
          :clickable="step2Complete"
          :type="consentGiven ? 'is-success' : 'is-primary'"
        >
          <b-message
            title="Acuerdo de Consentimiento y Exención de Responsabilidad"
            type="is-warning"
            has-icon
            class="my-4"
            :closable="false"
          >
            <p>
              Al marcar la siguiente casilla, usted reconoce y acepta como
              usuario la responsabilidad de verificar la precisión del documento
              tanto de entrada como de salida, incluyendo la correcta
              incrustación y ubicación de la firma, utilizando esta herramienta.
              Es esencial que revise cuidadosamente cada etapa del proceso antes
              de confirmar su descarga, ya que el uso del archivo generado es de
              su exclusiva responsabilidad. Asimismo, SAS declara no asumir
              responsabilidad por errores, omisiones o imprecisiones que puedan
              presentarse en el resultado final del documento. Por último, en
              alineación con nuestro compromiso con su privacidad y seguridad,
              le informamos que el sistema no guarda ni los archivos de entrada
              y salida, ni la firma utilizada.
            </p>
          </b-message>
          <b-checkbox v-model="consentGiven">
            He leído y acepto los términos y condiciones de uso.
          </b-checkbox>
          <div
            :style="{
              display: 'flex',
              justifyContent: 'flex-end',
            }"
            class="mb-4"
          >
            <b-button
              type="is-success"
              icon-pack="fas"
              icon-left="file-signature"
              :disabled="!consentGiven"
              @click.prevent="insertSignaturesInPDF"
            >
              Firmar y descargar el documento
            </b-button>
          </div>
          <b-progress
            v-if="
              customLoadingPercentage > 0 ||
              customLoadingPercentage < 100 ||
              customLoadingMessage !== null
            "
            :style="{
              zIndex: 9999,
            }"
            :value="customLoadingPercentage"
            type="is-primary"
            size="is-medium"
            :show-value="true"
          >
            {{ customLoadingMessage }}
          </b-progress>
        </b-step-item>
        <template #navigation="{ previous, next }">
          <hr />
          <div
            :style="{
              display: 'flex',
              justifyContent: 'space-between',
            }"
            class="px-2"
          >
            <b-button
              outlined
              type="is-primary"
              icon-pack="fas"
              icon-left="chevron-left"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
            >
              {{ navigationBackText() }}
            </b-button>
            <b-tooltip
              v-if="isButtonDisabled"
              :label="
                activeStep === 0
                  ? 'Debes subir un archivo PDF y una firma para continuar'
                  : 'Debes seleccionar una ubicación de la firma para continuar'
              "
              position="is-left"
              type="is-danger"
              size="is-small"
              multilined
            >
              <b-button
                outlined
                type="is-primary"
                icon-pack="fas"
                icon-right="chevron-right"
                :disabled="next.disabled || isButtonDisabled"
                @click.prevent="next.action"
              >
                {{ navigationFowardText() }}
              </b-button>
            </b-tooltip>
            <b-button
              v-else-if="!next.disabled"
              outlined
              type="is-primary"
              icon-pack="fas"
              icon-right="chevron-right"
              :disabled="next.disabled || isButtonDisabled"
              @click.prevent="next.action"
            >
              {{ navigationFowardText() }}
            </b-button>
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import { getDocument } from "pdfjs-dist/es5/build/pdf.js";
import { createWorker, createScheduler } from "tesseract.js";
import { PDFDocument } from "pdf-lib";
import pdf from "vue-pdf";

export default {
  name: "SimpleDigitalSignatureView",
  components: {
    pdf,
  },
  data() {
    return {
      activeStep: 0,
      pdfFile: null,
      pdfBinary: null,
      pdfSource: null,
      currentPage: 1,
      pageCount: null,
      signatureFile: null,
      signatureFileUrl: null,
      signatureImageHeight: 60,
      signatureImages: [],
      signaturePositions: [],
      potencialSignatures: [],
      OCROffset: 200,
      imageSelected: -1,
      consentGiven: false,
      isLoading: false,
      signatureText: "Firma",
      customLoadingMessage: null,
      customLoadingPercentage: 0,
    };
  },
  computed: {
    step1Complete() {
      return !!this.pdfFile && !!this.signatureFile;
    },
    step2Complete() {
      return this.imageSelected !== -1;
    },
    isButtonDisabled() {
      if (this.activeStep === 0) {
        return !this.step1Complete;
      } else if (this.activeStep === 1) {
        return !this.step2Complete;
      }
      return false;
    },
  },
  watch: {
    step1Complete(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.showSuccessNotification(this.activeStep);
        }, 1000);
      }
    },
    imageSelected(newValue) {
      this.consentGiven = false;
      this.customLoadingPercentage = 0;
      this.customLoadingMessage = null;
      this.showSelectedImageToast(newValue);
    },
    pdfFile(newValue) {
      if (newValue) {
        this.customLoadingPercentage = 0;
        this.customLoadingMessage = null;
        this.consentGiven = false;
      }
    },
    signatureFile(newValue) {
      if (newValue) {
        this.customLoadingPercentage = 0;
        this.customLoadingMessage = null;
        this.consentGiven = false;
      }
    },
    signatureImageHeight(newValue) {
      if (newValue) {
        this.customLoadingPercentage = 0;
        this.customLoadingMessage = null;
        this.consentGiven = false;
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  methods: {
    imageStyle(index) {
      return {
        padding: "1rem",
        border:
          index === this.imageSelected
            ? "2px solid #EE8600"
            : "1px solid #dbdbdb",
        cursor: "pointer",
        borderRadius: "8px",
      };
    },

    showSelectedImageToast(index) {
      if (index === -1) return;
      this.$buefy.toast.open({
        message: `La firma se aplicará en la ubicación número ${index + 1}.`,
        type: "is-success",
        size: "is-small",
        position: "is-bottom",
        duration: 5000,
      });
    },

    showSuccessNotification(step) {
      this.$buefy.notification.open({
        message:
          step === 0
            ? "Archivos cargados correctamente, puedes continuar con el siguiente paso"
            : "Ubicación de firma seleccionada correctamente, puedes continuar con el siguiente paso",
        type: "is-success",
        size: "is-small",
        "has-icon": true,
        position: "is-bottom-right",
        "progress-bar": true,
        duration: 5000,
      });
    },
    async onAddPdfFile(File) {
      if (File === null) return;
      this.isLoading = true;
      const buffer = await this.pdfFile.arrayBuffer();
      this.pdfBinary = buffer;
      this.pdfSource = [new Uint8Array(buffer)].at(-1);
      await this.getPotencialSignatures();
      this.isLoading = false;
    },

    async onAddSignatureImage(File) {
      if (File === null) return;
      this.isLoading = true;
      const buffer = await this.signatureFile.arrayBuffer();
      this.signatureFileSource = new Uint8Array(buffer);
      this.signatureFileUrl = URL.createObjectURL(this.signatureFile);
      this.$buefy.toast.open({
        message: `Firma: ${this.shortTextFile(
          this.signatureFile.name,
          24
        )} cargada correctamente`,
        type: "is-success",
        position: "is-bottom",
        duration: 3000,
      });
      this.isLoading = false;
    },

    shortTextFile(text, maxLength) {
      if (text.length <= maxLength) return text;
      return text.slice(0, maxLength) + "...";
    },

    backPage() {
      if (this.pageCount === null) return;
      if (this.currentPage - 1 < 1) return;
      this.currentPage -= 1;
    },
    nextPage() {
      if (this.pageCount === null) return;
      if (this.currentPage + 1 > this.pageCount) return;
      this.currentPage += 1;
    },

    scaleUp() {
      if (this.signatureImageHeight + 10 > 100) return;
      this.signatureImageHeight += 10;
    },
    scaleDown() {
      if (this.signatureImageHeight - 10 < 50) return;
      this.signatureImageHeight -= 10;
    },
    handleKeydown(e) {
      switch (e.key) {
        case "ArrowLeft":
          this.backPage();
          break;
        case "ArrowRight":
          this.nextPage();
          break;
        case "+":
          this.scaleUp();
          break;
        case "-":
          this.scaleDown();
          break;
      }
    },

    navigationFowardText() {
      if (this.activeStep === 0) return "Continuar con la Ubicación de Firma";
      if (this.activeStep === 1)
        return "Continuar con el Consentimiento y Descarga";
      if (this.activeStep === 2) return "Proceso completado";
    },

    navigationBackText() {
      if (this.activeStep === 0) return "No hay pasos anteriores";
      if (this.activeStep === 1) return "Volver a Subir Archivos";
      if (this.activeStep === 2) return "Volver a la Ubicación de Firma";
    },

    async getPotencialSignatures() {
      if (!this.pdfFile) return;

      this.potencialSignatures = [];
      this.signatureImages = [];
      this.imageSelected = -1;
      this.isLoading = true;

      try {
        const pdfBuffer = await this.pdfFile.arrayBuffer();
        const pdfDoc = await this.loadPdfDocument(pdfBuffer);
        const { canvas, viewport } = await this.renderFirstPageToCanvas(pdfDoc);
        const textMatches = await this.findSignatureTextMatches(canvas);

        if (textMatches.length === 0) {
          this.showNoSignaturesFoundAlert();
          return;
        }
        this.potencialSignatures = textMatches;
        this.signatureImages = this.getSignatureImages({
          canvas,
          width: viewport.width / 2,
          height: 300,
        });
        this.$buefy.toast.open({
          message: `Documento: ${this.shortTextFile(
            this.pdfFile.name,
            24
          )} cargado correctamente`,
          type: "is-success",
          position: "is-bottom",
          duration: 3000,
        });
      } catch (error) {
        console.error("Error processing PDF:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async renderPageWithCanvas({ pdfDoc, pageNumber, scale }) {
      const page = await pdfDoc.getPage(pageNumber);
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      await page.render({
        canvasContext: ctx,
        viewport: viewport,
      }).promise;
      return { canvas, viewport };
    },

    getSignatureImages({ canvas, width, height }) {
      const signatureImages = [];
      this.potencialSignatures.forEach((bbox) => {
        const signatureImage = this.cropImageFromCanvas({
          canvas,
          x: bbox.x0 - this.OCROffset,
          y: bbox.y0 - this.OCROffset,
          width,
          height,
        });
        signatureImages.push(signatureImage);
      });
      return signatureImages;
    },

    cropImageFromCanvas({ canvas, x, y, width, height }) {
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      const imageData = ctx.getImageData(x, y, width, height);
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = width;
      croppedCanvas.height = height;
      const croppedCtx = croppedCanvas.getContext("2d", {
        willReadFrequently: true,
      });
      croppedCtx.putImageData(imageData, 0, 0);
      return croppedCanvas.toDataURL();
    },

    async loadPdfDocument(pdfBuffer) {
      return await getDocument({ data: pdfBuffer }).promise;
    },

    async renderFirstPageToCanvas(pdfDoc) {
      return await this.renderPageWithCanvas({
        pdfDoc,
        pageNumber: 1,
        scale: 1.5,
      });
    },

    async findSignatureTextMatches(canvas) {
      const signatureTextLower = this.signatureText.toLowerCase();
      const worker = await createWorker("spa");
      const firstPageResult = await worker.recognize(canvas.toDataURL());
      return firstPageResult.data.lines
        .filter((line) => line.text.toLowerCase().includes(signatureTextLower))
        .flatMap((line) => line.words)
        .filter((word) => word.text.toLowerCase().includes(signatureTextLower))
        .map((word) => word.bbox);
    },

    showNoSignaturesFoundAlert() {
      this.$buefy.dialog.alert({
        message:
          "No se encontraron firmas en el documento con el texto: " +
          this.signatureText,
        type: "is-danger",
        hasIcon: true,
      });
    },

    async processPDFAndExtractSignatures() {
      this.signaturePositions = [];
      this.customLoadingPercentage = 0;
      this.customLoadingMessage = "Procesando documento...";
      const pdfBuffer = await this.pdfFile.arrayBuffer();
      const pdfDoc = await getDocument({ data: pdfBuffer }).promise;
      const docPages = pdfDoc.numPages;
      const x =
        this.potencialSignatures[this.imageSelected].x0 - this.OCROffset;
      const y =
        this.potencialSignatures[this.imageSelected].y0 - this.OCROffset;

      const cropImagesPromises = Array.from(
        { length: docPages },
        async (_, i) => {
          const pageNum = i + 1;
          const { canvas, viewport } = await this.renderPageWithCanvas({
            pdfDoc,
            pageNumber: pageNum,
            scale: 1.5,
          });
          const imageSrc = this.cropImageFromCanvas({
            canvas,
            x,
            y,
            width: viewport.width / 2,
            height: 300,
          });
          return { imageSrc, pageNum };
        }
      );
      let tasksCompleted = 0;
      const croppedImages = await Promise.all(cropImagesPromises);
      this.customLoadingMessage = "Buscando firmas...";
      const scheduler = await this.createWorkersAndScheduler(4);
      const ocrPromises = croppedImages.map(({ imageSrc, pageNum }) => {
        return scheduler.addJob("recognize", imageSrc).then((result) => {
          tasksCompleted++;
          this.updateProgressBar({
            tasksCompleted,
            docPages,
            currentPage: pageNum,
          });
          return { result, pageNum };
        });
      });

      const results = await Promise.all(ocrPromises);
      this.customLoadingMessage = "Guardando ubicación de firma.";
      results.forEach(({ result, pageNum }) => {
        result.data.lines.forEach((line) => {
          if (
            line.text.toLowerCase().includes(this.signatureText.toLowerCase())
          ) {
            this.signaturePositions.push({ pageNum, bbox: line.bbox });
          }
        });
      });
      this.customLoadingMessage = "Proceso completado.";
      await scheduler.terminate();
    },

    async createWorkersAndScheduler(numWorkers) {
      const scheduler = createScheduler();
      const createWorkerAndAddToScheduler = async () => {
        const worker = await createWorker("spa");
        scheduler.addWorker(worker);
      };

      await Promise.all(
        Array.from({ length: numWorkers }, createWorkerAndAddToScheduler)
      );

      return scheduler;
    },
    updateProgressBar({ tasksCompleted, docPages, currentPage }) {
      const progressPercentage = Math.round((tasksCompleted / docPages) * 100);
      this.customLoadingMessage = `Progreso: ${progressPercentage}% (Firmando página ${currentPage})...`;
      this.customLoadingPercentage = progressPercentage;
    },

    async insertSignaturesInPDF() {
      if (!this.pdfBinary) return;
      this.isLoading = true;

      try {
        const pdfDoc = await PDFDocument.load(this.pdfBinary);

        await this.processPDFAndExtractSignatures();
        const desiredHeight = this.signatureImageHeight;
        const scaleFactor = 1.5; // El mismo factor de escala usado en el OCR
        const signatureImage = await pdfDoc.embedPng(this.signatureFileSource);

        // Mantiene la relación de aspecto de la imagen
        const aspectRatio = signatureImage.height / signatureImage.width;
        const width = desiredHeight / aspectRatio;
        const height = desiredHeight;

        for (const position of this.signaturePositions) {
          const page = pdfDoc.getPages()[position.pageNum - 1];
          const bbox = position.bbox;

          // Ajuste para coincidir con la posición vertical de la firma en el OCR.
          const ocrVerticalOffset = this.OCROffset;

          // Ajuste para centrar la firma horizonalmente.
          // (En el valor 0 la imagen se posiciona en el inicio de la palabra firma)
          const horizonalPositionFix = desiredHeight; // Desplazamiento de la imagen hacia la derecha.

          // Ajuste para centrar la firma verticalmente
          // (Esto varia dependiendo del texto que se encuentre sobre la palabra Firma).
          const verticalPositionFix = 130; // Desplazamiento de la imagen hacia arriba.
          const x =
            (this.potencialSignatures[this.imageSelected].x0 +
              horizonalPositionFix) /
              scaleFactor -
            width / 2;
          const y =
            page.getHeight() -
            (bbox.y0 +
              this.potencialSignatures[this.imageSelected].y0 -
              ocrVerticalOffset -
              verticalPositionFix) /
              scaleFactor -
            height;

          page.drawImage(signatureImage, { x, y, width, height });
        }

        const modifiedPdfBytes = await pdfDoc.save();
        this.downloadModifiedPDF(modifiedPdfBytes);
      } catch (error) {
        console.log("error", error);
      }
      this.isLoading = false;
    },
    downloadModifiedPDF(pdfBytes) {
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace para descargar
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `${this.pdfFile.name.slice(0, -4)}_FIRMADO.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Limpiar la URL para liberar recursos
      URL.revokeObjectURL(url);
    },
  },
};
</script>
<style scoped>
.pdf-container {
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 16px;
  overflow-y: auto;
}

.signature-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f5f5f5;
  padding: 16px;
  width: 100%;
}

.signature-preview {
  max-height: 300px;
  max-width: 100%;
  width: 100%;
  border: white 4px solid;
  background-color: white;
  object-fit: contain;
}
.image-container {
  position: relative;
  display: inline-block;
}
.image-index {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  font-weight: bold;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
</style>
