<template>
    <div id="app">
        <p>{{ message }} </p>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            message: "Hello Vue!"
        }
    }
}
</script>