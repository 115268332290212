<template>
  <div class="p-2">
    <b-collapse
      :open="false"
      class="card is-border-darkgoldenrod"
      animation="slide"
    >
      <template #trigger="props">
        <div class="card-header has-background-primary">
          <p :class="'is-size-5 card-header-title ' + titleTextColor">{{ requestType.real_name }}</p>
          <a class="card-header-icon">
            <b-icon :class="titleTextColor" :icon="props.open ? 'minus-circle' : 'plus-circle'" size="is-medium"></b-icon>
          </a>
        </div>
      </template>
      <div class="card-content is-border-light-grey">
        <div class="content">
          <p style="font-size: 1.2em">{{ requestType.long_description }}</p>
          <div class="columns is-centered">
            <div class="column is-10">
              <b-button class="button is-primary is-fullwidth" @click="redirect">Iniciar Solicitud</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
export default {
  props: ["requestType", "titleTextColor", "backgroundColor", "borderColor"],
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    redirect() {
      window.open(`/requests/new/${this.requestType.id}`, "_blank");
    },
  },
};
</script>

<style scoped>
.card-header {
  min-height: 5em;
}

.card-header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 4em; /* Ajusta según sea necesario */
  text-align: center;
}
</style>