<template>
  <div>
    <div class="columns has-text-centered">
      <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
    </div>
    <div class="columns my-0">
      <div class="column is-6 has-text-right my-0">
        <b-button
          icon-left="arrow-left"
          type="is-primary is-light"
          outlined
          @click="backPage"
          :disabled="pageCount === 1"
        >
          Retroceder página
        </b-button>
      </div>
      <div class="column is-6 has-text-left my-0">
        <b-button
          icon-right="arrow-right"
          type="is-primary is-light"
          outlined
          @click="nextPage"
          :disabled="pageCount === 1"
          >Siguiente página
        </b-button>
      </div>
    </div>
    <div class="columns has-text-centered my-0">
      <div class="column has-text-centered my-0">
        <b-button
          icon-left="undo"
          type="is-primary is-light"
          outlined
          @click="rotatePdf('left')"
        >
        </b-button>
        <b-button
          icon-right="redo"
          type="is-primary is-light"
          outlined
          @click="rotatePdf('right')"
        >
        </b-button>
        <b-button
          icon-left="search-plus"
          type="is-primary is-light"
          outlined
          @click="zoomIn()"
          :disabled="zoomInDisabled"
        >
        </b-button>
        <b-button
          icon-right="search-minus"
          type="is-primary is-light"
          outlined
          @click="zoomOut()"
          :disabled="zoomOutDisabled"
        >
        </b-button>
      </div>
    </div>
    <div class="columns is-centered">
      <div
        class="column is-8 has-text-centered is-flex is-justify-content-center"
      >
        <pdf
          class="is-border-black"
          ref="pdfViewer"
          :page="currentPage"
          :src="src"
          @num-pages="pageCount = $event"
          :rotate="angle"
          :style="{ width: `${zoom}%` }"
        ></pdf>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  props: ["src"],
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      pageCount: null,
      downloaded: false,
      angle: 0,
      zoom: 100,
      width: 1100,
      zoomInDisabled: false,
      zoomOutDisabled: false,
    };
  },
  components: {
    pdf,
  },
  methods: {
    backPage() {
      if (this.pageCount === null) return;
      if (this.currentPage - 1 < 1) return;
      this.currentPage -= 1;
    },
    nextPage() {
      if (this.pageCount === null) return;
      if (this.currentPage + 1 > this.pageCount) return;
      this.currentPage += 1;
    },
    handleKeyup(e) {
      if (e.repeat) {
        return;
      }
      switch (e.keyCode) {
        case 37:
          this.backPage();
          break;
        case 39:
          this.nextPage();
          break;
      }
    },
    rotatePdf(rotationType) {
      this.$refs.pdfViewer.$el.style.pointerEvents = "none";
      if (this.width === 1100) {
        this.width = 1200;
      } else {
        this.width = 1100;
      }
      if (rotationType === "left") {
        this.angle -= 90;
      } else if (rotationType === "right") {
        this.angle += 90;
      }
      if (this.angle > 360) {
        this.angle -= 360;
      }
      if (this.angle < 0) {
        this.angle += 360;
      }
      const pdfViewer = this.$refs.pdfViewer.pdfRender;
      if (pdfViewer && typeof pdfViewer.cancel === "function") {
        pdfViewer.cancel();
      }
    },
    zoomIn() {
      if (this.zoom < 100) {
        this.zoom += 5;
        if (this.zoomOutDisabled) {
          this.zoomOutDisabled = false;
        }
      } else {
        this.zoomInDisabled = true;
      }
    },
    zoomOut() {
      if (this.zoom > 30) {
        this.zoom -= 5;
        if (this.zoomInDisabled) {
          this.zoomInDisabled = false;
        }
      } else {
        this.zoomOutDisabled = true;
      }
    },
  },
  created() {
    window.addEventListener("keyup", this.handleKeyup, null);
  },
};
</script>
