<template>
    <div>
      <ValidationObserver ref="observer"  v-slot="{ handleSubmit}">
        <form @submit.prevent="handleSubmit(assignCargo)">
            <div class="modal-card custom-visibility" style="width: 100%">
              <header class="modal-card-head custom-visibility">
                <p class="modal-card-title">Asignar Cargo</p>

                <button
                    type="button"
                    class="delete"
                    @click="close()"
                    style="background: #ff3860"
                />
              </header>
                <div class="modal-card-body custom-visibility">
                      <div class="columns is-multiline">
                          <b-field class="column is-half" label="Roles">
                              <div>
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <multiselect class="custom-multiselect "
                                          v-model="selectedInternalPosition" :options="internal_positions"
                                                   :custom-label="opt => internal_positions.find(x => x.id === opt.id) != null ? internal_positions.find(x => x.id === opt.id).name:null "
                                                   :multiple="false"
                                                   placeholder="Seleccione un rol" selectLabel="Presione para seleccionar"
                                                   selectedLabel="Seleccionado"
                                                   deselectLabel="Presione para deseleccionar"
                                                   track-by="id"
                                                   label="name"
                                                   aria-expanded="true"
                                                   style="z-index: 9999;"

                                      >
                                          <template v-slot:noOptions>
                                              No existen datos
                                          </template>
                                          <span slot="noResult">
                                          No se encontraron elementos.
                                        </span>
                                      </multiselect>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </div>

                          </b-field>

                          <b-field class="column is-half"
                              label="Descripción"

                          >
                            <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="description"
                                     placeholder="Ingrese el motivo o descripción del rol">
                            </b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>

                          <b-field class="column is-half" label="Inicio del rol">
                            <ValidationProvider rules="required" v-slot="{errors}">
                              <b-datepicker v-model="start_date"
                                            :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                                            placeholder="No hay fecha definida de inicio"
                                            locale="es">
                              </b-datepicker>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                          <b-field class="column is-half" label="Fin del rol">
                            <ValidationProvider rules="required" v-slot="{errors}">

                              <b-datepicker v-model="end_date"
                                            trap-focus
                                            :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                                            placeholder="No hay fecha definida de termino"
                                            locale="es">
                              </b-datepicker>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>

                          </b-field>

                      </div>
                </div>
            </div>
            <footer class="modal-card-foot custom-visibility is-flex is-justify-flex-end">
              <div>
                  <b-button type="is-danger" @click="$emit('close')">Cancelar</b-button>
                  <b-button type="is-success"  native-type="submit" >Guardar cambios</b-button>

              </div>
            </footer>
        </form>
      </ValidationObserver>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>


    </div>
</template>


<script>
import axios from 'axios';
import moment from "moment";
import {isNil} from "buefy";

export default {
    name: "ModalAssignCargo",
    props: ["internal_positions", "user_id"],

    data() {
        return {
            isLoading: false,
            selectedInternalPosition: null,
            description: "",
            start_date: null,
            end_date: null,
            formData: {}

        }

    },
    methods: {
        updateDate() {
            this.start_date = this.selectedInternalPosition.start_date !== null ? new Date(this.selectedInternalPosition.start_date) : null;
            this.end_date = this.selectedInternalPosition.end_date !== null ? new Date(this.selectedInternalPosition.end_date) : null;
            ;
        },

        assignCargo() {
          this.isLoading = true
            this.formData = {
                "user_id": this.user_id,
                "internal_position_id": this.selectedInternalPosition.id,
                "description": this.description,
                "start_date": this.start_date,
                "end_date": this.end_date
            }
            axios
                .post("/user_internal_positions.json", this.formData).then(
                response => {

                    this.text = "Se ha asignado correctamente"

                    this.$buefy.dialog.confirm({
                            title: 'Listo',
                            message: this.text,
                            type: 'is-success',
                            hasIcon: true,
                            icon: 'check-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true,
                            canCancel: false,

                        }
                    );

                })
                .catch(error => {
                    console.log(error)
                    this.validationErrors = error.response.data
                    var elmnt = document.getElementById("app");
                    elmnt.scrollIntoView();
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al crear la solicitud, favor revisar formato en ' + Object.keys(error.response.data).toString(),
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });

                }).finally(() => {

              this.isLoading = false;
              this.close();
            });


        },
        close() {
          this.$emit('close')
        }
    }
}
</script>
<style>

.custom-visibility{
  overflow: visible !important;
}

.modal-card {
  width: auto !important;
}
.custom-multiselect .multiselect__content-wrapper {
  min-width: 100%;
  width: auto;
  border: none;
  box-shadow: 4px 4px 10px 0 rgba(0,0,0,.1);
}

</style>