<template>
    <div class="container is-fullheight" style="padding: 2rem;background-color: #F2F2F2;">
        <div class="section" style="background-color: white; height: 100%" ref="createRequestType">

            <!-- Titulo de la sección -->
            <div class="is-flex is-justify-content-flex-start">
                <h1 class="subtitle is-1 has-text-secondary">
                    Seleccione el tipo de solicitud
                </h1>
            </div>

            <!-- Separador -->
            <hr class="solid">

            <div class="mb-3 has-text-secondary">
                <h6 class="is-size-5">A continuación se listan los tipos de solicitudes que tiene disponibles según su
                    rol actual en la plataforma. Seleccione una para continuar:</h6>

            </div>

            <!-- Request Types Section -->
            <p v-if="requestTypes.length === 0"><i>Su rol no puede realizar ningun tipo de solicitud</i></p>
            <div v-if="requestTypes.length === 0" class="columns is-multiline">

                <div v-for="i in 8" :key="i" class="column is-one-quarter-desktop is-one-third-tablet is-half-mobile">
                    <article class="media">
                        <div class="media-content">
                            <div class="content p-2">
                                <p>
                                    <b-skeleton height="150px"></b-skeleton>
                                    <b-skeleton active></b-skeleton>
                                </p>
                            </div>
                        </div>
                    </article>
                </div>
            </div>

            <div v-else class="columns is-multiline">

                <div v-for="(request_type, request_type_index) in requestTypes" :key="request_type_index"
                     class="column is-one-quarter-desktop is-one-third-tablet is-full-mobile">

                    <div class="card has-background-primary p-2" style="min-height:100%">
                        <a :href="'/requests/new/' + request_type.id" target="_blank">
                            <b-tooltip :label="request_type.description" type="is-secondary is-light" multilined
                                       position="is-bottom">
                                <div class="card-content has-text-centered">
                                    <img class="mb-2" src="/document-icon.png" style="width:80%"
                                         :alt="request_type.real_name">
                                    <h1 class="title is-6 has-text-white">
                                        {{ request_type.real_name }}
                                    </h1>
                                </div>
                            </b-tooltip>
                        </a>
                    </div>
                </div>
            </div>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: "ChooseRequestType",
    data: () => {
        return {
            requestTypes: [],
            isLoading: false,
        }
    },
    created() {
      document.title="Iniciar una solicitud"
        this.isLoading = true;

        axios.get("/requests/request_types.json").then(
            response => {
                this.requestTypes = response.data.request_types;
                this.isLoading = false;
            }
        ).catch(
            error => {
                console.log(error);
                this.isLoading = false;
            }
        );
    },
    methods: {}
}
</script>
