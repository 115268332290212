<template>
    <div class="container is-fluid is-fullheight" style="padding: 2rem;width: 75%;background-color: #F2F2F2;height: 100vh">

        <div class="section mt-1 pt-0" style="background-color: white">
            <!-- Titulo de la sección -->
            <div class="is-flex is-justify-content-flex-start">
                <h1 class="subtitle is-1 has-text-secondary ">
                    Verificación de código
                </h1>
            </div>
            <!-- Separador -->
            <hr class="solid">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(verifyCode)">
                    <div class="columns">
                        <div class="column">
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <b-field label="Ingrese código de verificación*">
                              <b-input
                                  v-model="inputUser"
                                  class="is-primary"
                                  placeholder="Ingrese código de verificación de documento"
                              />
                            </b-field>
                                <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="column is-narrow">

                            <b-button class="is-secondary" icon-left="search" native-type="submit" style ="margin-top: 30px">Buscar</b-button>


                        </div>
                    </div>
                </form>
                <div class="container" v-if="exist">
                  <div class="columns box is-multiline is-vcentered">
                    <div class="column is-full ">
                      <div class="columns is-multiline">

                        <div class="column is-half">
                          <strong>Nombre completo:</strong> {{ tokenInfo.user.name.toUpperCase() }} {{ tokenInfo.user.last_name.toUpperCase() }} {{ tokenInfo.user.sur_name.toUpperCase() }}
                        </div>
                        <div class="column is-half">
                          <strong>RUT:</strong> {{ tokenInfo.user.rut }}-{{ tokenInfo.user.dv }}
                        </div>
                        <div class="column is-half">
                          <strong>Unidad:</strong> {{ tokenInfo.unit }}
                        </div>
                        <div class="column is-half">
                          <strong>Carrera:</strong> {{ tokenInfo.user.career }}
                        </div>
                      </div>
                    </div>
                    <div class="column is-full">
                      <div class="columns is-multiline is-vcentered ">
                        <div class="column is-half">
                          <strong>Tipo de Solicitud:</strong> {{tokenInfo.request_type }}
                        </div>
                        <div class="column is-half">
                          <strong>Fecha de Creación:</strong> {{ dateToString(tokenInfo.created_at) }}
                        </div>

                        <div class="column is-half">
                          <strong>Validez del documento:</strong>
                          <b-tag v-if="tokenInfo.active" icon="check" type="is-success" size="is-medium">
                            Activo
                          </b-tag>
                          <b-tag v-else icon="times" type="is-danger" size="is-medium">
                            No Activo
                          </b-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            </ValidationObserver>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {openBuefyAlertDialog, openBuefyConfirmDialog, dateToString} from "../../packs/utilities";

export default {
    name: "VerifyCode",
    props: [],
    components: {},
    data: function () {
        return {
            inputUser: "",
            isLoading: false,
            exist:false,

        }
    },
  created() {
      document.title ="Verificación de código"
  },

  methods: {
    dateToString,
    verifyCode(){
      this.isLoading= true
      this.exist=false
      return axios.get('/public/verify-code.json',{
            params: {
              code: this.inputUser,
            },
          })
          .then(response => {
            this.exist = response.data.exist
            this.tokenInfo = response.data
          })
          .catch(error => {
            this.exist = false
          }).finally(()=> {
            this.isLoading = false
            if (!this.exist){
              openBuefyAlertDialog(this,"Documento inválido","El documento no existe o ya es no es válido")
            }

          })

    }

  }
}
</script>