<template>
  <div>
    <div class="container">
      <!---->
      <section>
        <div v-if="canAppeal()">
          <div class="columns is-multiline pt-2">
            <div class="column is-full has-text-centered">
              <h1 class="subtitle is-3 has-text-secondary">
                <b>Información de la detención</b>
              </h1>
            </div>
            <div class="column is-full">
              <b-field v-if="appeals.length != 0">
                <b>Última observación:</b> {{ lastObservation() }}
              </b-field>
              <b-field v-else>
                <b>Observación inicial de la detención:</b>
                {{ initial_observation }}
              </b-field>

              <ValidationProvider rules="required|max:500" v-slot="{ errors }">
                <b-field label="Escriba una respuesta a la detención*">
                  <b-input
                    class="mx-2 pt-2"
                    type="textarea"
                    maxlength="500"
                    v-model="inputObservation"
                    placeholder="Escriba aquí su respuesta a la detención..."
                  >
                  </b-input>
                </b-field>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns is-multiline is-centered">
            <div class="column is-half has-text-centered">
              <b-upload v-model="document" size="is-large" expanded>
                <a class="button is-secondary">
                  <b-icon icon="upload"></b-icon>
                  <span>{{
                    document.name ||
                    "Click para subir un documento de respuesta a la detención"
                  }}</span>
                </a>
              </b-upload>
            </div>
            <div class="column is-half has-text-right">
              <b-button
                type="is-success"
                icon-left="file-import"
                @click="confirmObservation"
                :disabled="
                  !canAppeal() || disableButton || inputObservation === ''
                "
              >
                Enviar respuesta
              </b-button>
            </div>
            <div class="column is-full">
              <i>
                <b>Extensiones permitidas:</b> '.pdf', '.doc', '.docx', '.png',
                '.jpg', '.jpeg' <b>Tamaño máximo:</b> 10MB</i
              >
            </div>
          </div>

          <hr class="solid" />
        </div>
        <div class="columns is-multiline pt-2">
          <div class="column is-full has-text-centered">
            <h1 class="subtitle is-3 has-text-secondary">
              Historial de respuestas
            </h1>
          </div>
        </div>
        <b-table
          :paginated="appeals.length < 5 ? false : true"
          :pagination-simple="false"
          pagination-position="bottom"
          :current-page="currentPageTable"
          per-page="5"
          :data="appeals"
          :bordered="false"
          :striped="true"
          :narrowed="false"
          :hoverable="true"
          :focusable="false"
          :mobile-cards="true"
        >
          <b-table-column
            field="applicant_observation"
            :visible="true"
            label="Comentario solicitante"
            v-slot="props"
          >
            {{ props.row.applicant_observation }}
          </b-table-column>
          <b-table-column
            field="reviewer_observation"
            :visible="true"
            label="Comentario revisor"
            v-slot="props"
          >
            {{
              props.row.reviewer_observation != null
                ? props.row.reviewer_observation
                : "La corrección aún no ha sido contestada"
            }}
          </b-table-column>
          <b-table-column
            centered
            field="is_accepted"
            :visible="true"
            label="¿Aceptado?"
            v-slot="props"
          >
            <b-tag
              v-if="props.row.reviewer_observation === null"
              icon="clock"
              type="is-warning"
              size="is-medium"
              rounded
            >
              Pendiente
            </b-tag>
            <b-tag
              v-else-if="props.row.is_accepted"
              icon="check"
              type="is-success"
              size="is-medium"
              rounded
            >
              Sí
            </b-tag>
            <b-tag
              v-else
              icon="times"
              type="is-danger"
              size="is-medium"
              rounded
            >
              No
            </b-tag>
          </b-table-column>
          <b-table-column
            field="id_drive"
            :visible="true"
            label="Documento subido"
            v-slot="props"
          >
            <b-button
              v-if="props.row.id_drive !== null"
              class="is-centered"
              type="is-primary"
              @click="downloadFile(props.row.id_drive)"
            >
              Descargar <i class="fas fa-download ml-1"></i>
            </b-button>
            <div v-else>Sin Documento</div>
          </b-table-column>
        </b-table>
      </section>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import { verifyFile } from "../../../packs/utilities";

export default {
  name: "ModalAddObservationApplicant",
  props: [
    "request_state_id",
    "initial_observation",
    "lastState",
    "progressOfRequest",
  ],
  components: {},
  data: function () {
    return {
      inputObservation: "",
      responseData: [],
      //---------------------------------
      validationErrors: {},
      isLoading: false,
      form_link: "",
      document: [],
      appeals: [],
      formData: {},
      currentPageTable: 1,
      disableButton: false,
    };
  },

  created() {
    this.isLoading = true;
    //localhost:3000/appeals/get_state_appeals/1.json
    http: this.getAppeals();
  },

  methods: {
    getAppeals() {
      axios
        .get("/appeals/get_state_appeals/" + this.request_state_id + ".json")
        .then((response) => {
          this.appeals = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.appeals = [];
          this.isLoading = false;
        });
    },
    formatDate(date) {
      if (date != null && date !== "" && date !== undefined) {
        var arreglo = date.split("-");
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
      } else {
        return "";
      }
    },
    downloadFile(document_id) {
      this.isLoading = true;

      axios
        .request({
          url: "/documents/download_file/" + document_id,
          responseType: "blob",
        })
        .then((response) => {
          //var blobUrl = URL.createObjectURL(response.data);
          //window.open(blobUrl)

          let info = response.headers["content-disposition"];
          let name = info.split('"')[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        });
    },
    canAppeal() {
      // si no hay apelaciones puede apelar
      if (this.appeals.length === 0) {
        return true;
      }
      let last_appeal = this.appeals[0];

      if (this.lastState && this.progressOfRequest === "Detenida") {
        let stateUpdatedAt = new Date(this.lastState.updated_at);
        let lastAppealUpdatedAt = new Date(last_appeal.updated_at);
        if (stateUpdatedAt > lastAppealUpdatedAt) return true;
      }

      //si la ultima de las apelaciones no tiene observaciones esta pendiente
      if (last_appeal.reviewer_observation === null) {
        return false;
      }
      //si es que ya fue acceptada no es necesario apelar
      if (last_appeal.is_accepted) {
        return false;
      }
      return true;
    },
    confirmObservation() {
      this.$buefy.dialog.confirm({
        title: "Confirmación",
        message: `¿Desea enviar esta corrección? Luego de confirmar esta no podrá ser modificada`,
        cancelText: "Cancelar",
        confirmText: "Enviar",
        type: "is-success",
        onConfirm: () => this.pushObservation(),
      });
    },
    pushObservation() {
      let file_errors = verifyFile(this.document);
      if (file_errors) {
        this.dialogError(file_errors, "Error en el archivo");
        return;
      }
      this.isLoading = true;
      this.disableButton = true;
      let last_appeal_id = this.appeals.length > 0 ? this.appeals[0].id : null;

      this.formData = new FormData();
      this.formData.append("applicant_observation", this.inputObservation);
      this.formData.append("is_accepted", false);
      this.formData.append("request_state_id", this.request_state_id);
      this.formData.append("file", this.document);
      this.formData.append("parent_id", last_appeal_id);

      axios
        .post("/appeals.json", this.formData)
        .then((response) => {
          this.$buefy.dialog.confirm({
            title: "Listo",
            message: "La observación se ha creado exitosamente.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            canCancel: false,
          });
          this.getAppeals();
          this.$forceUpdate();
          this.disableButton = false;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.validationErrors = error.response.data.error;
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.dialogError(this.validationErrors, "Error");
          this.$forceUpdate();
          this.isLoading = false;
          this.disableButton = false;
        });
    },
    dialogError(message, title) {
      this.$buefy.dialog.alert({
        title: title,
        message: message,
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    lastObservation() {
      let last_appeal = this.appeals[0];
      if (this.lastState && this.progressOfRequest === "Detenida") {
        let stateUpdatedAt = new Date(this.lastState.updated_at);
        let lastAppealUpdatedAt = new Date(last_appeal.updated_at);
        if (stateUpdatedAt > lastAppealUpdatedAt) {
          return this.initial_observation;
        }
      }
      return last_appeal.reviewer_observation;
    },
  },
};
</script>
