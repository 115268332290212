<template>
  <div>
    <div class="is-size-5 mb-6">
      A continuación se detallan los precios de la documentación que se puede
      solicitar a través del sistema SAS.<br />
      Ten en cuenta que algunos de estos documentos deben ser pagados
      inmediatamente o desde la tercera solicitud realizada en el año. <br />
      Si la solicitud requiere de un pago, esta será detenida una vez realizada
      la solicitud y se le informará el monto a pagar. Ten en cuenta que tendrás
      <b>5 días hábiles</b> para responder desde el día siguiente a la
      detención.<br /><br />
      <b>¡IMPORTANTE!</b> El monto transferido debe ser exacto al precio
      indicado, no puede ser más, ni menos. Además, al momento de enviar el
      comprobante de pago, debe asegurarse de que se vean todos los datos
      respectivos emitidos por el banco. Si el depósito lo realiza una tercera
      persona, además de informar su nombre y RUT. En caso depositar un monto
      equivocado, usted deberá volver a realizar la transferencia por el monto
      completo. <br />
      Ante cualquier duda ponerse en contacto con
      <b>registrocurricular.fing@usach.cl</b>. A continuación se detalla la
      información.<br />
    </div>
    <div class="title is-3">Precios de Certificados</div>
    <b-table class="is-border-light-grey" :data="paymentInfo">
      <b-table-column label="Tipo de Solicitud" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column label="Precio" v-slot="props">
        {{ props.row.price }}
      </b-table-column>
      <b-table-column label="Pago desde" v-slot="props">
        {{ props.row.paid_immediately }}
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      paymentInfo: [
        {
          name: "Certificado Título en Trámite",
          price: "16.500 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado Grado en Trámite",
          price: "16.500 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado Ranking Licenciatura",
          price: "29.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado Ranking Egreso",
          price: "29.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado Ranking Titulación",
          price: "29.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de Horas Pedagógicas",
          price: "29.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de Egreso",
          price: "23.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de Calificaciones",
          price: "88.200 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de la Superintendencia de Electricidad y Combustibles - Instalador(a) de Gas",
          price: "29.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado Conducente para Proseguir Estudios de Postgrado",
          price: "29.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado Conducente para Proseguir Estudios de Postgrado",
          price: "29.400 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de Calificaciones de Cursos de Educación Continua",
          price: "36.500 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de Grado en Trámite de Magister",
          price: "16.500 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de Grado en Trámite de Doctorado",
          price: "16.500 (CLP)",
          paid_immediately: "La tercera solicitud en el año",
        },
        {
          name: "Certificado de No Impedimento Académico",
          price: "Exento",
          paid_immediately: "Exento",
        },
        {
          name: "Certificado de No Impedimento Académico",
          price: "Exento",
          paid_immediately: "Exento",
        },
        {
          name: "Certificado de Alumno(a) Regular",
          price: "Exento",
          paid_immediately: "Exento",
        },
        {
          name: "Boletines",
          price: "Exento",
          paid_immediately: "Exento",
        },
      ],
    };
  },
};
</script>
