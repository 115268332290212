import moment from "moment";
import axios from "axios";

let MAX_SIZE = 10; //MB
let MAX_FILE_SIZE = MAX_SIZE * 1024 * 1024;
export let OVERDUE_DAY = 14; //dias desde la creacion que deben pasar para que venza una solicitud
export let CLOSE_TO_OVERDUE= 5; // dias restante para considerar que va a vencer
export let PROGRESSES= {

"Enviada":{
    id:5,
    icon: "paper-plane",
    color: "is-primary",
  },
  "En Curso":{
    id:2,
    icon: "clock",
    color: "is-in_progress_request",
  },
  "Desistida":{
    id:8,
    icon: "user-times",
    color: "is-desist_request",
  },
  "Aceptada":{
    id:6,
    icon: "check-double",
    color: "is-accepted_request",
  },
  "Detenida":{
    id:4,
    icon: "stop",
    color: "is-stopped_request",
  },
  "Rechazada":{
    id:7,
    icon: "times",
    color: "is-denied_request",
  },
  "No Corresponde":{
    id: 9,
    icon: "file-excel",
    color: "is-dont-correspond-request",
  },
  "No Procesada":{
    id: 10,
    icon: "comment-slash",
    color: "is-unprocessed-request",
  },
}

/**
 * Funcion que busca coincidencias dentro de una fecha. Esta fecha se formatea a string
 * con el formato DD-MM-YYYY y se le realiza un match con el input.
 * @param {moment} date recibe como parametro una fecha de tipo objeto creado por moment.
 * @param {string} input recibe un string del cual se le hara la busqueda dentro de la fecha ya formateada a string.
 * @return {boolean} retorna un booleano que indica si el input esta incluida en la fecha.
 */
export function searchDate(date, input) {
  return (
    input &&
    date.format("DD-MM-YYYY") &&
    date.format("DD-MM-YYYY").includes(input)
  );
}

export function formatDate(date) {
  if (date != null && date !== "" && date !== undefined) {
    var arreglo = date.split("-");
    return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
  } else {
    return "";
  }
}

/**
 * Funcion que trae información respecto a un estado de una solicitud

 * @param {string} progress_name nombre estado de la solicitud.
 * @return {json} json con el icono asociado al estado (icon), color (color) y descripcion del estado (description)
 */
export function getStateInfo(progress_name){
  let default_progress = {
    icon: "question",
    color:"is-primary",
    description:"Estado sin información asociada"
  }
  return PROGRESSES[progress_name] || default_progress
}
/**
 * Funcion que verifica si un archivo es valido, en caso de no serlo arroja el string de error
 * @param {File} file recibe como parametro un archivo ingresado por el usuario.
 * @return {String} retorna un string vacio si esta correcto y con el error si no.
 */
export function verifyFile(file) {
  if (!file || file.length == 0) {
    return "";
  }

  let supported_extensions = [".pdf", ".doc", ".docx", ".png", ".jpg", ".jpeg"];
  if (file.size > MAX_FILE_SIZE) {
    return "No se permiten archivos con peso superior a 10MB";
  }
  var fileExt = "." + file.name.split(".").pop();

  if (!supported_extensions.includes(fileExt.toLowerCase())) {
    return (
      "Solo se permiten las siguientes extensiones: " + supported_extensions
    );
  }
  return "";
}

export function formatString(array) {
  let string = "";
  for (var i = 0; i < array.length; i++) {
    let partial = array[i];
    if (i === 0) {
      string = string + partial;
    } else {
      string = string + "\n" + partial;
    }
  }
  return string;
}

export function setIntervals(interval, anio, usuario) {
  const today = moment();
  let interval_end = "";
  let interval_start = "";
  let text = "";
  if (interval === "DIA") {
    interval_start = today.startOf("day").format("DD/MM/YYYY");
    interval_end = today.endOf("day").format("DD/MM/YYYY");
    text =
      "Fechas entre: " +
      interval_start.toString() +
      " - " +
      interval_end.toString();
  } else if (interval === "SEMANA") {
    interval_start = today.startOf("week").format("DD/MM/YYYY");
    interval_end = today.endOf("week").format("DD/MM/YYYY");
    text =
      "Fechas entre: " +
      interval_start.toString() +
      " - " +
      interval_end.toString();
  } else if (interval === "MES") {
    interval_start = today.startOf("month").format("DD/MM/YYYY");
    interval_end = today.endOf("month").format("DD/MM/YYYY");
    text =
      "Fechas entre: " +
      interval_start.toString() +
      " - " +
      interval_end.toString();
  } else if (interval === "AÑO") {
    //AÑO
    if (anio !== null && anio !== undefined && anio !== "ACTUAL") {
      today.set({ year: anio });
    }
    interval_start = today.startOf("year").format("DD/MM/YYYY");
    interval_end = today.endOf("year").format("DD/MM/YYYY");
    text =
      "Fechas entre: " +
      interval_start.toString() +
      " - " +
      interval_end.toString();
  } else {
    text = "Se muestran todos los datos históricos";
  }
  if (usuario) {
    text =
      text +
      ". " +
      "Se muestran los datos correspondientes al usuario: " +
      usuario.name;
  }
  return text;
}

export function createYears() {
  var i = 0;
  var actualDate = new Date();
  var array = ["ACTUAL"];
  for (i = 0; i < 10; i++) {
    array.push(actualDate.getFullYear() - 1 - i);
  }
  return array;
}

export function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = 1980;
  var years = [];
  years.push("Todo");
  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}
/**
 * Funcion que crea un dialogo de confirmacion de buefy
 * @param {} vm recibe this.
 * @param {String} title recibe ell titulo del dialogo.
 * @param {String} message recibe el contenido del dialogo.
 * @param {String} confirmText recibe el contenido boton de confirmacion.
 * @param {String} icon icono a usar.
 * @param {()} confirmAction accion que deberia realizarse al confirmar.
 * @param {()} cancelAction accion que deberia realizarse al cancelar.
 */
export function openBuefyConfirmDialog(vm,title,message,confirmText="Aceptar",icon="check-circle",confirmAction=null ,cancelAction=null) {
  vm.$buefy.dialog.confirm({
    title: title,
    message: message,
    cancelText: "Cerrar",
    confirmText: confirmText,
    type: "is-success",
    hasIcon: true,
    icon: 'check-circle',
    ariaRole: 'alertdialog',
    ariaModal: true,
  });
}
  /**
   * Funcion que crea un dialogo de confirmacion de buefy
   * @param {} vm recibe this.
   * @param {String} title recibe ell titulo del dialogo.
   * @param {String} message recibe el contenido del dialogo.
   * @param {String} confirmText recibe el contenido boton de confirmacion.
   * @param {String} icon icono a usar.
   * @param {()} confirmAction accion que deberia realizarse al confirmar.
   * @param {()} cancelAction accion que deberia realizarse al cancelar.
   */
  export function openBuefyAlertDialog(vm,title,message,confirmText="Aceptar",icon="times-circle",confirmAction=null ,cancelAction=null) {
    vm.$buefy.dialog.confirm({
      title: title,
      message: message,
      cancelText: "Cerrar",
      confirmText: confirmText,
      type: "is-danger",
      hasIcon: true,
      icon: icon,
      ariaRole: 'alertdialog',
      ariaModal: true,
    });
  }
    /**
* @param {BuefyDialog} vm
 * @param {int} userId
 * @param {boolean} disabled
 * @return {void}
 */
  export function disableUser(vm,userId,disabled){

    let title= "Deshabilitar usuario"
    let message = "Esta acción deshabilitará al usuario."
    if (disabled){
      title= "Habilitar usuario"
      message = "Esta acción habilitará al usuario."
    }
    vm.$buefy.dialog.confirm({
      title: title,
      message: message,
      cancelText: "Cancelar",
      confirmText: "Aceptar",
      type: "is-success",
    });
  }
/**
 * @param {string} document Nombre del documento debe seguir la nomenclatura de drive
 * @return {string} nombre mostrado a público
 */
export function  setName(document){
  if(document.includes("Documento Detenido")){
    return "Documento enviado en corrección a una detención"
  }
  else if (document.includes("[RESPUESTA FINAL]")){
    return document.split("-")[0]+""
  }
  else {
    return document.split("-")[0]
  }

}

/**
 * Esta funcion permite la busqueda de elementos con coincidencia parcial, puede usarse para las tablas de b-table como una custom-search pasandole el texto en vez de la columna
 * @param {String} text, el texto con el que se quiere hacer la comparacion
 * @param {String} input, El texto ingresado por el usuario
 * @return {boolean}
 */
  export function parcialSearch(text,input){
    const regex = new RegExp(input, 'i')
    return regex.test(text)

  }
/**
 * Convierte una fecha en un string, se hizo pensando en los campos de calendarios
 * @param {Date} date, Fecha de calendario del estilo 2023-11-10T03:00:00.000Z
 * @return {String} 10-11-2023
 */
  export function dateToString(date){
    date = new moment(date, "YYYY-MM-DD HH:mm:ss")
    return date.format("DD-MM-YYYY")

  }
/**
 * Convierte una fecha en un string incluyendo la hora, se hizo pensando en las observaciones internas
 * @param {Date} date, Fecha de calendario del estilo 2023-11-10T03:00:00.000Z
 * @return {String} 10:34 10-11-2023
 */
export function dateToStringWithHour(date){
  date = new moment(date, "YYYY-MM-DD HH:mm:ss")
  return date.format("DD-MM-YYYY HH:mm")

}

