<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <div class="column">
            <h3 class="is-size-3 has-text-weight-bold">Reportería GCC</h3>
          </div>


        </div>
        <div>
          <div class="box">
            <div class="columns is-multiline my-2 px-5">
              <!-- Unit filter -->
              <div class="column is-one-third">

                  <b-field label="Filtrar por Unidad:">
                    <multiselect
                        v-model="selectedUnitOption"
                        :options="units"
                        :custom-label="(opt) => units.find((x) => x.id === opt.id).name"
                        :multiple="false"
                        placeholder="Seleccione una unidad"
                        selectLabel="Presione para seleccionar"
                        selectedLabel="Seleccionado"
                        deselectLabel="Presione para deseleccionar"
                        track-by="id"
                        label="name"
                    >
                      <template v-slot:noOptions> No existen datos</template>
                      <span slot="noResult"> No se encontraron elementos. </span>
                    </multiselect>
                  </b-field>
              </div>

              <!-- StartDate filter -->
              <div class="column is-one-quarter">
                <b-tooltip
                    type="is-info"
                    label="Filtro en base a la fecha de inicio de una solicitud."
                >
                  <b-field label="Filtrar por Fecha Desde:">

                    <b-datepicker
                        placeholder="Selecciona una Fecha"
                        icon="calendar-alt"
                        trap-focus
                        v-model="selectedStartDate"
                        :icon-right="selectedStartDate ? 'close-circle' : ''"
                        icon-right-clickable
                        @icon-right-click="clearStartDate"
                        :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                        locale="es"
                    >
                    </b-datepicker>
                  </b-field>
                </b-tooltip>
              </div>

              <!-- EndDate filter -->
              <div class="column is-one-quarter">
                <b-tooltip
                    type="is-info"
                    label="Filtro en base a la fecha de inicio de una solicitud."
                >
                  <b-field label="Filtrar por Fecha hasta:">
                    <b-datepicker
                        placeholder="Selecciona una Fecha"
                        icon="calendar-alt"
                        icon-pack="fas"
                        trap-focus
                        v-model="selectedEndDate"
                        :icon-right="selectedEndDate ? 'close-circle' : ''"
                        icon-right-clickable
                        @icon-right-click="clearEndDate"
                        :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                        locale="es"
                    >
                    </b-datepicker>
                  </b-field>
                </b-tooltip>
              </div>

              <!-- Button to Apply filters -->
              <div class="column is-flex is-align-items-end is-justify-content-end">
                <b-button
                    type="is-secondary"
                    v-on:click="applyFilters"
                    icon-left="search"
                >Aplicar Filtros
                </b-button>
              </div>
            </div>
          </div>
          <br>
        </div>
        <div class="box">


          <div class="columns is-multiline is-centered">
            <div class="column is-full has-text-centered">

              <h1 class="subtitle is-3 has-text-secondary">
                Indicadores de solicitudes
              </h1>
            </div>

            <div class="column is-4 has-text-centered">
              <div class="subtitle is-4 has-text-weight-bold mb-0">Total</div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.total_requests }}
              </div>
            </div>

            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                Enviadas
                <b-button
                  class="is-send_request custom-button ml-1"
                  @click="openModal('send')"
                  icon-right="eye"
                  outlined
                >
                </b-button>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.waiting_requests }}
              </div>
            </div>

            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                En curso
                <b-button
                  class="is-in_progress_request custom-button ml-1"
                  @click="openModal('inProgress')"
                  icon-right="eye"
                  outlined
                >
                </b-button>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.in_course_requests }}
              </div>
            </div>



            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                Por vencer
                <b-tooltip
                  type="is-info"
                  label="Quedan 5 o menos días hábiles para vencer"
                >
                  <b-button
                    class="custom-button ml-1 mt-2"
                    @click="openModal('closeToOverdue')"
                    icon-right="eye"
                    outlined
                  >
                  </b-button>
                </b-tooltip>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.close_overdue_request }}
              </div>
            </div>

            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                Vencidas
                <b-tooltip
                  type="is-info"
                  label="Creadas hace más de 14 días hábiles"
                >
                  <b-button
                    class="is-danger custom-button ml-1 mt-2"
                    @click="openModal('Overdue')"
                    icon-right="eye"
                    outlined
                  >
                  </b-button>
                </b-tooltip>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.overdue_requests }}
              </div>
            </div>

            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                Detenidas
                <b-button
                  class="is-stopped_request custom-button ml-1"
                  @click="openModal('stopped')"
                  icon-right="eye"
                  outlined
                >
                </b-button>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.stopped_requests }}
              </div>
            </div>

            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                Aceptadas
                <b-button
                  class="is-success custom-button ml-1"
                  @click="openModal('accepted')"
                  icon-right="eye"
                  outlined
                >
                </b-button>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.accepted_requests }}
              </div>
            </div>

            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                No Corresponden
                <b-button
                  class="is-dont-correspond-request custom-button ml-1"
                  @click="openModal('notCorrespond')"
                  icon-right="eye"
                  outlined
                >
                </b-button>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.not_correspond_requests }}
              </div>
            </div>

            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                No Procesadas
                <b-button
                  class="is-unprocessed-request custom-button ml-1"
                  @click="openModal('unprocessed')"
                  icon-right="eye"
                  outlined
                >
                </b-button>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.unprocessed_requests }}
              </div>
            </div>
            <div class="column is-4 has-text-centered">
              <div
                class="subtitle is-4 has-text-weight-bold mb-0 is-flex is-align-items-center is-justify-content-center"
              >
                Desistidas
                <b-button
                  class="is-desist_request custom-button ml-1"
                  @click="openModal('desisted')"
                  icon-right="eye"
                  outlined
                >
                </b-button>
              </div>
              <div class="subtitle is-4 has-text-weight-bold">
                {{ request_stats.desisted_requests }}
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="box">
        <!-- End Filters Section -->
          <div class="columns ">
            <div class="column has-text-centered">
              <h1 class="subtitle is-3 has-text-secondary">
                Cantidad de solicitudes según tipo de solicitud
              </h1>
            </div>
          </div>
          <div class="columns ">
            <div class="column has-text-left">
              <b-button
                  label="Resumen de solicitudes por usuarios"
                  type="is-secondary"
                  icon-left="download"
                  @click = "downloadStats()"
              />
            </div>
            <div class="column has-text-right has-text-centered">
              <b-collapse v-model="isOpenTableInfo" :open="false" aria-id="contentIdForA11y1">
                <template #trigger="props">
                  <b-button
                      label="Información de la tabla"
                      type="is-info"
                      icon-left="info"
                      aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"

                  />
                </template>
              </b-collapse>
            </div>
          </div>
          <div v-if="isOpenTableInfo" class="notification">
            <div class="content has-text-left">
              <p>
                <b>N° de solicitudes finalizadas:</b> Solicitudes que han sido <em>Aceptadas</em>, <em>Desistidas</em>, <em>No Correspondidas</em> y <em>No Procesadas</em>.<br>
                <b>Días hábiles promedio de revisión:</b> Se consideran solo las solicitudes <em>Aceptadas</em> y <em>Rechazadas</em>
                (solicitudes revisadas),
                esto debido a que son las terminadas por revisores.
                Se consideran solo días habiles en el promedio y la manera obtener el promedio es la siguiente<br>
                Se calculan los días hábiles de todas las solicitudes, se suman entre sí y se dividen por la cantidad de solicitudes finalizadas<br>
                <em>Notas:
                  <ul>
                    <li>Cabe destacar que si una solicitud es iniciada luego de las 18:00 hrs, esta cuenta desde el día siguiente.</li>
                    <li>Un día hábil va desde lunes a viernes de 9 a 18. </li>
                    <li>Los feriados no suman al promedio, no así los recesos intra-universitarios.</li>
                  </ul>
                </em>
                <br>
                <b>N° de solicitudes en curso:</b> Solicitudes sin asignar y en revisión.<br>
                <b>N° de solicitudes totales:</b> La cantidad de solicitudes <em>Finalizadas</em> más las solicitudes <em>En Curso</em>.<br>

              </p>
            </div>
          </div>

          <!-- Table Section -->
          <b-table
              class="pt-2"
              :data="request_type_stats == null ? [] : request_type_stats"
              :paginated="true"
              :pagination-simple="false"
              pagination-position="bottom"
              :current-page="currentPageTable"
              per-page="15"
              :bordered="false"
              :striped="true"
              :narrowed="false"
              :hoverable="true"
              :loading="isLoading == null ? true : false"
              :focusable="false"
              :mobile-cards="true"
          >
            <!-- Column Name -->

            <b-table-column
                field="name"
                :visible="true"
                label="Nombre del tipo de solicitud"
                sortable
                searchable :custom-search="funcionCustom"
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar por nombre"
                    icon="search"
                    size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.request_type }}

              </template>
            </b-table-column>

            <!-- Column number of requests finalizadas-->
            <b-table-column
                field="accepted"
                :visible="true"
                centered
                sortable
                label="N° de solicitudes aceptadas"
            >
              <template v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{ props.row.accepted }}
                </span>
              </template>
            </b-table-column>

            <b-table-column
                field="not_correspond"
                :visible="true"
                sortable
                label="N° de solicitudes que no corresponden"
            >
              <template v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{ props.row.not_correspond }}
                </span>
              </template>
            </b-table-column>
            <b-table-column
              field="unprocessed"
              :visible="true"
              label="N° de solicitudes que no fueron procesadas"
            >
              <template v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{ props.row.unprocessed }}
                </span>
              </template>
            </b-table-column>
            <!-- Column number of requests desistidas-->
            <b-table-column
                field="desisted"
                :visible="true"
                sortable
                label="N° de solicitudes desistidas"
            >
              <template v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{ props.row.desisted }}
                </span>
              </template>
            </b-table-column>

            <!-- Column number of requests -->


            <b-table-column
                field="finished"
                :visible="true"
                centered
                sortable
                cell-class="custom-color"
                label="N° de solicitudes finalizadas"
            >
              <template  v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{ props.row.finished }}
                </span>
              </template>
            </b-table-column>
            <b-table-column
                field="average_days_to_complete"
                :visible="true"
                centered
                sortable
                label="Días hábiles promedio de revisión"
            >
              <template v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{
                    props.row.average_days_to_complete == null
                        ? "-"
                        : props.row.average_days_to_complete
                  }}
                </span>
              </template>
            </b-table-column>
            <!-- Column number of requests encurso-->

            <b-table-column
                field=""
                :visible="true"
                centered
                sortable
                label="N° de solicitudes en curso"
            >
              <template v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{ props.row.total - props.row.finished }}
                </span>
              </template>
            </b-table-column>

            <!-- Column number of requests totales-->
            <b-table-column
                field="total"
                :visible="true"
                centered
                sortable
                cell-class="custom-color"
                label="N° de solicitudes totales"
            >
              <template v-slot="props">
                <span class="is-flex is-justify-content-center">
                  {{ props.row.total }}
                </span>
              </template>
            </b-table-column>
            <b-table-column
                v-slot="props"
                :visible="true"
                centered
                label="Ver todas las solicitudes de un tipo"
            >
              <b-button
                  class="is-in_progress_request"
                  @click="openModal(props.row.request_type_id)"
                  icon-right="eye"  outlined>

              </b-button>
            </b-table-column>


            <template #empty>
              <div class="has-text-centered">
                No se encontró ninguna estadística con los filtros ingresados.
              </div>
            </template>
          </b-table>
        </div>
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
        ></b-loading>
      </div>

    </div>

    <b-modal
        v-model="isModalRequestActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%" height="90%"
    ><template #default="props">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head has-background-secondary" style="width: auto;max-width:100%;">
          <p class="modal-card-title has-text-white" style="width: auto; max-width: 100%;">Solicitudes</p>
          <button
              type="button"
              class="delete"
              @click="isModalRequestActive = false"/>
        </header>
        <section class="modal-card-body" >
      <table-list-requests
          :applicant_information="true"
          :actions="false"
          :requests="modalRequests"
          :reviewer_info="true"
      >
      </table-list-requests>
        </section>
        <footer class="modal-card-foot actions has-background-secondary">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="isModalRequestActive = false">Cerrar</button>
          </div>
        </footer>
      </div>
    </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import ModalCreateState from "../States/ModalCreateState.vue";
import TableListRequests from "../Requests/TableListRequests.vue";

import {OVERDUE_DAY,CLOSE_TO_OVERDUE} from "../../packs/utilities";
import moment from "moment";
//import UserAudit from "./UserAudit";
//import GeneralAudit from "./GeneralAudit";

export default {

  name: "GccStatistics",
  components: {TableListRequests, ModalCreateState},
  props: [],
  data: function () {
    return {
      request_stats: [],
      request_type_stats: [],
      currentPageTable: 1,
      states: [
        "",
        "Depto. Académico",
        "Secretaria FING",
        "Secretaria Académica",
        "Decano",
      ],
      selectedStateOption: null,
      units: ["", "Depto. Informatica", "Facultad de Ingeniería"],
      selectedUnitOption: null,

      selectedStartDate: null,
      selectedEndDate: null,
      isLoading: false,
      requests: null,
      response: "",
      modalRequests:[],
      isModalRequestActive:false,
      isOpenTableInfo: false,
    };
  },
  methods: {
    async openModal(filter){
      this.isLoading = true
      let start_date  = this.selectedStartDate == null
          ? null
          : this.selectedStartDate.getFullYear() +
          "-" +
          (this.selectedStartDate.getMonth() + 1) +
          "-" +
          this.selectedStartDate.getDate()
let end_date  = this.selectedEndDate == null ? null
          : this.selectedEndDate.getFullYear() +
          "-" +
          (this.selectedEndDate.getMonth() + 1) +
          "-" +
          this.selectedEndDate.getDate();

      let progress_id =null;
      let overdue= false;
      let close_overdue=false;
      let request_type_id =null;
      if(isNaN(filter)) {
        switch (filter) {
          case 'inProgress':
            progress_id = 2;
            break;
          case 'send':
            progress_id = 5;
            break;
          case 'stopped':
            progress_id = 4;
            break;
          case 'closeToOverdue':
            close_overdue = true;
            break;
          case 'unprocessed':
            progress_id = 10;
            break;
          case 'notCorrespond':
            progress_id = 9;
            break;
          case 'accepted':
            progress_id = 6;
            break;
          case 'desisted':
            progress_id = 8;
            break;
          case 'Overdue':
            overdue = true;
            break;

        }
      }
      else{
        request_type_id = filter;
      }

      let params = {
        "unit_id": this.selectedUnitOption!=null ? this.selectedUnitOption.id : null,
        "start_date": start_date,
        "end_date": end_date,
        "progress_id":progress_id,
        "close_overdue": close_overdue,
        "overdue": overdue,
        "request_type_id": request_type_id
      }


     await axios
          .get("/requests/apply-gcc-filters-statistics.json", {
            params,
          }).then((response) => {
           this.modalRequests = response.data.requests;

           this.modalRequests = this.modalRequests == undefined ? [] : this.modalRequests
           this.modalRequests = this.modalRequests.map((request) => {
             var newRequests = request;

             newRequests["start_date"] = moment(
                 request["start_date"],
                 "YYYY-MM-DD HH:mm:ss"
             ).format("DD-MM-YYYY");
             newRequests["end_date"] = newRequests["end_date"] != null ? moment(
                 request["end_date"],
                 "YYYY-MM-DD HH:mm:ss"
             ).format("DD-MM-YYYY") : null;
             return newRequests;
           });
           this.isModalRequestActive=true
           this.isLoading = false;

         })
         .catch((e) => {
           console.log(e);
           this.isModalRequestActive=true
           this.isLoading = false;

         });


    },
    async downloadStats(){
      this.isLoading = true
      let start_date  = this.selectedStartDate == null
          ? null
          : this.selectedStartDate.getFullYear() +
          "-" +
          (this.selectedStartDate.getMonth() + 1) +
          "-" +
          this.selectedStartDate.getDate()
      let end_date  = this.selectedEndDate == null ? null
          : this.selectedEndDate.getFullYear() +
          "-" +
          (this.selectedEndDate.getMonth() + 1) +
          "-" +
          this.selectedEndDate.getDate();
      var url = "/user_requests/gcc-reviewer-statistics.xlsx?start_date="+start_date+"&end_date="+end_date;
      window.open(url);
     this.isLoading =false;


    },
    funcionCustom(row,input){

      const regex = new RegExp(input, 'i')
      if (regex.test(row.request_type)) {
        return true;
      }
      return false;
    },
    async getFilters() {
      await axios
          .get("/units/deptos.json")
          .then((response) => {
            this.units = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.units = [];
          });

    },

    applyFilters() {
      this.isLoading = true;
      let params = {
        unit_id:
            this.selectedUnitOption == null ? null : this.selectedUnitOption.id,

        start_date:
            this.selectedStartDate == null
                ? null
                : this.selectedStartDate.getFullYear() +
                "-" +
                (this.selectedStartDate.getMonth() + 1) +
                "-" +
                this.selectedStartDate.getDate(),
        end_date:
            this.selectedEndDate == null
                ? null
                : this.selectedEndDate.getFullYear() +
                "-" +
                (this.selectedEndDate.getMonth() + 1) +
                "-" +
                this.selectedEndDate.getDate(),
      };

      axios
          .get("/request_types/gcc/statistics.json", {
            params,
          })
          .then((response) => {
            //console.log(response)
            this.request_stats = response.data.request_stats;
            this.request_type_stats = response.data.request_type_stats;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
    },
    clearStartDate() {
      this.selectedStartDate = null;
    },
    clearEndDate() {
      this.selectedEndDate = null;
    },
  },
  created() {
    this.isLoading = true;
    let fechaActual = new Date()
    this.selectedStartDate = new Date(fechaActual.getFullYear(), 0, 1);
    this.selectedEndDate = new Date(fechaActual.getFullYear(), 11, 31);
    document.title = "Reportería GCC"
    this.getFilters();
    this.applyFilters();
  },
};
</script>
<style>
.custom-column-button{
  margin-top:0.3rem;
}
.custom-button {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin: 0;
  height: 24px; /* Ajusta la altura según tus necesidades */
}

.custom-button .icon {
  margin-right: 0.5rem;
}

.custom-button .button-content {
  line-height: 24px; /* Ajusta la altura de línea según tus necesidades */
}
.custom-color {
  background-color: #EFEFF2;

}
</style>