<template>
    <div class="container is-fullheight" style="padding: 2rem;background-color: #F2F2F2;">
        <div class="section" style="background-color: white; height: 100%" ref="createRequestType">

            <!-- Titulo de la sección -->
            <div class="is-flex is-justify-content-flex-start is-align-items-center">
                <div class="is-flex is-flex-wrap-nowrap">
                    <h1 class="title is-1 has-text-secondary mb-0 mr-5">
                        Solicitud Código {{ request.code }}
                    </h1>
                </div>
                <span class="tag is-primary is-large">{{ requestType.real_name }}</span>
            </div>

            <!-- Separador -->
            <hr class="solid">

            <div class="columns is-multiline my-2 px-5">
                <div class="column is-full">
                    <b>Fecha de Inicio:</b> {{ formatDate(request.start_date) }}
                </div>
                <div class="column is-full">
                    <b>Descripción:</b>
                    <div class="card">
                        <div class="card-content">
                            <div class="content">
                                <span v-if="request.description" style="white-space: pre-line">{{
                                    request.description
                                    }}</span>
                                <span v-else style="white-space: pre-line; color: darkgrey">Esta solicitud no tiene descripción.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns is-multiline mt-3 mb-2 px-5">
                <div class="column is-full is-flex is-align-items-center is-justify-content-space-between">
                    <h1 class="subtitle is-2 has-text-secondary">
                        Historial de Revisión
                    </h1>

                </div>
            </div>
            <!-- Table Section -->
            <b-table :data="history_review == null ? [] : history_review" :paginated="true" :pagination-simple=false
                     pagination-position="bottom" :current-page="currentPageTable" per-page="15" :bordered="false"
                     :striped="true" :narrowed="false" :hoverable="true" :loading="isLoading == null ? true : false"
                     :focusable="false" :mobile-cards="true">


                <!-- Column number of requests -->
                <b-table-column field="reviewer" :visible="true" centered width="550" label="Revisor" searchable>
                    <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por nombre"
                                 icon="search" size="is-small"/>
                    </template>
                    <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ props.row.reviewer }}
                    </span>
                    </template>

                </b-table-column>

                <!-- Column number of requests finalizadas-->
                <b-table-column field="internal_position" :visible="true" centered width="550" label="Rol" searchable>
                    <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por rol" icon="search"
                                 size="is-small"/>
                    </template>
                    <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ props.row.internal_position }}
                    </span>
                    </template>

                </b-table-column>

                <!-- Column Name -->
                <b-table-column field="real_name" :visible="true" label="Pasos" width="250" sortable searchable
                                centered>
                    <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por paso" icon="search"
                                 size="is-small"/>
                    </template>
                    <template v-slot="props">
                        {{ props.row.state }}
                    </template>
                </b-table-column>

                <!-- Column number of requests detenidas-->
                <b-table-column field="description" :visible="true" centered width="550" label="Acción">
                    <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ props.row.description }}
                    </span>
                    </template>

                </b-table-column>

                <!-- Column number of requests finalizadas-->
                <b-table-column field="date" :visible="true" centered width="550" label="Fecha">
                    <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ formatDate(props.row.date) }}
                        <br>
                        {{ formatHour(props.row.date) }}
                    </span>
                    </template>

                </b-table-column>

                <template #empty>
                    <div class="has-text-centered">Esta solicitud aún no está siendo revisada</div>
                </template>
            </b-table>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>
    </div>
</template>


<script>
import axios from "axios"

export default {
    name: "RevisionHistory",
    components: {},
    props: ['requestId'],
    data: function () {
        return {
            history_review: [],
            request: {},
            requestType: "",
            currentPageTable: 1,

        }
    },
    methods: {

        formatHour(date) {
            let fecha_hora = date.split("T");
            date = fecha_hora[1];
            if (date != null && date !== "" && date !== undefined) {
                return date.substring(0, 5)

            }
        },


        formatDate(date) {
            let fecha_hora = date.split("T");
            date = fecha_hora[0];
            if (date != null && date !== "" && date !== undefined) {
                var arreglo = date.split("-")
                return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0]
            } else {
                return ""
            }
        },
    },
    created() {
        this.isLoading = true;
        axios
            .get("/requests/" + this.requestId + "/revision_history.json")
            .then(response => {
                //console.log(response)
                this.history_review = response.data.history_reviews;
                this.request = response.data.request
                this.requestType = response.data.request_type

                this.isLoading = false;
            })
            .catch(e => {
                console.log(e);
                this.isLoading = false;
            });

    },
}
</script>
