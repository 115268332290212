import { render, staticRenderFns } from "./GccStatistics.vue?vue&type=template&id=dffbf01e"
import script from "./GccStatistics.vue?vue&type=script&lang=js"
export * from "./GccStatistics.vue?vue&type=script&lang=js"
import style0 from "./GccStatistics.vue?vue&type=style&index=0&id=dffbf01e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports