<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveState)">
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head has-background-secondary">
            <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="ModalCreateState">
            <div class="columns">
              <div class="column">
                <div
                  class="mb-2"
                  v-if="Object.keys(validationErrors).length !== 0"
                >
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item, index) in validationErrors" :key="index">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

            <div class="columns" v-if="is_reviewer">
              <div class="column has-text-centered">
                <b>Información del solicitante (SIAC)</b><br />
                {{ student.nombres }} {{ student.paterno }}
                {{ student.materno }} (RUT: {{ studentRut }})<br />
                <div v-if="carreers.length > 0">
                  <h4 class="mb-0 pb-0">
                    <ul>
                      <li v-for="(carrera, index) in carreers" :key="index">
                        [{{ carrera.codigo_carrera }}]
                        {{ carrera.alt_nombre_carrera }}
                        {{ carrera.version_plan }}-{{ carrera.plan }} -
                        {{ carrera.tipo }}, {{ carrera.jornada }}
                        <b-icon
                          v-if="carrera.jornada === 'Diurno'"
                          class="mr-1"
                          icon="sun"
                        />
                        <b-icon
                          v-else-if="carrera.jornada === 'Vespertino'"
                          class="mr-1"
                          icon="moon"
                        />

                        Ingreso: {{ carrera.semestre_ingreso }}-{{
                          carrera.agno_ingreso
                        }}
                      </li>
                    </ul>
                  </h4>
                </div>
                <div v-else>Sin carreras en SIAC</div>
              </div>
            </div>

            <div class="columns is-multiline">
              <!--                          Campos formulario-->
              <div
                v-for="(element, index) in responseData"
                :key="index"
                class="field column is-half-tablet is-half-desktop"
              >
                <label class="label">{{ element.item_title }}</label>
                <div class="control">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input
                      v-if="element.item_type === 'DATE'"
                      class="input"
                      :value="formatDate(element.item_response)"
                      type="text"
                      disabled
                      placeholder="Ingrese un nombre"
                    />

                    <input
                      v-else
                      class="input"
                      v-model="element.item_response"
                      type="text"
                      disabled
                      placeholder="Ingrese un nombre"
                    />
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column is-12" v-if="requestDescription && !isLoading">
                <b-field label="Justificación de la solicitud aportados por solicitante">
                  <b-input
                      v-model="requestDescription"
                      disabled
                      type="textarea"
                      placeholder="Esta solicitud no tiene justificación."
                  />
                </b-field>
              </div>
            </div>
            <div v-if="show_sol_planes_programas && is_reviewer">
              <br />
              <h1 class="title has-text-centered">
                Planes y programas del estudiante en GDRC
              </h1>

              <div
                class="columns is-gapless is-multiline is-mobile"
                v-for="(carrera, index) in carreers"
                :key="index"
              >
                <div class="column is-three-fifths is-offset-one-fifth">
                  <b-button
                    v-if="checkNameCareer(carrera)"
                    class="rounded mx-3"
                    expanded
                    type="is-gdrc-color"
                    @click="goToGdrc(carrera)"
                  >
                    <div class="columns is-vcentered">
                      <i class="fas fa-sign-in-alt mr-3"></i>
                      [{{ carrera.codigo_carrera }}]
                      {{ carrera.alt_nombre_carrera }} -
                      {{ careerNameExtra(carrera) }}
                      <b-icon
                        v-if="carrera.jornada === 'Diurno'"
                        icon="sun"
                        class="is-align-content-flex-end mr-1"
                      ></b-icon>
                      <b-icon
                        v-else-if="carrera.jornada === 'Vespertino'"
                        icon="moon"
                        class="is-align-content-flex-end mr-1"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="question"
                        class="is-align-content-flex-end mr-1"
                      ></b-icon>
                      Ingreso {{ carrera.semestre_ingreso }}-{{
                        carrera.agno_ingreso
                      }}
                    </div>
                  </b-button>

                  <b-button
                    v-else
                    class="rounded mx-3"
                    expanded
                    type="is-black-color"
                    @click="goToGdrc(carrera)"
                  >
                    <div class="columns is-vcentered">
                      <i class="fas fa-sign-in-alt mr-3"></i>
                      [{{ carrera.codigo_carrera }}]
                      {{ carrera.alt_nombre_carrera }} -
                      {{ careerNameExtra(carrera) }}
                      <b-icon
                        v-if="carrera.jornada === 'Diurno'"
                        icon="sun"
                        class="is-align-content-flex-end mr-1"
                      ></b-icon>
                      <b-icon
                        v-else-if="carrera.jornada === 'Vespertino'"
                        icon="moon"
                        class="is-align-content-flex-end mr-1"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="question"
                        class="is-align-content-flex-end mr-1"
                      ></b-icon>
                      Ingreso {{ carrera.semestre_ingreso }}-{{
                        carrera.agno_ingreso
                      }}
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer class="modal-card-foot actions has-background-secondary">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">
              Cerrar
            </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import revisionHistory from "../Requests/RevisionHistory.vue";

export default {
  name: "ModalShowFormResponse",
  computed: {
    revisionHistory() {
      return revisionHistory;
    },
  },
  props: [
    "modalHeader",
    "google_form_id",
    "google_form_response_id",
    "is_reviewer",
    "carreers",
    "student",
    "studentRut",
    "requestDescription",
  ],
  components: {},
  data: function () {
    return {
      responseData: [],
      //---------------------------------
      validationErrors: {},
      isLoading: false,
      form_link: "",
      show_sol_planes_programas: false,
    };
  },

  created() {
    this.isLoading = true;

    axios
      .get(
        "/google_form_responses/get_response/" +
          this.google_form_id +
          "/" +
          this.google_form_response_id +
          ".json"
      )
      .then((response) => {
        this.responseData = response.data;
        this.show_sol_planes_programas = this.responseData.some(
          (element) =>
            element.item_title === "Tipo formulario" &&
            element.item_response === "Solicitud de planes y programas"
        );
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(
          "No se pudieron cargar los formularios disponibles para responder."
        );
        this.isLoading = false;
      });
  },

  methods: {
    formatDate(date) {
      if (date != null && date !== "" && date !== undefined) {
        var arreglo = date.split("-");
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
      } else {
        return "";
      }
    },
    goToGdrc(carrera) {
      let run = this.studentRut.split("-")[0];
      window.open(
        "https://gdrc.fing.usach.cl/programs/search-student/" +
          run +
          "/" +
          carrera.codigo_carrera +
          "/" +
          carrera.agno_ingreso +
          "/" +
          carrera.semestre_ingreso,
        "_blank"
      );
    },
    checkNameCareer(carrera) {
      return this.responseData.some(
        (element) =>
          element.item_title === "Selecciona tu carrera" &&
          element.item_response.includes(carrera.alt_nombre_carrera) &&
          element.item_response.includes(carrera.agno_ingreso)
      );
    },
    careerNameExtra(career) {
      let jornada = career.jornada;
      if (career.jornada === "") jornada = "Jornada indefinida";
      return `${this.capitalizerText(career.tipo)}, ${this.capitalizerText(
        jornada
      )} `;
    },
    capitalizerText(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
  },
};
</script>

<style scoped>
.is-black-color {
  background-color: #fff;
  color: black;
  border: 1px solid #000 !important;
}

.is-gdrc-color {
  background-color: #124270;
  color: white;
}
</style>
