<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(goToRequestType)">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <b-field label="Búsqueda de Solicitudes">
          <b-autocomplete
            class="is-fullwidth"
            :loading="isLoading"
            v-model="search"
            @focus="fetchRequestTypes"
            :data="filteredRequestTypes"
            field="real_name"
            icon="search"
            keep-first
            @select="selected = $event"
            dropdown-position="top"
            expanded
            open-on-focus
          >
            <template v-slot="props">
              <div style="white-space: normal" class="is-size-6">
                <b-tag
                  v-if="props.option.origin"
                  size="is-small is-secondary is-light"
                  :type="formatOriginColor(props.option.origin)"
                  class="is-size-6"
                >
                  <b>{{ formatOriginName(props.option.origin) }}</b>
                </b-tag>
                {{ props.option.real_name }}
                <b-tag
                  v-if="props.option.role_type"
                  size="is-small"
                  type="is-secondary is-light"
                  class="is-size-6"
                >
                  <b>{{ props.option.role_type }}</b>
                </b-tag>
                <b-tag
                  v-if="props.option.role_state"
                  size="is-small"
                  type="is-primary is-light"
                  class="is-size-6"
                >
                  <b>{{ props.option.role_state }}</b>
                </b-tag>
              </div>
            </template>
            <template #empty>
              <div v-if="isLoading">Cargando solicitudes...</div>
              <div v-else>No se encontraron resultados</div>
            </template>
          </b-autocomplete>
          <b-button
            class="is-pulled-right ml-2"
            type="is-primary"
            icon-left="external-link-alt"
            native-type="submit"
            :disabled="!selected"
            >Ir a la Solicitud
          </b-button>
        </b-field>
        <span class="validation-alert">{{ errors[0] }}</span>
      </ValidationProvider>
    </form>
  </ValidationObserver>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      requestTypes: [],
      search: "",
      selected: null,
      isLoading: false,
    };
  },
  computed: {
    filteredRequestTypes() {
      if (this.requestTypes.length === 0) return [];
      return this.requestTypes.filter((requestType) =>
        requestType.name.includes(
          this.search
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        )
      );
    },
  },
  methods: {
    fetchRequestTypes() {
      let vm = this;
      if (vm.requestTypes.length > 0) return;
      vm.isLoading = true;
      axios
        .get("/request_types/enabled.json")
        .then((response) => {
          vm.requestTypes = response.data.request_types;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          vm.isLoading = false;
        });
    },
    goToRequestType() {
      window.open(`/requests/new/${this.selected.id}`, "_blank");
    },
    formatOriginName(origin) {
      if (origin === "GCC") return "Registro Curricular";
      if (origin === "GSD") return "Docencia";
      return "Sin Información";
    },
    formatOriginColor(origin) {
      if (origin === "GCC") return "is-gcc";
      if (origin === "GSD") return "is-gsd";
      return "is-primary";
    },
    formatOriginBorder(origin) {
      if (origin === "GCC") return "is-border-gcc";
      if (origin === "GSD") return "is-gsd";
      return "is-primary";
    },
  },
};
</script>
