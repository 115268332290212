<template>
<div class="container is-fullheight" style="padding: 2rem;background-color: #F2F2F2;">
    <div class="section" style="background-color: white; height: 100%" ref="createRequestType">

        <!-- Titulo de la sección -->
        <div class="is-flex is-justify-content-flex-start">
            <h1 class="subtitle is-1 has-text-secondary">
                Estadísticas
            </h1>
        </div>

        <!-- Separador -->
        <hr class="solid">

                <!-- Filters Section -->
        <div class="columns is-multiline my-2 px-5 ">

            <!-- Unit filter -->
            <div class="column is-full-mobile is-full-tablet is-one-third-desktop">
                <b-field label="Filtrar por Unidad:">
                    <multiselect v-model="selectedUnitOption" :options="units" :custom-label="opt => units.find(x => x.id === opt.id).name" :multiple="false" placeholder="Seleccione una unidad" selectLabel="Presione para seleccionar" selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" track-by="id" label="name">
                        <template v-slot:noOptions>
                            No existen datos
                        </template>
                        <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                    </multiselect>
                </b-field>
            </div>

            <!-- Request Type filter -->
            <!--
            <div class="column is-full-mobile is-full-tablet is-one-third-desktop">
                <b-field label="Filtrar por Rol:">
                    <multiselect v-model="selectedInternalPosition" :options="internalPositions" :custom-label="opt => internalPositions.find(x => x.id === opt.id).name" :multiple="false" placeholder="Seleccione un rol" selectLabel="Presione para seleccionar" selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" track-by="id" label="name">
                        <template v-slot:noOptions>
                            No existen datos
                        </template>
                        <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                    </multiselect>
                </b-field>

            </div>
            -->

            <!-- StartDate filter -->
            <div class="column is-full-mobile is-full-tablet is-one-third-desktop">
                <b-field label="Filtrar por Fecha (Desde):">
                    <b-datepicker placeholder="Selecciona una Fecha" icon="calendar-alt" trap-focus v-model="selectedStartDate" :icon-right="selectedStartDate ? 'close-circle' : ''" icon-right-clickable @icon-right-click="clearStartDate" :date-formatter="(date) => date.toLocaleDateString('es-ES')" locale="es">
                    </b-datepicker>
                </b-field>
            </div>

            <!-- EndDate filter -->
            <div class="column is-full-mobile is-full-tablet is-one-third-desktop">
                <b-field label="Filtrar por Fecha (Hasta):">
                    <b-datepicker placeholder="Selecciona una Fecha" icon="calendar-alt" icon-pack="fas" trap-focus v-model="selectedEndDate" :icon-right="selectedEndDate ? 'close-circle' : ''" icon-right-clickable @icon-right-click="clearEndDate" :date-formatter="(date) => date.toLocaleDateString('es-ES')" locale="es">
                    </b-datepicker>
                </b-field>
            </div>

            <!-- Button to Apply filters -->
            <div class="column is-flex is-align-items-end is-justify-content-end">
                <b-button type="is-secondary" v-on:click="applyFilters">Aplicar Filtros</b-button>
            </div>

        </div>
        <!-- End Filters Section -->

        <!-- Table Section -->
        <b-table :data="data_stats == null ? [] : data_stats" :paginated="true" :pagination-simple=false pagination-position="bottom" :current-page="currentPageTable" per-page="15" :bordered="false" :striped="true" :narrowed="false" :hoverable="true" :loading="isLoading == null ? true : false" :focusable="false" :mobile-cards="true">
            <!-- Column Name -->
            <b-table-column field="name" :visible="true" label="Nombre" width="250" sortable searchable>
                <template slot="searchable" slot-scope="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por nombre" icon="search" size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row.name }}
                </template>
            </b-table-column>

            <!-- Column number of requests -->
            <b-table-column field="number_of_requests" :visible="true" centered width="550" label="N° de solicitudes creadas">
                <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ props.row.number_of_requests }}
                    </span>
                </template>

            </b-table-column>

            <!-- Column number of requests finalizadas-->
            <b-table-column field="requests_in_course" :visible="true" centered width="550" label="N° de solicitudes en curso">
                <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ props.row.requests_in_course }}
                    </span>
                </template>

            </b-table-column>

            <!-- Column number of requests finalizadas-->
            <b-table-column field="requests_finished" :visible="true" centered width="550" label="N° de solicitudes finalizadas">
                <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ props.row.requests_finished }}
                    </span>
                </template>

            </b-table-column>




            <!-- Column number of requests detenidas-->
            <b-table-column field="requests_stopped" :visible="true" width="550" label="N° de solicitudes Detenidas">
                <template v-slot="props">
                    <span class="is-flex is-justify-content-center">
                        {{ props.row.requests_stopped }}
                    </span>
                </template>

            </b-table-column>

            
            <template #empty>
                <div class="has-text-centered">No se encontró ninguna estadística con los filtros ingresados.</div>
            </template>
        </b-table>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</div>
</template>
</template>

<script>
import axios from "axios"
export default {
    name: "Statistics",
    components: {},
    props: [],
    data: function () {
        return {
            data_stats: [],
            currentPageTable: 1,
            states: ["", "Depto. Académico", "Secretaria FING", "Secretaria Académica", "Decano"],
            selectedStateOption: null,
            units: ["", "Depto. Informatica", "Facultad de Ingeniería"],
            selectedUnitOption: null,
            internalPositions: ["", "Nombramiento"],
            selectedInternalPosition: null,
            selectedStartDate: null,
            selectedEndDate: null,
            isLoading: false,
            requests: null
        }
    },
    methods: {
        async getFilters() {
            await axios.get('/units.json')
                .then(response => {
                    this.units = response.data;
                })
                .catch(error => {
                    console.log(error);
                    this.units = [];
                });

            await axios.get('/internal_positions.json')
                .then(response => {
                    this.internalPositions = response.data.filter(x => {return x.name != "SUPERADMIN"});;
                })
                .catch(error => {
                    console.log(error);
                    this.internalPositions = [];
                })
        },

        applyFilters() {
            this.isLoading = true;
            let params = {
                unit: this.selectedUnitOption == null ? 'empty' : this.selectedUnitOption.name,
                internal_position: this.selectedInternalPosition == null ? 'empty' : this.selectedInternalPosition.name,
                start_date: this.selectedStartDate == null ? 'empty' : this.selectedStartDate.getFullYear() + "-" + (this.selectedStartDate.getMonth() + 1) + "-" + this.selectedStartDate.getDate(),
                end_date: this.selectedEndDate == null ? 'empty' : this.selectedEndDate.getFullYear() + "-" + (this.selectedEndDate.getMonth() + 1) + "-" + this.selectedEndDate.getDate()
            }

            axios.get('statistics/apply_filters.json', {
                    params
                })
                .then(response => {
                    //console.log(response)
                    this.data_stats = response.data.data_statistics;
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error(error);
                    this.isLoading = false;
                })
        },
        clearStartDate() {
            this.selectedStartDate = null;
        },
        clearEndDate() {
            this.selectedEndDate = null;
        },
    },
    created() {
      document.title="Estadísticas por tipo de solicitud"
        this.isLoading = true;
        this.getFilters();
        axios
            .get("/request_types/statistics.json")
            .then(response => {
                this.data_stats = response.data.data_statistics;
                this.isLoading = false;
            })
            .catch(e => {
                console.log(e);
                this.isLoading = false;
            });

    },
}
</script>
