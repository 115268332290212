<template>
  <b-table
      :data="internalNotes"
      :paginated="isPaginated"
      :pagination-rounded = 'true'
      pagination-position='bottom'
      :pagination-simple ='false'
      :bordered="false"
      :striped="true"
      :per-page="perPage"
      :detailed="true"
      detail-key="id"
      :show-detail-icon="true"
      detail-icon="arrow-circle-right"

  >
    <b-table-column field="code" width="6%" label="Código" searchable :custom-search="codeParcialSearch">

      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            placeholder=" Buscar"
            icon="search"
            size="is-small"/>
      </template>
      <template v-slot="props">

        {{ props.row.code}}
      </template>
    </b-table-column>
    <b-table-column field="reviewer.full_name" width="15%" label="Persona que observa" :custom-search="codeParcialSearch">
      <template v-slot="props">
        {{ props.row.reviewer.full_name }}
      </template>
    </b-table-column>

    <b-table-column field="observation"   label="Observación" >
      <template v-slot="props">
        {{ props.row.observation }}
      </template>
    </b-table-column>
    <b-table-column field="original_observationparent_code"   label="Responde a"   searchable :custom-search="parentCodeParcialSearch">

      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            placeholder=" Buscar por código"
            icon="search"
            size="is-small"/>
      </template>
      <template v-slot="props">
        <div v-if="props.row.original_observation.parent_code">
          Cód: {{props.row.original_observation.parent_code}}
        </div>
        <div v-else>
          -
        </div>
      </template>


    </b-table-column>
    <b-table-column label="Acciones" v-slot="props" width="13%">
      <b-button type="is-success " v-if="canCreate" icon-left="reply" @click="answer(props.row.id)"></b-button>
      <button class="button is-primary" v-if="props.row.documents.length>0" @click="view(props.row.id)">
        <span class="icon" style="padding-bottom: 1px">
          <span class="fa-stack fa-sm">
            <i class="fas fa-file fa-stack-2x"></i>
            <i class="fas fa-eye fa-stack-1x has-text-grey-dark"></i>
          </span>
        </span>
      </button>
    </b-table-column>
    <template #empty>
      <div class="has-text-centered">Esta solicitud aún no posee observaciones internas</div>
    </template>
    <template #detail="props">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <p>
              <b>Paso activo al momento de realizar la observación:</b> {{ props.row.state.number }}/{{props.row.state.total_steps}}
              <br />
              <b>Fecha de la observación:</b> {{ dateToStringWithHour(props.row.created_at) }}
            </p>
          </div>
        </div>
      </article>
    </template>
    <template #bottom-left>
      <b-select v-model="perPage" :disabled="!isPaginated" class="pb-1">
        <option value="3">3 por página</option>
        <option value="5">5 por página</option>
        <option value="10">10 por página</option>
        <option value="15">15 por página</option>
        <option value="20">20 por página</option>
      </b-select>
    </template>
  </b-table>
</template>
<script >

import {dateToStringWithHour, parcialSearch} from "../../../packs/utilities";
import {defineComponent} from "vue";

export default {
  name: "InternalObservationTable",
  props: ["internalNotes","isPaginated", "canCreate"],
  data: function () {
    return {
      perPage:3
    };
  },
  methods: {
    dateToStringWithHour,
    view(id){
      this.$emit('view',id);
    },

    answer(id){
      this.$emit('answer',id);
    },
    codeParcialSearch(row, input) {
      return parcialSearch(row.code.toString(),input)
    },
    parentCodeParcialSearch(row, input) {
      return parcialSearch(row.original_observation.parent_code,input)
    }
  }
}

</script>

<style>

.pagination-list {
  margin-top: 0 !important;
}

</style>
