<template>
  <section>
    <div class="modal-card custom-visibility" style="width: auto">
      <header class="modal-card-head custom-visibility">
        <p class="modal-card-title">Crear usuario</p>
        <b-button
          native-type="button"
          class="delete"
          @click="close"
          style="background: #ff3860"
        />
      </header>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(createUser)">
          <div class="modal-card-body custom-visibility">
            <div class="columns is-multiline">
              <b-field class="column is-one-third" label="Nombres*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="user.name"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field class="column is-one-third" label="Apellido*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="user.last_name"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field class="column is-one-third" label="Segundo apellido">
                <b-input v-model="user.sur_name"></b-input>
              </b-field>
              <b-field class="column is-one-third" label="Correo corporativo*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input type="email" v-model="user.email"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field class="column is-one-third" label="Rut">
                <ValidationProvider
                  :rules="{
                    required: run.dv !== null && run.dv !== '',
                    regex: '^([0-9]+)$',
                  }"
                  v-slot="{ errors }"
                >
                  <b-input v-model="run.rut"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field
                class="column is-one-third"
                label="Dígito verificador (dv)"
              >
                <ValidationProvider
                  :rules="{
                    required: run.rut !== null && run.rut !== '',
                    regex: /^[0-9kK]$/,
                  }"
                  v-slot="{ errors }"
                >
                  <b-input v-model="run.dv"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field class="column is-half" label="Rol*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <multiselect
                    class="custom-multiselect"
                    v-model="selectedInternalPosition"
                    :options="internalPositions"
                    :custom-label="
                      (opt) =>
                        internalPositions.find((x) => x.id === opt.id) != null
                          ? internalPositions.find((x) => x.id === opt.id).name
                          : null
                    "
                    :multiple="false"
                    placeholder="Seleccione un rol"
                    selectLabel="Presione para seleccionar"
                    selectedLabel="Seleccionado"
                    deselectLabel="Presione para deseleccionar"
                    track-by="id"
                    label="name"
                    aria-expanded="true"
                  >
                    <template v-slot:noOptions> No existen datos </template>
                    <span slot="noResult"> No se encontraron elementos. </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field class="column is-half" label="Descripción*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input
                    v-model="description"
                    placeholder="Ingrese el motivo o descripción del rol"
                  >
                  </b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>

              <b-field class="column is-half" label="Inicio del rol*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-datepicker
                    v-model="start_date"
                    icon="calendar-alt"
                    placeholder="Formato de Fecha: dd/mm/aaaa"
                    position="is-top-right"
                    :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                    locale="es"
                  >
                  </b-datepicker>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field class="column is-half" label="Fin del rol">
                <b-datepicker
                  v-model="end_date"
                  trap-focus
                  icon="calendar-alt"
                  placeholder="Formato de Fecha: dd/mm/aaaa"
                  position="is-top-right"
                  :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                  locale="es"
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <footer class="modal-card-foot custom-visibility actions">
            <b-button type="is-danger" @click="close">Cancelar</b-button>
            <b-button native-type="submit" type="is-success"
              >Crear usuario</b-button
            >
          </footer>
        </form>
      </ValidationObserver>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ModalCreateUser",
  data() {
    return {
      isLoading: false,
      internalPositions: [],
      selectedInternalPosition: null,
      description: "",
      start_date: null,
      end_date: null,
      user: {
        email: "",
        name: "",
        last_name: "",
        sur_name: "",
      },
      run: {
        rut: "",
        dv: "",
      },
    };
  },
  async created() {
    await this.getInternalPositions();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    createUser() {
      this.isLoading = true;
      let internal_position = {
        internal_position_id: this.selectedInternalPosition.id,
        start_date: this.start_date,
        end_date: this.end_date,
        description: this.description,
      };
      this.formData = {
        user: JSON.stringify(this.user),
        run: JSON.stringify(this.run),
        internal_position: JSON.stringify(internal_position),
      };
      axios
        .post("/users/add-user.json", this.formData)
        .then((response) => {
          this.text =
            "El usuario con correo " +
            this.user.email +
            " fue creado exitosamente";

          this.$buefy.dialog.confirm({
            title: "Listo",
            message: this.text,
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            canCancel: false,
            onConfirm: () => {
              this.close();
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.validationErrors = error.response.data;
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: "Error",
            message:
              "Hubo los siguientes errores al crear intentar crear el usuario: " +
              this.validationErrors.join(", "),
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getInternalPositions() {
      return axios
        .get("/internal_positions.json")
        .then((response) => {
          this.internalPositions = response.data;
        })
        .catch((error) => {
          //console.log(error);
          this.internalPositions = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.custom-visibility {
  overflow: visible !important;
}
</style>
