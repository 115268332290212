
<template>
  <!-- header -->
  <div style="background-color: white"  >
    <img src="../../../../public/gsd/home_student_images/headers/preguntas_frecuentes.png" class="responsive">
    <!-- body -->
    <div class="columns is-centered">
      <div class="column is-9" >

      <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of collapses"
            :key="index"
            :open="isOpen(index)"
            @open="open(index)"
            @close=" close(index)"
            :aria-id="'contentIdForA11y5-' + index">
          <template #trigger="props">
            <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open">
              <p class="card-header-title is-flex is-justify-content-left subtitle is-size-3 has-text-secondary has-text-weight-bold is-family-secondary ">
                {{collapse.titulo}}
              </p>
              <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'chevron-down' : 'chevron-up'">
                </b-icon>
              </a>
            </div>
          </template>

          <div class="card-content" v-for="(cuerpo,index) of collapse.cuerpo" :key="index">

            <div  class=" has-text-secondary is-family-secondary">
              <p class="is-size-5-desktop is-size-6 has-text-weight-bold">
                {{cuerpo.subtitulo}}
               </p> <br/>
              <p class="is-size-6-desktop is-size-8"><div v-html="cuerpo.texto"></div></p>
            </div>
        </div>

        </b-collapse>
      </div>
    </div>
    <!-- Footer -->

    <div style="background-color: #003B71" align="center">
      <img src="../../../../public/logo_sin_texto.png"/>
      <div >
        <a href="https://www.fing.usach.cl/" target="_blank">
          <h1 class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold">
            WEB OFICIAL FING USACH | Entra aquí
          </h1>
        </a>
        <br/>
      </div>

    </div>
    <div style="background-color: #FAC633" align="center">
      <br/>
      <h1 class="is-size-6 has-text-black has-text-centered is-family-secondary ">
        Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins nº 3363. Estación Central. Santiago. Chile.
      </h1>
      <a href="https://www.usach.cl/contacto" target="_blank">
        <h1 class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold">
          Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
        </h1>
      </a>
      <br/>

    </div>
  </div>

</template>
<script>

export default {
  name: "GsdFaq",
  components: {
  },
methods:{
    isOpen(index){
      let position = this.tabOpens.indexOf(index);
      if (position>-1){
        return true;
      }
      return false;
    },
    open(index){
      this.tabOpens.push(index)
    },
  close(index){
    const position = this.tabOpens.indexOf(index);
    if (position > -1) { // only splice array when item is found
      this.tabOpens.splice(position, 1); // 2nd parameter means remove one item only
    }

  }
},
  data() {
    return {
      tabOpens: [],
      collapses: [
        {
          "titulo": "1. CONSULTAS GENERALES",
          "cuerpo": [
            {
              "subtitulo": "1.1 ¿DÓNDE PUEDO SOLICITAR EL CERTIFICADO DE ALUMNO REGULAR?",
              "texto": "Esto se puede hacer a través de la Plataforma <a class='is-underlined' style='text-decoration-line: none;' href='https://loa.usach.cl/intranetfing/' target='_blank'>LOA</a>, donde se puede descargar e imprimir. Para lograrlo, es necesario que se tenga activada la cuenta LOA, trámite que se realiza en la Oficina de Atención al Público de Registro Curricular de la Facultad de Ingeniería (en el cual se debe presentar la cédula de identidad)." +
                  "<br/><br/>Por otra parte, se puede obtener solicitándolo al correo <u>registrocurricular.fing@usach.cl</u><br/>"
            },
            {
              "subtitulo": "1.2 ¿DÓNDE SE PUEDE SOLICITAR EL COMPROBANTE DE MATRÍCULA?",
              "texto": "Se debe ingresar a <a class='is-underlined' style='text-decoration-line: none;' href='https://pagosyserviciosenlinea.usach.cl' target='_blank'>https://pagosyserviciosenlinea.usach.cl</a> y entrar con el usuario y contraseña correspondientes al correo USACH. Luego se debe ingresar a la sección de impresión de matrícula y seleccionar el semestre activo de la matrícula para guardarla.<br/>"
            },
            {
              "subtitulo": "1.3 ¿QUÉ ES EL NIVEL ACADÉMICO Y PARA QUÉ SIRVE?",
              "texto": "Se asocia a la asignatura más baja que se esté cursando por semestre, el nivel al que pertenezca esa asignatura, es el nivel académico de el o la estudiante.<br/><br/><br/>Para saber cuál es el nivel de los y las estudiantes, se debe ingresar a la malla curricular en el sistema LOA. La asignatura inscrita de menor nivel determinará el nivel actual de el o la estudiante. Por ejemplo, si se llegase a reprobar una asignatura de nivel 1, y actualmente tiene solo asignaturas de nivel 2 más la asignatura de nivel 1, el nivel correspondiente es 1. En otro caso, si se tiene inscrito asignaturas del nivel 4, 5 y 6 dentro del horario, siendo las de nivel 4, las asignaturas de nivel más bajo, entonces, el nivel es 4.<br/><br/><br/>Esto sirve para la inscripción de asignaturas, puesto que se anuncia los días que cada nivel puede inscribir asignaturas por el sistema LOA.<br/><br/>"
            },
            {
              "subtitulo": "1.4 ¿DÓNDE PUEDO INFORMARME SOBRE LAS FECHAS DE INICIO DE CLASES?",
              "texto": "En el calendario de actividades académicas. Este se puede descargar desde el LOA en la sección 'Documentos', donde también se encuentran las fechas importantes, tales como fechas de inicio y término de clases, inscripción de asignaturas, eliminación de asignaturas y otros.<br/><br/><br/>Otra forma, se puede encontrar visitando la página de la Vicerrectoría Académica, en la sección de “Calendario Académico” <a class='is-underlined' style='text-decoration-line: none;' href='https://vra.usach.cl/vicerrectoria-academica' target='_blank'>https://vra.usach.cl/vicerrectoria-academica</a><br/>"
            },
            {
              "subtitulo": "1.5 ¿CÓMO PUEDO REALIZAR EL PAGO DE MENSUALIDADES/ARANCEL Y DE LA MATRÍCULA?",
              "texto": "Existen dos formas de cancelar mensualidades y matrícula. La primera es pagos y servicios en línea, en la cual debes ingresar a <a class='is-underlined' style='text-decoration-line: none;' href='https://pagosyserviciosenlinea.usach.cl.' target='_blank'>https://pagosyserviciosenlinea.usach.cl</a>.<br/><br/><br/>Para poder realizar los pagos, se debe ingresar a la pestaña denominada <b>pago web</b> y seguir los pasos dentro del portal para efectuar el pago. La segunda opción es a través del Departamento de finanzas dentro del campus universitario, el cual se puede cancelar de forma presencial. Esta opción se podrá realizar una vez se retomen las clases de manera presencial.<br/><br/>"
            },
            {
              "subtitulo": "1.6 ¿QUÉ DEBO HACER LUEGO DE REPROBAR UNA ASIGNATURA?",
              "texto": "Si se reprueba alguna asignatura, se debe inscribir nuevamente el siguiente semestre por medio del sistema <a class='is-underlined' style='text-decoration-line: none;' href='https://loa.usach.cl/intranetfing/' target='_blank'>LOA</a>. Si es la segunda vez que se reprueba la asignatura, se deberá enviar una solicitud de Reincorporación. Esto se encuentra regido bajo el Reglamento General de Régimen de Estudios de Pregrado, ubicado en el <a class='is-underlined' style='text-decoration-line: none;' href='https://loa.usach.cl/intranetfing/' target='_blank'>LOA</a>, en la sección “Documentos”.<br/><br/>"
            },
            {
              "subtitulo": "1.7 ¿QUÉ HACER SI NO SE ASISTE A UNA EVALUACIÓN POR PROBLEMAS DE SALUD?",
              "texto": "Lo primero que se debe hacer, es enviar una solicitud de Justificación de Inasistencia (Que se puede realizar en este mismo sitio web), adjuntando el certificado médico que avale la falta a la evaluación. Posteriormente, cuando esta solicitud sea cursada, se deberá visar el certificado en el Centro Médico. Esto se encuentra regido bajo el Reglamento General de Régimen de Estudios de Pregrado, ubicado en el <a class='is-underlined' style='text-decoration-line: none;' href='https://loa.usach.cl/intranetfing/' target='_blank'>LOA</a>, en la sección “Documentos”.<br/><br/>"
            },
            {
              "subtitulo": "1.8 ¿CUÁL ES LA FORMA CORRECTA DE REDACTAR UN MAIL?",
              "texto": "Lo primero que debe hacer, es verificar si el correo que está enviando, corresponde para atender su problema y/o solicitud, esto lo puede verificar en esta misma página en la parte de “Contactos”.<br/><br/><br/>Luego, salude, escriba su nombre y apellido, carrera a la que pertenece y RUT. Finalmente, exponga su problema y/o requerimiento de la manera más explícita posible, para que la persona que lo lea, tenga claridad de lo que solicita y pueda atenderle de forma más expedita.<br/>"
            }
          ]
        },
        {
          "titulo": "2. CONSULTAS DE ESTUDIANTES NUEVOS ",
          "cuerpo": [
            {
              "subtitulo": "2.1 ¿CÓMO PUEDO INGRESAR A MI CORREO USACH POR PRIMERA VEZ?",
              "texto": "Se debe ingresar a <a class='is-underlined' style='text-decoration-line: none;' href='https://sso.usach.cl/' target='_blank'>sso.usach.cl</a> y escribir los datos de mail y contraseña disponibles al momento de estar matriculado. Se debe considerar, que la activación de este correo se demora 48 horas aproximadamente luego de efectuar la primera matrícula.<br/><br/><br/>Tutorial para activar correo USACH: <a class='is-underlined' style='text-decoration-line: none;' href='https://youtu.be/Trde0-c5cuk' target='_blank'>https://youtu.be/Trde0-c5cuk</a><br/><br/><br/>Si hay algún problema con el correo USACH, contactarse con:<u>soporte@segic.usach.cl</u><br/><br/><br/>Para mayor información ingresar a <a class='is-underlined' style='text-decoration-line: none;' href='https://www.usach.cl/correo-estudiantes' target='_blank'>https://www.usach.cl/correo-estudiantes</a>.<br/><br/><br/>"
            },
            {
              "subtitulo": "2.2 ¿CÓMO PUEDO INGRESAR AL SISTEMA LOA POR PRIMERA VEZ?",
              "texto": "Ingresa a <a class='is-underlined' style='text-decoration-line: none;' href='https://loa.usach.cl/intranetfing/' target='_blank'>https://loa.usach.cl/intranetfing/</a>.<br/>El usuario corresponde al RUT sin puntos ni guión, y la clave está compuesta por los 4 primeros dígitos del RUT, seguido de las 2 primera letras del apellido paterno (minúscula) y las 2 primeras letras del nombre (minúsculas)<br/><br/><br/>Por ejemplo: si el RUT es 12.345.678-9 y el nombre Andrés Orellana, el usuario será: 12345678 y la clave 1234oran. Una vez ingresado al portal, se podrá cambiar la clave por una a gusto.<br/><br/>"
            },
            {
              "subtitulo": "2.3 ¿DÓNDE PUEDO CONSULTAR LA UBICACIÓN FÍSICA DE LAS SALAS DE CLASE Y OFICINAS DENTRO DEL CAMPUS UNIVERSITARIO?",
              "texto": "Para consultar la ubicación física de las salas de clases, puede visitar el siguiente link: <a class='is-underlined' style='text-decoration-line: none;' href='https://registro.usach.cl/index.php?ct=map' target='_blank'>https://registro.usach.cl/index.php?ct=map</a><br/><br/><br/>Por otra parte, es posible descargar la app USACH Móvil para sistemas Android pinchando aquí <a class='is-underlined' style='text-decoration-line: none;' href='https://play.google.com/store/apps/details?id=cl.cglusach.umovil&hl=es_CL&gl=US' target='_blank'>link</a>.<br/><br/>"
            },
            {
              "subtitulo": "2.4 ¿CÓMO PUEDO OBTENER MI PASE ESCOLAR?",
              "texto": "El pase escolar o Tarjeta Nacional Estudiantil (TNE) es un beneficio administrado por JUNAEB que acredita la calidad de estudiante regular de enseñanza Básica, Media o Superior y que hace efectiva la gratuidad o rebaja en el pago de la tarifa de los servicios de locomoción colectiva. La TNE se solicita a la sección Asignación de Créditos Universitarios y Tarjeta Nacional Estudiantil dependiente de la Dirección de Apoyo al Estudiante (DAE). El pase se paga junto con la matrícula cada semestre.<br/> Para más información sobre la TNE ingresa a <a class='is-underlined' style='text-decoration-line: none;' href='https://www.tne.cl/' target='_blank'>https://www.tne.cl/</a>. <br/>"
            }
          ]
        },
        {
          "titulo": "3. LOA",
          "cuerpo": [
            {
              "subtitulo": "3.1 ¿QUÉ ES EL LOA?",
              "texto": "Es una aplicación computacional diseñada para estudiantes, docentes y administrativos de la Facultad de Ingeniería, que permite disponer de información para las actividades docentes tales como inscripción de asignaturas, impresión de certificados de alumno y alumna regular, consultas sobre salas y horarios de clases, consultas de calificaciones, entre otros.<br/>"
            },
            {
              "subtitulo": "3.2 APAREZCO EN LOA COMO USUARIO BLOQUEADO",
              "texto": "Esto ocurre por ingreso de clave errónea o usuario erróneo cada cierto número de intentos. Cada una hora se desbloquean los usuarios.<br/><br/><br/>Para mayor información, recurrir al correo: <u>loa.fing@usach.cl</u>.<br/><br/>"
            },
            {
              "subtitulo": "3.3 ¿QUÉ DOCUMENTOS SE PUEDEN OBTENER DESDE LA PLATAFORMA LOA?",
              "texto": "En la sección <b>'Documentos'</b> dentro del LOA, se pueden adquirir documentos, tales como el calendario de actividades académicas, la Guía del Apoyo al Estudiante, los reglamentos de la Universidad y libros de asignaturas de plan común (Cálculo, Álgebra, Física). Por otra parte, se puede obtener el certificado de alumno regular de el o la estudiante.<br/><br/>"
            },
            {
              "subtitulo": "3.4 ¿CÓMO SE REALIZA LA INSCRIPCIÓN DE ASIGNATURAS?",
              "texto": "Para los y las estudiantes nuevos, la inscripción de asignaturas es automática, desde el segundo semestre en adelante, la inscripción de asignaturas se lleva a cabo mediante períodos de inscripción dependiendo del nivel al que pertenezcan. Las asignaturas se deben inscribir vía LOA dentro de la fecha que se informa previamente (calendario de actividades académicas), primero se debe inscribir las asignaturas de menor nivel, y luego se puede seguir inscribiendo las asignaturas de niveles más altos. Las asignaturas del nivel más bajo <b>NO</b> pueden desinscribirse.<br/><br/>"
            },
            {
              "subtitulo": "3.5 ¿CUÁL ES LA CANTIDAD DE ASIGNATURAS QUE PUEDO INSCRIBIR EN EL SEMESTRE? ",
              "texto": "Se debe inscribir y cursar un mínimo de dos asignaturas de la malla por semestre para permanecer en la carrera. Se hará excepción a esta norma sólo en aquellos casos justificados en que tal obligación no sea posible de cumplir.<br/><br/><br/>El número máximo de asignaturas que puede inscribir por semestre, es equivalente al número de asignaturas del nivel. Sin embargo, es posible inscribir una o más asignaturas adicionales, siempre que se hayan aprobado todas las asignaturas inscritas en el último período, se cumpla con los requisitos exigidos, que exista cupo y que no exista tope de horario entre las asignaturas inscritas.<br/><br/>"
            },
            {
              "subtitulo": "3.6 ¿PUEDO INSCRIBIR ASIGNATURAS QUE TENGAN EL MISMO HORARIO? (TOPE DE HORARIO)",
              "texto": "Los topes de horario suceden cuando dos asignaturas tienen asociados el mismo horario, o alguna de sus cátedras, laboratorios o clases de ejercicios tienen al menos un horario equivalente. En estos casos <b>NO</b> se pueden tomar ambas asignaturas, se debe inscribir sólo una o cambiar de coordinación si es que existe esa posibilidad en otro horario.<br/><br/><br/>Si los ramos con tope son de distinto nivel, se debe priorizar el de nivel más bajo.<br/><br/>"
            },
            {
              "subtitulo": "3.7 ¿HASTA CUÁNDO SE PUEDE DESINSCRIBIR ASIGNATURAS?",
              "texto": "Se podrán desincribir las asignaturas inscritas, con excepción de las obligatorias, durante las primeras cuatro semanas de iniciado el semestre académico.<br/><br/>"
            },
            {
              "subtitulo": "3.8 ¿QUÉ SIGNIFICA Y QUE MIDEN LOS ÍNDICES ACADÉMICOS 'PPA/FA/FE/NAS/NAR' QUE SALEN EN EL LOA?",
              "texto": "<b>PPA(PROMEDIO PONDERADO ACUMULADO):</b><br/>Cuociente que se obtiene al dividir la suma de los productos de todas las calificaciones finales obtenidas por el o la estudiante en las asignaturas cursadas, aprobadas y reprobadas, por su correspondiente número de créditos, y la suma de créditos cursados<br/><br/><br/><b>FACTOR DE AVANCE:</b><br/>Es el cuociente entre el número de asignaturas aprobadas por semestre y el número de asignaturas del semestre. El o la estudiante que aprueba todas las asignaturas por semestre, tiene un factor avance 1. El reglamento indica que un estudiante debe cursar al menos tres asignaturas por semestre. Por lo que un índice de avance inferior a 0.6 es un indicador inicial de alumno en riesgo. En general, mientras menor sea el valor, mayor es el nivel de riesgo.<br/><br/><br/><b>FACTOR DE EFICIENCIA:</b><br/>Es el total de asignaturas aprobadas versus el total de asignaturas al nivel que se encuentra el o la estudiante. Mientras menor sea el valor, mayor es el nivel de riesgo.<br/><br/><br/><b>NAS (INDICADOR RITMO DE AVANCE):</b><br/>Corresponde al número de asignaturas aprobadas versus el número de semestres de permanencia.<br/><br/><br/><b>NAR (INDICADOR DE EFECTIVIDAD):</b><br/>Se calcula como el número de asignaturas aprobadas dividido por el total de asignaturas reprobadas.<br/><br/>"
            },
            {
              "subtitulo": "3.9 ¿CÓMO SE PUEDE CAMBIAR DE SECCIÓN/COORDINACIÓN? YA QUE, NO QUEDÉ CON EL PROFESOR QUE ME INSCRIBÍ AL TOMAR EL RAMO.",
              "texto": "<b>No se puede realizar</b> o solicitar un cambio de sección o coordinación como tal, debido a que al inscribirse en una asignatura se está realizando una postulación para dicha coordinación. Solo en un caso especial se puede realizar una solicitud de cambio de coordinación que se llevarán a cabo cuando el solicitante tenga un impedimento médico por algún tratamiento en horarios específicos y el cambio se realizaría entre coordinaciones con distintos horarios siempre que sea posible.<br/><br/><br/>"
            }
          ]
        },
        {
          "titulo": "4. BENEFICIOS ESTUDIANTILES",
          "cuerpo": [
            {
              "subtitulo": "",
              "texto": "<b>Cabe destacar que todas las preguntas frecuentes asociadas a los beneficios estudiantiles, tienen validez con fecha (fecha de publicación).<br/><br/><br/>En cualquier caso, es recomendable comunicarse con la Asistente Social del Departamento Académico al que pertenece el o la estudiante, para una mejor orientación.<br/><br/><br/>Mayor información en: <a class='is-underlined' style='text-decoration-line: none;' href='http://www.vrae.usach.cl/area-de-beneficios-y-asistencia-estudiantil' target='_blank'>http://www.vrae.usach.cl/area-de-beneficios-y-asistencia-estudiantil</a></b><br/>"
            },
            {
              "subtitulo": "4.1 ¿QUÉ ES UNA BECA?",
              "texto": "Las becas son apoyos económicos que son entregados por el Ministerio de Educación, con el fin de que los y las nuevas estudiantes puedan financiar parte del costo de sus estudios. El tope máximo de las becas es el arancel referencial de la carrera. Los recursos del Ministerio de Educación son entregados directamente a las instituciones de Educación Superior, no al o la estudiante.<br/><br/><br/>Las becas se diferencian de los créditos, en que los recursos obtenidos por las becas no deben ser reembolsados o devueltos una vez egresado o egresada, titulado o titulada, o cuando no registre matrícula en el sistema por dos años. La carga del beneficio la hace en forma interna la Universidad, no necesitas realizar ningún trámite.<br/><br/><br/>Para más información sobre las becas disponibles hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.beneficiosestudiantiles.cl/becas/becas-de-arancel' target='_blank'>https://portal.beneficiosestudiantiles.cl/becas/becas-de-arancel</a><br/><br/>"
            },
            {
              "subtitulo": "4.2 ¿QUÉ ES UNA BECA COMPLEMENTARIA?",
              "texto": "Las becas complementarias son beneficios que entrega el Mineduc a los y las estudiantes de Educación Superior, los cuales consisten en un aporte correspondiente a los gastos asociados a los estudios, por ejemplo, la alimentación (Tarjeta JUNAEB o Beca de Alimentación para Educación Superior).<br/><br/>Para más información sobre las becas disponibles, hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.beneficiosestudiantiles.cl/becas/becas-complementarias' target='_blank'>https://portal.beneficiosestudiantiles.cl/becas/becas-complementarias</a><br/><br/><br/>Para saber si se cuenta con esta Beca, revisar el siguiente link: <a class='is-underlined' style='text-decoration-line: none;' href='https://baes.junaeb.cl/BAES/servlet/hantecedentes' target='_blank'>https://baes.junaeb.cl/BAES/servlet/hantecedentes</a><br/><br/>"
            },
            {
              "subtitulo": "4.3 ¿QUÉ SON LOS CRÉDITOS DE EDUCACIÓN SUPERIOR?",
              "texto": "Es un apoyo financiero que se le puede otorgar a los y las estudiantes de Educación Superior, que no cumplen con los requisitos para obtener Gratuidad o una beca de arancel. Estos créditos son el Fondo Solidario o el Crédito con Aval del Estado.<br/><br/><br/>Estos tipos de créditos son préstamos que sirven para cubrir parte del costo de los estudios por medio de recursos estatales o con respaldo del Estado (Como el CAE), y pueden ser entregados en UTM o UF, con un interés anual del 2%.<br/><br/><br/>Estos tipos de préstamos, deben ser devueltos después de egresar o titularse, y los plazos pueden ir desde 10 hasta 20 años, dependiendo del valor de la deuda y de los ingresos del deudor o la deudora.<br/><br/><br/>Para mayor información sobre, hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.beneficiosestudiantiles.cl/becas/creditos-de-educacion-superior' target='_blank'>https://portal.beneficiosestudiantiles.cl/becas/creditos-de-educacion-superior</a><br/><br/><br/>"
            },
            {
              "subtitulo": "4.4 ¿QUÉ ES EL CRÉDITO CON GARANTÍA ESTATAL (CAE)?",
              "texto": "Beneficio del Estado que se otorga a estudiantes de probado mérito académico, que requieran apoyo financiero para comenzar o seguir estudiando una carrera de pregrado en una institución acreditada y que sea parte del Sistema de Crédito con Garantía Estatal.<br/><br/><br/>Para mayor información sobre los requisitos para postular y las fechas de postulación, hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.ingresa.cl/como-postular/' target='_blank'>https://portal.ingresa.cl/como-postular/</a><br/><br/><br/>Para mayor información sobre qué es el Crédito con Garantía Estatal (CAE), hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.ingresa.cl/el-credito/que-es-el-credito-cae/' target='_blank'>https://portal.ingresa.cl/el-credito/que-es-el-credito-cae/</a><br/><br/>"
            },
            {
              "subtitulo": "4.5 ¿QUÉ ES EL FONDO SOLIDARIO DE CRÉDITO UNIVERSITARIO?",
              "texto": "Permite financiar total o parcialmente el arancel de referencia anual de una carrera, a los y las estudiantes.<br/><br/><br/>El Fondo Solidario de Crédito Universitario es compatible con cualquier beca de arancel que cubra un monto menor al determinado por el arancel de referencia de la carrera respectiva. Los únicos beneficios estudiantiles que no se pueden complementar con el Fondo Solidario, son la Beca Bicentenario, Beca Vocación de Profesor y Reparación, además del Crédito con Garantía del Estado.<br/><br/><br/>Para mayor información sobre los requisitos para postular y la forma de hacerlo, hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://www.ayudamineduc.cl/ficha/fondo-solidario-del-credito-universitario' target='_blank'>https://www.ayudamineduc.cl/ficha/fondo-solidario-del-credito-universitario</a><br/><br/><br/>"
            },
            {
              "subtitulo": "4.6 ¿CÓMO SE PUEDE POSTULAR A LAS BECAS Y CRÉDITOS? ",
              "texto": "Las postulaciones para las becas y créditos, se realizan de forma anual y mediante la realización del formulario FUAS (Formulario Único de Acreditación Socioeconómica).<br/><br/><br/>En el caso de que la situación socioeconómica cambie, se puede realizar el formulario FUAS en cualquier año de la carrera para postular a los beneficios estudiantiles entregados por el MINEDUC.<br/><br/><br/>Para mayor información seguir la guía paso a paso, hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.beneficiosestudiantiles.cl/guia-paso-paso-inscripcion' target='_blank'>https://portal.beneficiosestudiantiles.cl/guia-paso-paso-inscripcion</a><br/><br/>"
            },
            {
              "subtitulo": "4.7 ¿QUÉ REQUISITOS SE DEBEN CUMPLIR PARA LA RENOVACIÓN DE BECAS?",
              "texto": "•Cumplir con el requisito de avance académico, es decir, para estudiantes de primer año, aprobar al menos el 60% de los cursos de primer año de la carrera (malla formal) <br/>•Para estudiantes de cursos superiores, aprobar el 70% de los cursos correspondientes al año. En el caso de la Beca para Estudiantes en Situación de Discapacidad o la Beca de Continuidad de Estudios para Estudiantes de Instituciones en Cierre, deben haber aprobado el 50% de las asignaturas inscritas el año anterior<br/>•Llenar a fin de cada año el Formulario de Acreditación Socioeconómica que sube a la web la VRAE USACH (Vicerrectoría de Apoyo Estudiantil)<br/>•Matricularse dentro de los plazos establecidos<br/>•Para el Fondo Solidario, el avance académico requerido es, a partir del tercer semestre de carrera, contar con el 50% de aprobación de los ramos acumulados históricos<br/> <br/>Para mayor información hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.beneficiosestudiantiles.cl/guia-paso-paso-renovacion' target='_blank'>https://portal.beneficiosestudiantiles.cl/guia-paso-paso-renovacion</a><br/><br/><br/>"
            },
            {
              "subtitulo": "4.8 ¿QUÉ ES Y CÓMO SE PUEDE POSTULAR A LA GRATUIDAD?",
              "texto": "La gratuidad es un beneficio estudiantil otorgado a familias correspondientes al 60% de menores ingresos de la población, según el Registro Social de Hogares, considera el valor total del arancel regulado de la carrera y la matrícula durante la duración nominal de su carrera.<br/><br/><br/>La postulación a la gratuidad se efectúa a través de la información entregada en el Formulario Único de Acreditación Socioeconómica (FUAS) en la página del Ministerio de Educación.<br/><br/><br/>Para mayor información hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.beneficiosestudiantiles.cl/gratuidad' target='_blank'>https://portal.beneficiosestudiantiles.cl/gratuidad</a><br/><br/>"
            },
            {
              "subtitulo": "4.9 ¿CUÁL ES LA DURACIÓN NOMINAL DE LA CARRERA?",
              "texto": "Se refiere a la duración del plan de estudio de la malla curricular correspondiente a la carrera en curso.<br/><br/><br/> <br/>"
            },
            {
              "subtitulo": "4.10 ¿LA GRATUIDAD ES SÓLO PARA ESTUDIANTES QUE INGRESAN A PRIMER AÑO DE UNA CARRERA?",
              "texto": "No, cualquiera puede acceder al beneficio de la gratuidad, siempre que cumpla con los requisitos exigidos, independiente del semestre que se esté cursando.<br/><br/>"
            },
            {
              "subtitulo": "4.11 ¿QUÉ PASA CON EL CAE O FONDO SOLIDARIO DE CRÉDITO SI SE ACCEDE A LA GRATUIDAD COMO ESTUDIANTE DE CURSO SUPERIOR? ",
              "texto": "El CAE o Fondo solidario de Crédito, ya no será necesario una vez se acceda a la gratuidad, sin embargo, los compromisos adquiridos en estos créditos seguirán vigentes una vez se finalicen los estudios.<br/><br/><br/>En el caso en que se finalice el beneficio de Gratuidad, se puede volver a postular al Fondo Solidario de Crédito.<br/><br/>"
            },
            {
              "subtitulo": "4.12 ¿CUÁNTO DURA LA GRATUIDAD? ",
              "texto": "La Gratuidad cubrirá la duración formal de la carrera que se esté estudiando, por ejemplo, si la carrera dura 12 semestres, entonces el beneficio cubrirá esa cantidad de semestres de estudio.<br/><br/><br/>Si un estudiante congela sus estudios (retiro, postergación o renuncia) por razones justificadas, se mantendrá el beneficio de Gratuidad cuando se retome la carrera o inicie otra, para ello debe haber efectuado suspensión del beneficio en Bienestar Estudiantil de la Institución de Educación Superior dentro de los plazos establecidos por MINEDUC, debiendo ser aprobada la suspensión por MINEDUC.<br/><br/><br/>El trámite de la suspensión se realiza en Bienestar Estudiantil, una vez que el o la estudiante cuente con el documento de la Facultad en que se acepta su retiro, postergación o renuncia.<br/><br/>"
            },
            {
              "subtitulo": "4.13 ¿CUÁNTAS VECES SE PUEDE RECIBIR GRATUIDAD?",
              "texto": "La gratuidad se asigna solo 1 vez, y acepta un cambio de carrera.<br/><br/>"
            },
            {
              "subtitulo": "4.14 ¿QUÉ DEBO HACER PARA RENOVAR LA GRATUIDAD?",
              "texto": "La gratuidad se renueva al momento de matricularte en la Universidad semestre a semestre siempre que se cuente con las siguientes condiciones:<br/>•Matricularse en su institución o en otra casa de estudios adscrita a Gratuidad, para mantener su derecho.<br/>•Estar estudiando dentro de la duración formal de la carrera.<br/>•No se exige avance académico ni completar FUAS.<br/><br/><br/>Los y las estudiantes de primer año beneficiados, deben pagar su matrícula. Este pago es reembolsado posteriormente cuando se recibe el beneficio desde el MINEDUC. Desde segundo año en adelante solo se debe activar la matrícula, recordando que el trámite <b>NO</b> es automático, debe realizarlo el o la estudiante.<br/>"
            },
            {
              "subtitulo": "4.15 SI SE TIENE CAE Y ES BENEFICIARO DE LA GRATUIDAD ¿QUÉ SE DEBE HACER PARA CANCELAR EL CAE?",
              "texto": "El pagaré es un contrato que se firma al iniciar la carrera, el cual se firma solo una vez. Después, cada año, el o la estudiante debe indicar, en los plazos indicados, cuál es el monto anual que requiere (monto del arancel de la carrera que estudia o monto requerido de diferencia si es que tiene alguna beca).<br/><br/><br/>Al momento de obtener Gratuidad, ese monto anual que solicita el o la estudiante, debe dejarlo en 0.<br/><br/><br/>En el caso en que el o la estudiante pierda la Gratuidad, puede retomar el CAE si cumple con los requisitos, solicitando el monto que requiera.<br/><br/><br/>Los renovantes no necesitan volver a postular ni firmar el contrato. Para renovar el Crédito CAE, deberán completar y enviar el Formulario de Solicitud de Monto que estará disponible, en www.ingresa.cl, en el período de renovación, plazo que será informado de manera oportuna, en esa mismo sitio Web (usualmente se extiende de diciembre a abril de cada año).<br/><br/>"
            },
            {
              "subtitulo": "4.16 EN EL CASO DE REALIZAR UN CAMBIO DE CARRERA Y/O INSTITUCIÓN ¿LA GRATUIDAD CUBRIRÁ TODA LA NUEVA CARRERA?",
              "texto": "No, existe derecho a un solo cambio de carrera dentro de la misma institución o de otra que tenga Gratuidad, para mantener el beneficio, debiendo restarse de la duración de la Gratuidad para la nueva carrera, el período ya ocupado en la primera carrera.<br/><br/><br/>"
            },
            {
              "subtitulo": "4.18 ¿EL FONDO SOLIDARIO SE POSTERGA POR EL PERIODO SUSPENDIDO?",
              "texto": "El Fondo Solidario tiene la posibilidad de extenderse por un periodo de hasta un 50% adicional a la duración formal de la carrera. Se debe considerar esto al suspender, dado que, si suspende o no, el periodo de duración del beneficio no se extenderá más del 50% de la duración de la carrera.<br/>Para mayor información revisa el siguiente link: <a class='is-underlined' style='text-decoration-line: none;' href='https://portal.beneficiosestudiantiles.cl/suspension-de-beneficio' target='_blank'>https://portal.beneficiosestudiantiles.cl/suspension-de-beneficio</a>.<br/><br/><br/>"
            },
            {
              "subtitulo": "4.19 ¿QUÉ SUCEDERÁ CON LA RENOVACIÓN DE LOS BENEFICIOS PARA LOS Y LAS ESTUDIANTES CUYAS INSTITUCIONES SE ENCUENTREN EN PARO?",
              "texto": "Para la renovación de Becas son requisitos:<br/>1.- Estar matriculado en la carrera e institución en la que se otorgó el beneficio.<br/>2.- Cumplir con el requisito de avance académico:<br/><p style='margin-left: 25px;'>•Para estudiantes de primer año, aprobar al menos el 60% de los cursos de primer año de la carrera. <br/>•Para estudiantes de cursos superiores, aprobar del 70% de los cursos correspondientes al año. <br/>•En el caso de la Beca para Estudiantes en Situación de Discapacidad o la Beca de Continuidad de Estudios para Estudiantes de Instituciones en Cierre, se debe haber aprobado el 50% de los ramos inscritos el año anterior. <br/>•Para el Fondo Solidario, el avance académico requerido es del 50% a partir del tercer semestre de carrera.</p><br/><br/><br/>"
            },
            {
              "subtitulo": "4.20 ADEMÁS DE LAS BECAS ENTREGADAS POR EL ESTADO, ¿EXISTEN BECAS PROPIAS DE LA UNIVERSIDAD?",
              "texto": "Las becas institucionales de la Universidad de Santiago de Chile, son recursos que permiten apoyar a las y los estudiantes en términos económicos, para que puedan concluir con éxito su carrera universitaria. Existen las “Becas Arancelarias” y las “No Arancelarias”. Para mayor información sobre los requisitos para postular, se debe contactar directamente a la asistente social de la facultad (añadir link a contactos).<br/>Para mayor información hacer clic aquí: <a class='is-underlined' style='text-decoration-line: none;' href='https://admision.usach.cl/becas-y-beneficios/' target='_blank'>https://admision.usach.cl/becas-y-beneficios/</a><br/>"
            }
          ]
        },
        {
          "titulo": "5. GLOSARIO USACH",
          "cuerpo":[ {
            "subtitulo": "",
            "texto": "<b>•Cedenna:</b> Centro para el Desarrollo de la Nanociencia y la Nanotecnología<br/><b>•CESS:</b> Centre for Experimental Social Science<br/><b>•CIIET:</b> Centro de Investigación en Innovación y educación con TIC<br/><b>•DGDE:</b> Dirección de género, diversidad y equidad<br/><b>•DRII:</b> Departamento de Relaciones Internacionales e Interuniversitarias<br/><b>•EAO:</b> Escuela de Artes y Oficios.<br/><b>•FACIMED:</b> Facultad de Ciencias Médicas<br/><b>•FACTEC:</b> Facultad Tecnológica<br/><b>•FAE:</b> Facultad de Administración y Economía<br/><b>•FAHU:</b> Facultad de Humanidades<br/><b>•FID:</b> Formación Inicial Docente<br/><b>•FING:</b> Facultad de Ingeniería<br/><b>•FQyB:</b> Facultad de Química y Biología<br/><b>•Fudea:</b> Fundación de Egresados y Amigos de la Universidad de Santiago<br/><b>•Paiep:</b>  Programa de Acceso Inclusivo, Equidad y Permanencia<br/><b>•PEI:</b> Plan Estratégico Institucional<br/><b>•MEI:</b> Modelo Educativo Institucional<br/><b>•SCT:</b> Sistema de Créditos Transferible<br/><b>•Segic:</b> Servicio de Gestión Informática y Computación<br/><b>•UNIE:</b> Unidad de Innovación Educativa<br/><b>•UPS:</b> Unidad de Promoción de la Salud Psicológica<br/><b>•VRA:</b> Vicerrectoría Académica<br/><b>•Vridei:</b> Vicerrectoría de Investigación, Desarrollo e Innovación<br/><b>•VRAE:</b> Vicerrectoría de Apoyo al Estudiante<br/><b>•VIME:</b> Vicerrectoría de Vinculación con el Medio<br/><b>•VIPO:</b> Vicerrectoría de Postgrado "
          }
          ]
        }
      ]
    }
  },
  created() {
    document.title = "GSD | Preguntas frecuentes"
  },
};
</script>


<style>



.responsive {
  width: 100%;
  height: auto;
}

/* Solid border */
hr.solid {
  border-top: 2px solid #bbb;
  width: 100%;
}
</style>
