<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(createNewState)">
                <!-- Box de Formulario Appointment -->
                <div class="modal-card" style="max-width: 200%; width: 600px;">
                    <header class="modal-card-head has-background-secondary">
                        <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                        <button
                                type="button"
                                class="delete"
                                @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body" ref="ModalCreateState">
                        <div class="columns">
                            <div class="column">
                                <div class="mb-2" v-if="Object.keys(validationErrors).length !== 0">
                                    <b-message type="is-danger" has-icon>
                                        <div v-for="(item,index) in validationErrors" :key="index">
                                            {{ item[0] }}
                                        </div>
                                    </b-message>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Nombre del paso *</label>
                            <ValidationProvider rules="required|max:200" v-slot="{errors}">
                                <input class="input" type="text" v-model="stateName" placeholder="Ingrese un nombre">
                                <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="field mt-4">
                            <label class="label">Descripción *</label>
                            <div class="control">
                                <ValidationProvider rules="required|max:250" v-slot="{errors}">
                                    <textarea class="textarea" v-model="stateDescription"
                                              placeholder="Ingrese una descripcion"></textarea>
                                    <span class="validation-alert">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="field mt-4">
                            <label class="label">¿El paso tiene duración específica?</label>
                            <b-radio v-model="haveDuration"
                                     native-value="no">
                                No
                            </b-radio>
                            <b-radio v-model="haveDuration"
                                     native-value="si">
                                Si
                            </b-radio>
                        </div>
                        <div v-if="haveDuration === 'si'" class="field mt-4">
                            <label class="label">Ingrese la duración (en días) *</label>
                            <ValidationProvider rules="required|integer|min_value:1|max_value:365" v-slot="{errors}">
                                <b-input placeholder="Number"
                                         type="text"
                                         v-model="stateDuration">
                                </b-input>
                                <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </section>

                    <footer class="modal-card-foot actions has-background-secondary">
                        <div class="field is-grouped is-pulled-right">
                            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                            <button class="button is-primary" type="submit">Guardar</button>
                        </div>
                    </footer>
                </div>

            </form>
        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalCreateState",
    props: ["modalHeader"],
    emits: ['newstate'],
    components: {},
    data: function () {
        return {
            selectedInternalPositions: [],
            optionsInternalPositions: [],
            internalPositions: {},
            validationErrors: {},
            isLoading: false,
            haveDuration: "no",
            stateDuration: 1,
            stateName: "",
            stateDescription: ""
        }
    },

    created() {
    },

    methods: {
        createNewState() {
            let newState = {
                "real_name": this.stateName,
                "name": this.stateName.toUpperCase().normalize("NFD"),
                "information": this.stateDescription,
                "internal_positions": [],
                "documents": [],
                "forms": []
            }
            if (this.haveDuration === 'no') {
                newState['duration'] = 0;
            } else {
                newState['duration'] = this.stateDuration;
            }

            this.$emit('close');
            this.$emit('newstate', newState);
        },

        saveState() {
            this.isLoading = true;
            // prepare formData
            let formData = new FormData();
            let name = this.stateName.toUpperCase().normalize("NFD");
            formData.append("name", name);
            formData.append("real_name", this.stateName);
            formData.append("information", this.stateDescription);
            if (this.haveDuration === 'no') {
                this.stateDuration = 0;
            }
            formData.append("duration", this.stateDuration)

            // make axios
            axios
                .post("/states.json", formData)
                .then(response => {
                    this.$buefy.dialog.confirm({
                        title: 'Listo',
                        message: 'El paso se ha creado exitosamente.',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'check-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true,
                        canCancel: false,
                        closeOnConfirm: true,
                        onConfirm: () => {
                        }
                    });
                    this.isLoading = false;
                    this.$emit('close');
                    this.$emit('newstate', response.data);
                })
                .catch(error => {
                    this.validationErrors = error.response.data
                    var elmnt = document.getElementById("app");
                    elmnt.scrollIntoView();
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al crear el paso, por favor revise el formato.',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                    this.isLoading = false;
                });
        },
    }
}
</script>