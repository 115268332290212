<template>
    <div>
        <section class="pt-6">
            <div class="columns is-centered">
                <div class="column is-three-quarters has-background-white">
                    <!-- Titulo de la sección -->
                    <div class="columns is-vcentered is-multiline">
                        <div class="column is-four-fifths ">
                            <h1 class="subtitle is-1 has-text-secondary">
                                Gestión de usuarios con código Authenticator para firma masiva
                            </h1>

                        </div>                        
                    </div>
                    <!-- Separador -->
                    <hr class="solid">
                </div>
            </div>
            <div class="columns is-centered is-multiline ">
                <div class="column is centered is-three-quarters has-background-white">
                    <b-table
                            :data="users"
                            :paginated="isPaginated"
                            :per-page="perPage"
                            :current-page.sync="currentPage"
                            :pagination-position="paginationPosition"
                            :default-sort-direction="defaultSortDirection"
                            :sort-icon="sortIcon"
                            :sort-icon-size="sortIconSize"
                            default-sort="created_at"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                    >
                        <b-table-column field="id" label="ID" width="100" sortable searchable>
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por código"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template  v-slot="props">
                            {{props.row.id}}
                          </template>

                        </b-table-column>
                        <b-table-column field="full_name" label="Nombre Completo" sortable searchable>
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por nombre"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template  v-slot="props">
                            {{ props.row.full_name }}
                          </template>

                        </b-table-column>
                        <b-table-column field="email" label="Correo Corporativo" sortable searchable>
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por email"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template  v-slot="props">
                            {{ props.row.email }}
                          </template>

                        </b-table-column>
                        <b-table-column label="Acciones" v-slot="props" width="25%">
                            <b-button type="is-danger" @click="updateUser(props.row.id)"
                                      icon-left="trash">Eliminar acceso a código
                            </b-button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </section>


        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>

import axios from "axios";
import moment from "moment/moment";
import {openBuefyAlertDialog, openBuefyConfirmDialog} from "../../packs/utilities";

export default {
    name: "UserCodeManagement",
    data() {
        return {
            validationErrors: {},
            isPaginated: true,
            paginationPosition: 'bottom',
            defaultSortDirection: 'desc',
            sortIcon: 'arrow-up',
            sortIconSize: 'is-small',
            currentPage: 1,
            perPage: 10,
            isLoading: false,
            users: [],
        }
    },
    created() {
      document.title="Administración códigos de acceso de usuarios"
      this.getUserInfo();
    },
    components: {},
    methods: {
      getUserInfo(){
        this.isLoading=true
        axios.get('/dashboard/user-code-management.json')
            .then(response => {
              this.users = response.data.users
              this.users = this.users.map(user => {
                var newUser = user
                newUser["created_at"] = moment(newUser["created_at"], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
                return newUser;
              });
            })
            .catch(error => {
              //console.log(error);
              this.user = [];
            }).finally(()=>{
              this.isLoading=false;
        });
      },
      updateUser(userId) {
        let message = "Esta acción deshabilitará los códigos de acceso que posee el usuario.";
        this.$buefy.dialog.confirm({
          title: "Deshabilitar códigos de acceso",
          message: message,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          onConfirm: () => this.deleteCodeUser(userId)
        });
      },

      deleteCodeUser(userId){
        this.isLoading=true
        axios
            .post("/users/disabled-code-access/"+userId)
            .then(()=> {
                this.getUserInfo();
                this.$buefy.dialog.alert({
                  message: 'Código de usuario deshabilitado',
                  type: 'is-success',
                  hasIcon: true,
                  icon: 'check-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                  
                }
            ).catch( () => {
                this.$buefy.dialog.alert({
                message: 'Hubo un error en su solicitud',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })              
            }
        ).finally(()=>{
              this.isLoading=false
            }
        );
      },
      compareDate(a, b) {
        if (a == null) {
          return -1;
        }
        if (b == null) {
          return 1;
        }
        const dateA = new Date(a.split('-').reverse().join('-'));
        const dateB = new Date(b.split('-').reverse().join('-'));
        return dateA - dateB;
      },

      customSortCreatedAt(a, b, isAsc) {
        return isAsc ? this.compareDate(a.created_at, b.created_at) : this.compareDate(b.created_at, a.created_at);
      },
    }

}
</script>