<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(saveState)">
                <!-- Box de Formulario Appointment -->
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head has-background-secondary">
                        <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                        <button
                                type="button"
                                class="delete"
                                @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body" ref="ModalCreateState">
                        <iframe :src="form_prefilled_url +'&embedded=true'" width="750" height="600" frameborder="0"
                                marginheight="0" marginwidth="0">Cargando…
                        </iframe>
                    </section>
                </div>

                <footer class="modal-card-foot actions has-background-secondary">
                    <div class="field is-grouped is-pulled-right">
                        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                        <button class="button is-primary" type="submit">Guardar</button>
                    </div>
                </footer>
            </form>
        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalEditForm",
    props: ["modalHeader", "form_prefilled_url"],
    components: {},
    data: function () {
        return {
            validationErrors: {},
            isLoading: false,
        }
    },

    created() {
        //Bring internal positions from bakend
        //Meke axios to rails backend
        //Save internal positions into this.optionsInternalPositions
        //manage errors
        //save error.response.data into this.validationsErrors
        //Chnage multiselect to show only names and considerate the id
        //like this   :options="resolution_types.map(type => type.id)"
        //:custom-label="opt => resolution_types.find(x => x.id === opt).name"
    },

    methods: {
        saveState() {
        },
    }
}
</script>