<template>
  <div class="container box my-5">
    <b-loading v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="title has-text-left">Habilitación de Usuarios</div>
    <hr class="solid" />
    <ValidationObserver ref="observerStudent" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(searchStudent)">
        <b-field label="Buscar estudiante por RUT*">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{
              required: true,
              regex: /^\d{1,8}-[0-9kK]$/,
            }"
          >
            <b-input
              v-model="formattedRut"
              placeholder="Ej: 12345678-k"
            ></b-input>
            <span class="validation-alert">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-field>
        <div class="column has-text-right">
          <b-button
            class="has-text-right"
            icon-left="search"
            native-type="submit"
            type="is-secondary"
            >Buscar
          </b-button>
        </div>
      </form>
    </ValidationObserver>

    <div v-if="student" class="box mt-3">
      <div class="title has-text-left is-5 mb-0">
        Información del Estudiante
      </div>
      <hr class="solid" />
      <div class="has-text-centered">
        <div class="my-1"><b>Nombre:</b> {{ student.full_name }}</div>
        <div class="my-1"><b>RUT:</b> {{ student.rut }}</div>
        <div class="my-1"><b>Correo institucional:</b> {{ student.email }}</div>
      </div>

      <div class="has-text-centered">
        <div class="my-1"><b>Carreras cursadas:</b></div>
        <ul class="ml-6">
          <li v-for="career in careers" class="my-1">
            • {{ formatCareerName(career) }}
            <b-icon
              v-if="career.shift === 'Diurno'"
              class="mr-1"
              icon="sun"
            ></b-icon>
            <b-icon
              v-else-if="career.shift === 'Vespertino'"
              class="mr-1"
              icon="moon"
            ></b-icon>
            <b-icon v-else class="mr-1" icon="question"></b-icon>
            Ingreso {{ career.entry_semester }}-{{ career.entry_year }}
          </li>
        </ul>
      </div>
      <div class="columns">
        <div class="column"></div>
        <div class="column is-8">
          <b-notification
            v-if="student.alt_email"
            :closable="false"
            type="is-warning"
            class="has-text-centered"
            has-icon
          >
            Este estudiante ya posee un correo alternativo habilitado para
            ingresar al sistema SAS. <br />
            El correo alternativo es el siguiente: <b>{{ student.alt_email }}</b
            >.
          </b-notification>
        </div>
        <div class="column"></div>
      </div>

      <div class="mt-3">
        <ValidationObserver ref="observerEmail" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postEmail)">
            <div class="columns">
              <div class="column">
                <b-field label="Correo a habilitar con este estudiante*">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      email: true,
                    }"
                  >
                    <b-input
                      v-model="email"
                      placeholder="Ej: nombre.apellido@gmail.com"
                    ></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Confirmar correo ingresado*">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      email: true,
                      same_as: email,
                    }"
                  >
                    <b-input
                      v-model="emailConfirmation"
                      placeholder="Ej: nombre.apellido@gmail.com"
                    ></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="level-right">
              <div class="buttons">
                <b-button
                  icon-left="eraser"
                  type="is-warning"
                  @click="onClearData"
                  >Limpiar información
                </b-button>
                <b-button
                  class="has-text-right"
                  icon-left="plus"
                  native-type="submit"
                  type="is-success"
                  >Habilitar correo
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      rut: "",
      student: null,
      careers: [],
      isLoading: false,
      email: "",
      emailConfirmation: "",
    };
  },
  created() {
    document.title = "Habilitación de correo alternativo";
  },
  computed: {
    formattedRut: {
      get() {
        if (this.rut.length > 1)
          return this.rut.replace(/([0-9a-zA-Z]+)([0-9a-zA-Z])/, "$1-$2");
      },
      set(value) {
        this.rut = value.replace("K", "k").replace(/-/g, "");
      },
    },
  },
  methods: {
    searchStudent() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/people/enable-email.json", {
          params: {
            rut: vm.rut.slice(0, -1),
          },
        })
        .then((response) => {
          vm.student = response.data.student;
          vm.careers = response.data.careers;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          vm.isLoading = false;
        });
    },
    formatCareerName(career) {
      let jornada = career.shift;
      if (career.shift === "") jornada = "Jornada indefinida";
      return `[${career.career_code}] ${career.career_name} - ${career.type}, ${jornada} `;
    },
    postEmail() {
      let vm = this;
      vm.isLoading = true;
      axios
        .post("/people/enable-email.json", {
          usach_email: vm.student.email,
          personal_email: vm.email,
          rut: vm.student.rut.split("-")[0],
          dv: vm.student.rut.split("-")[1],
        })
        .then((response) => {
          vm.$buefy.dialog.alert({
            title: "¡Éxito!",
            message: "Correo habilitado con éxito.",
            type: "is-success",
            hasIcon: true,
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            vm.$buefy.dialog.alert({
              title: "Error",
              message: "El correo ingresado ya está habilitado.",
              type: "is-danger",
              hasIcon: true,
            });
          } else {
            vm.$buefy.dialog.alert({
              title: "Error",
              message: "Ha ocurrido un error al habilitar el correo.",
              type: "is-danger",
              hasIcon: true,
            });
          }
        })
        .then(() => {
          vm.isLoading = false;
        });
    },
    onClearData() {
      this.$buefy.dialog.confirm({
        title: "Limpiar información",
        message: "¿Desea limpiar la información del estudiante?",
        confirmText: "Sí",
        cancelText: "No",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          this.student = null;
          this.careers = [];
          this.email = "";
          this.emailConfirmation = "";
        },
      });
    },
  },
};
</script>
