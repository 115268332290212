<template>
  <div
      class="container is-fullheight"
      style="padding: 2rem; background-color: #f2f2f2"
      :class="isMobile ? 'p-2' : ''"
  >
    <div
        class="section"
        :class="isMobile ? 'p-2 pt-3' : ''"
        style="background-color: white; height: 100%"
    >
      <!-- Titulo de la sección -->
      <div
          class="is-flex is-justify-content-flex-start is-align-items-center"
          :class="isMobile ? 'ml-3 mt-1' : ''"
      >
        <div class="is-flex is-flex-wrap-nowrap">
          <h1 class="title is-1 has-text-secondary mb-0 mr-5">
            Solicitud Código {{ request.code }}
          </h1>
          <!--
          <span v-if="!isMobile" class="tag is-primary is-large">{{
            request_type.real_name
          }}</span>
          -->
        </div>
      </div>
      <!--
      <span
        v-if="isMobile"
        class="tag is-primary is-large"
        :class="isMobile ? 'ml-3 mt-2' : ''"
        >{{ request_type.real_name }}</span
      >
      -->
      <div class="subtitle is-3 mb-0 mt-4">
        <b>{{ request_type.real_name }}</b>
      </div>
      <!-- Separador -->
      <hr class="solid mt-0" />

      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item, index) in errores_validacion" :key="index">
            {{ item }}
          </div>
        </b-message>
      </div>
      <div class="columns is-multiline my-2 px-5">
        <div class="column is-full-mobile is-half-tablet">
          <b>Solicitante:</b> {{ applicantData.full_name }}
        </div>
        <div class="column is-full-mobile is-half-tablet">
          <b>Correo Institucional:</b> {{ applicantData.corporate_mail }}
          <b-tooltip
              multilined
              :label="
              'Usted posee un correo alternativo habilitado para acceder a su cuenta en el sistema SAS y recibir las ' +
              'notificaciones. El correo alternativo es el siguiente: ' +
              applicantData.personal_email +
              '.'
            "
              v-if="
              applicantData &&
              applicantData.personal_email &&
              !applicantData.corporate_email_active
            "
          >
            <b-icon icon="exclamation-triangle" type="is-warning"></b-icon>
          </b-tooltip>
          <b-tooltip
              multilined
              class="is-warning is-light"
              :label="
              'Usted posee su correo institucional habilitado para recibir las notificaciones e ingresar ' +
              'al sistema SAS. El correo alternativo ' +
              applicantData.personal_email +
              ' no está habilitado para ' +
              'recibir notificaciones ni ingresar al sistema.'
            "
              v-else-if="
              applicantData &&
              applicantData.personal_email &&
              applicantData.corporate_email_active
            "
          >
            <b-icon icon="exclamation-triangle" type="is-warning"></b-icon>
          </b-tooltip>
        </div>
        <div class="column is-full-mobile is-half-tablet">
          <b>Fecha de Inicio de Solicitud:</b> {{ request.start_date }}
        </div>
        <div class="column is-full-mobile is-half-tablet">
          <b>Carrera de la Solicitud:</b> {{ applicantData.career_name }}
        </div>
        <div class="column is-full-mobile is-full-tablet">
          <b>Justificación de la Solicitud:</b> {{ request.description }}
        </div>
      </div>
      <div
          class="columns is-multiline mt-3 px-5"
          v-if="lastState != null && lastState.documents.length > 0"
      >
        <div
            class="column is-full is-flex is-align-items-center is-justify-content-space-between"
        >
          <h1 class="is-full subtitle is-3 has-text-secondary">
            Documentos de la solicitud
          </h1>
        </div>
        <div
            v-for="(document, document_index) in lastState.documents"
            :key="document_index"
            class="column is-full ml-5 is-vcentered is-border-light-grey mb-1"
        >
          <div class="columns is-vcentered">
            <span class="column">
              {{ document_index + 1 }}. {{ setName(document.name) }}
            </span>

            <div class="column is-narrow">
              <b-button
                  type="is-secondary"
                  @click="downloadFile(document.id_document_drive)"
              >
                Descargar
                <i class="fas fa-download ml-1"></i>
              </b-button>
            </div>

            <div class="column is-narrow">
              <b-button
                  type="is-primary"
                  icon-right="eye"
                  @click="openModalShowDocument(document)"
              >
                Ver Documento
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <h1 class="subtitle is-3 has-text-secondary mb-0 px-5 mt-5">
        Formularios Completados:
      </h1>
      <div
          v-for="(state, state_index) in states"
          :key="state_index"
          class="my-3 px-6 mb-5"
      >
        <div
            v-if="state.google_forms !== null && state.google_forms.length > 0"
            v-for="(google_form, google_form_index) in state.google_forms"
            :key="google_form_index"
        >
          <div
              v-if="
              google_form.form_responses !== null &&
              google_form.form_responses.length > 0
            "
              v-for="(
              form_response, form_response_index
            ) in google_form.form_responses"
              :key="form_response_index"
          >
            <div class="columns is-vcentered is-border-light-grey">
              <div class="column">
                <span
                ><b>Nombre del formulario: </b> {{ google_form.name }}</span
                >
              </div>
              <div class="column has-text-right">
                <b-button
                    type="is-primary mx-1"
                    @click="openModalShowFormResponse(form_response)"
                    icon-right="poll-h"
                >
                  Ver Respuesta
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          v-if="baseStates.length > 0 && baseStates[0].documents.length > 0"
          class="my-6"
      >
        <h1 class="subtitle is-3 has-text-secondary mb-0 px-5 mt-5">
          Documentos Adjuntados:
        </h1>
        <div
            v-for="(document, documentIndex) in baseStates[0].documents"
            :key="documentIndex"
            class="my-3 px-6 mb-5"
        >
          <div class="columns is-vcentered is-border-light-grey">
            <div class="column">
              <b>Nombre de Documento:</b> {{ document.name.split("-")[0] }}
            </div>
            <div class="column is-3">
              <div class="level-right">
                <div class="buttons">
                  <b-button
                      icon-right="download"
                      type="is-secondary"
                      @click="downloadFile(document.id_document_drive)"
                  >
                  </b-button>
                  <b-button
                      type="is-primary"
                      icon-right="eye"
                      @click="openModalShowDocument(document)"
                  >
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 mb-2 px-5">
        <div>
          <h1 class="subtitle is-3 has-text-secondary mb-0">
            Estado de la Solicitud:
            <b-button
                class="ml-1"
                :type="progressColor"
                :icon-left="progressIcon"
                size="is-medium"
            >
              {{ getProgress() }}
            </b-button>
          </h1>
        </div>
        <b-notification
            v-if="getProgress() !== 'No Procesada'"
            class="my-4"
            :type="progressColor + ' is-light'"
            icon="exclamation"
            :closable="false"
            has-icon
        >
          <b>{{ getProgress() }}</b
          >: {{ progressDescription }}
        </b-notification>
        <b-notification
            v-else
            class="my-4"
            icon="exclamation"
            :closable="false"
            has-icon
            style="background-color: #e4e4e4; color: black;"
        >
          <b>{{ getProgress() }}</b
          >: {{ progressDescription }}
        </b-notification>
        <div
            v-if="showObservation && getProgress() !== 'No Procesada'"
            class="column is-full is-border-black"
        >
          <b>Observación final:</b> {{ extraInfo.last_observation }}
        </div>
        <div v-else-if="showObservation && getProgress() === 'No Procesada'"
             class="column is-full is-border-black"
        >
          <b
          >Esta solicitud no fue procesada porque usted no respondió a la siguiente
            detención:</b
          >
          {{ extraInfo.last_observation }}
        </div>
        <div v-if="getProgress() == 'Aceptada'" class="column is-full">
          <p v-if="extraInfo.file_attach_in_mail">
            <b-icon icon="envelope"></b-icon>Se ha enviado un archivo de
            respuesta a su correo
          </p>
        </div>
        <div v-if="getProgress() === 'Detenida'" class="column is-full">
          <hr class="solid" />
          <div v-if="getProgress() === 'Detenida' && lastState != null">
            <modal-add-observation-applicant
                modal-header="Historial de observaciones"
                :last-state="lastState"
                :progress-of-request="progress_of_request"
                :initial_observation="lastState.observation"
                :request_state_id="lastState.id"
            >
            </modal-add-observation-applicant>
          </div>
        </div>
        <div
            v-if="getProgress() != 'Desistida'"
            class="column is-full has-text-right"
        >
          <hr class="solid" />
          <b-button
              v-if="canDesist()"
              type="is-danger"
              @click="confirmDesist()"
              icon-left="user-times"
          >
            Desistir a la solicitud
          </b-button>
        </div>
      </div>

      <!-- Table -->

      <!--------------------------------------------------------------- Modals --------------------------------------------------------------------->

      <b-modal
          v-model="isModalShowFormResponseActive"
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          width="90%"
      >
        <template #default="props">
          <modal-show-form-response
              modal-header="Ver Respuesta de Formulario"
              :google_form_id="currentFormId"
              :google_form_response_id="currentFormResponseId"
              @close="props.close"
              :is_reviewer="false"
          >
          </modal-show-form-response>
        </template>
      </b-modal>
      <b-modal
          v-model="isModalEditFormResponseActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
      >
        <template #default="props">
          <modal-edit-form-response
              modal-header="Editar Respuesta de Formulario"
              :google_form_id="currentFormId"
              :google_form_response_id="currentFormResponseId"
              @close="props.close"
          >
          </modal-edit-form-response>
        </template>
      </b-modal>
      <b-modal
          v-model="isModalShowDocumentActive"
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          width="90%"
      >
        <template #default="props">
          <preview-program-modal
              :docId="currentDocumentIdDrive"
              @close="props.close"
          >
          </preview-program-modal>
        </template>
      </b-modal>
      <b-modal
          v-model="isModalAddFormResponseActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
      >
        <template #default="props">
          <modal-add-form-response
              modal-header="Responder Formulario"
              :state_id="currentState"
              :request_state_id="currentRequestStateId"
              :google_form_id="currentFormId"
              :request_id="request.id"
              @close="props.close"
          >
          </modal-add-form-response>
        </template>
      </b-modal>
      <b-modal
          v-model="isModalUploadDocumentsActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
      >
        <template #default="props">
          <modal-upload-document
              modal-header="Subir Documento"
              :request_state_id="currentRequestStateId"
              :document_id="currentDocumentToUpload"
              :request_id="request.id"
              :multiple="false"
              @close="props.close"
          >
          </modal-upload-document>
        </template>
      </b-modal>
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import ModalShowDocument from "../../Documents/ModalShowDocument.vue";
import ModalUploadDocument from "../../Documents/ModalUploadDocument.vue";
import ModalAddFormResponse from "../../GoogleForms/ModalAddFormResponse.vue";
import ModalShowFormResponse from "../../GoogleForms/ModalShowFormResponse.vue";
import ModalEditFormResponse from "../../GoogleForms/ModalEditFormResponse.vue";
import ModalAddObservationApplicant from "./ModalAddObservationApplicant.vue";
import { getStateInfo, setName } from "../../../packs/utilities";
import PreviewProgramModal from "../../Documents/PreviewProgramModal.vue";

export default {
  name: "ShowRequestApplicant",
  props: ["requestId", "showObservation"],
  components: {
    PreviewProgramModal,
    ModalShowDocument,
    ModalUploadDocument,
    ModalAddFormResponse,
    ModalShowFormResponse,
    ModalEditFormResponse,
    ModalAddObservationApplicant,
  },
  data: function () {
    return {
      isLoading: false,
      request: {
        code: "",
        start_date: "",
      },
      request_type: {},
      states: [],
      // applicantData se debe obtener desde SIGA
      applicantData: {
        commune: {},
      },
      errores_validacion: {},
      defaultOpenedDetails: [],
      showDetailIcon: true,
      switchVisualization: true,
      activeStep: 0,
      invertedStates: [],
      isModalEditFormActive: false,
      isModalShowDocumentActive: false,
      isModalUploadDocumentsActive: false,
      isModalAddFormResponseActive: false,
      isModalShowFormResponseActive: false,
      isModalEditFormResponseActive: false,
      isModalObservationActive: false,
      currentFormId: "",
      currentFormResponseId: "",
      currentDocumentIdDrive: "",
      currentRequestStateId: "",
      currentDocumentToUpload: "",
      isAttentionrequired: false,
      currentPageTable: 1,
      isMobile: false,
      currentState: 0,
      progress_of_request: "",
      progressIcon: "paper-plane",
      progressColor: "is-primary",
      progressDescription: "Enviada",
      extraInfo: {
        last_observation: "",
        file_attach_in_mail: false,
      },
      lastState: null,
      lastStateId: null,
      state: [],
      baseStates: [],
    };
  },
  created() {
    document.title = "Solicitud N°" + this.requestId;
    this.isLoading = true;
    axios
        .get("/requests/" + this.requestId + ".json")
        .then((response) => {
          this.request = response.data.request_data;
          if (this.request["start_date"] !== null) {
            this.request.start_date = this.formatDate(this.request.start_date);
          }
          this.baseStates = structuredClone(response.data.states);
          this.states = response.data.states
              .reverse()
              .filter((state) => state.progress.name != "Sin Iniciar");
          this.lastState = this.states[0];
          this.lastState.documents = this.lastState.documents.filter(
              (document) => document.id_drive
          );
          this.progress_of_request = response.data.progress_of_request;
          this.extraInfo = response.data.request_info;
          //this.states = this.invertedStates;
          //this.states.reverse();
          this.activeStep = this.states.length - 1;
          this.request_type = response.data.request_type;
          this.applicantData = response.data.applicant;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
  },
  methods: {
    setName,
    canDesist() {
      if (
          this.progress_of_request == "Desistida" ||
          this.progress_of_request == "Rechazada" ||
          this.progress_of_request == "Aceptada" ||
          this.progress_of_request == "No Corresponde" ||
          this.progress_of_request == "No Procesada"
      ) {
        return false;
      }
      return true;
    },
    //--------------------------------------------------
    formatDate(date) {
      date = date.substring(0, 10);
      if (date != null && date !== "" && date !== undefined) {
        var arreglo = date.split("-");
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
      } else {
        return "";
      }
    },

    requireAttention(state) {
      //Check if there are documents to be uploaded
      for (let i = 0; i < state.documents.length; i++) {
        if (state.documents[i].id_document_drive == null) {
          this.isAttentionrequired = true;
          return true;
        }
      }

      // Check if there are forms to be responded
      for (let i = 0; i < state.google_forms.length; i++) {
        if (
            state.google_forms[i].form_responses == null ||
            state.google_forms[i].form_responses.length == 0
        ) {
          this.isAttentionrequired = true;
          return true;
        }
      }

      return false;
    },
    confirmDesist() {
      this.$buefy.dialog.confirm({
        title: "Desistir de la solicitud",
        cancelText: "Cancelar",
        message: `¡Precaución! Al aceptar esta acción la solicitud será cerrada y no habrá manera de revertirlo.`,
        confirmText: "Desistir",
        type: "is-danger",
        onConfirm: () => this.desist(),
      });
    },
    desist() {
      //metodo para desistir
      this.isLoading = true;
      let formData = new FormData();
      formData.append("id", this.lastState.id);
      axios
          .post("/request_states/desist_state.json", formData)
          .then((response) => {
            this.isLoading = false;
            window.location.reload();
          })
          .catch((error) => {
            this.errores_validacion = error.response.data;
            let elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$buefy.dialog.alert({
              title: "Error",
              message: this.errores_validacion.error,
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            this.isLoading = false;
          });
    },

    getProgress() {
      if (this.states.length == 0 || this.lastState == null) {
        this.progressColor = "is-grey";
        this.progressIcon = "exclamation";
        this.progressDescription = "La solicitud aún no ha sido iniciada";
        return "Sin iniciar";
      }
      let info = getStateInfo(this.lastState.progress.name);
      this.progressColor = info.color;
      this.progressIcon = info.icon;
      this.progressDescription = this.lastState.progress.description;
      return this.lastState.progress.name;
    },
    reverseStates() {
      this.states.reverse();
    },
    openModalShowFormResponse(form_response) {
      this.currentFormId = form_response.google_form_id;
      this.currentFormResponseId = form_response.form_response_id;
      this.isModalShowFormResponseActive = true;
    },
    openModalObservation(state_id) {
      this.currentState = state_id;
      this.isModalObservationActive = true;
    },

    openModalEditFormResponse(form_response) {
      this.currentFormId = form_response.google_form_id;
      this.currentFormResponseId = form_response.form_response_id;
      this.isModalEditFormResponseActive = true;
    },

    openModalShowDocument(document) {
      this.currentDocumentIdDrive = document.id_document_drive;
      this.isModalShowDocumentActive = true;
    },

    openModalAddFormResponse(requestStateId, form) {
      this.currentRequestStateId = requestStateId;
      this.currentFormId = form.google_form_id;
      this.isModalAddFormResponseActive = true;
    },

    openModalUploadDocuments(requestStateId, documentId) {
      this.currentRequestStateId = requestStateId;
      this.currentDocumentToUpload = documentId;
      this.isModalUploadDocumentsActive = true;
    },
    deleteFileOfDocument(document, state_index) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("id", document.id);

      axios
          .post("/documents/delete_file_document.json", formData)
          .then((response) => {
            this.$buefy.dialog.confirm({
              title: "Listo",
              message: "El documento se ha eliminado exitosamente.",
              type: "is-success",
              hasIcon: true,
              icon: "check-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
              canCancel: false,
              onConfirm: () => {
                console.log("Documento eliminado exitosamente.");
              },
            });
            let index_to_update =
                this.states[state_index].documents.indexOf(document);
            this.states[state_index].documents[
                index_to_update
                ].id_document_drive = null;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error.response.data);
            this.$buefy.dialog.alert({
              title: "Error",
              message: "No se pudo eliminar el documento.",
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            this.isLoading = false;
          });
    },

    downloadFile(document_id) {
      this.isLoading = true;

      axios
          .request({
            url: "/documents/download_file/" + document_id,
            responseType: "blob",
          })
          .then((response) => {
            //var blobUrl = URL.createObjectURL(response.data);
            //window.open(blobUrl)

            let info = response.headers["content-disposition"];
            let name = info.split('"')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.isLoading = false;
            this.$buefy.dialog.alert({
              message: "Se descargo con exito.",
              type: "is-success",
              hasIcon: true,
              icon: "check-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
          })
          .catch((e) => {
            this.isLoading = false;
            this.$buefy.dialog.alert({
              message: "Fallo la descarga.",
              type: "is-danger",
            });
          });
    },
  },

  mounted() {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const mediaQueryTablet = window.matchMedia("(min-width: 1409px)");

    // Set initial value on first load.
    this.isMobile = !mediaQuery.matches;
    this.isTablet = !mediaQueryTablet.matches;

    mediaQueryTablet.addEventListener("change", (event) => {
      if (event.matches) {
        this.isTablet = false;
      } else {
        this.isTablet = true;
      }
    });

    // Listen for changes
    mediaQuery.addEventListener("change", (event) => {
      if (event.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
  },
};
</script>

<style>
.step-label {
  max-width: 16rem !important;
}

.b-steps.is-vertical
> .steps:not(.has-label-right):not(.has-label-left)
.step-items
.step-item
.step-link
> .step-details {
  background-color: white;
}

.step-column {
  max-width: 900px;
}
</style>

<!--

========================
 VISTA DETALLADA
===========================================
<b-steps v-else v-model="activeStep" label-position="bottom" size="default" mobile-mode="compact" :hasNavigation="isTablet" :vertical="!isTablet">
            <b-step-item v-for="(state, index_state) in states" :type="state.progress.name === 'En Curso' ? 'is-secondary' : 'is-success'" :step="index_state +1" header-class="step-label" :label="state.real_name" :key="index_state" :clickable="true" :icon="state.progress.name === 'En Curso' ? 'map-marker-alt' : (state.progress.name === 'Aprobado' ? 'check' : 'octagon-exclamation')">
                <div class="columns p-1">
                    <div class="column step-column">
                        <h1 v-if="state.progress.name === 'En Curso'" class="title is-4 has-text-left ml-3 mb-1 has-text-secondary">Tu solicitud se encuentra en {{state.real_name}}
                            <i class="fas fa-map-marker-alt"></i></h1>

                        <h1 v-if="state.progress.name === 'Aprobado'" class="title is-4 has-text-left ml-3 mb-1 has-text-success">Estado Aprobado
                            <i class="fas fa-check-circle"></i></h1>

                        <h1 v-if="state.progress.name === 'Detenido'" class="title is-4 has-text-left ml-3 mb-1 has-text-danger">Tu se solicitud se encuentra detenida aquí
                            <i class="fas fa-octagon-exclamation"></i></h1>

                        <div v-if="requireAttention(state)" class="column ml-5">
                            <b-message  type="is-warning" has-icon>
                                <strong>Atención: </strong> Debes subir los documentos requeridos o completar los formularios pendientes.
                            </b-message>
                        </div>

                        <div class="column mx-5">
                            <strong>Infomación: </strong> {{state.information}}

                        </div>

                        <div class="column mx-5">
                            <strong>Duración aproximada: </strong> {{state.duration == 0 ? 'No tiene' : state.duration}}
                        </div>

<div v-if="state.google_forms.length > 0 && state.progress.name === 'En Curso'" class="column is-full is-flex is-align-items-center is-justify-content-space-between ">
<h1 class="title is-4 has-text-secondary mb-0 ml-3 ">
    Formularios
</h1>

</div>

<div v-if="state.google_forms.length > 0 && state.progress.name === 'En Curso'" class="column is-full ml-5">
<dl v-for="(form, form_index) in state.google_forms" :key="form_index" class=" card p-3">
    <dt>
        <div class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-1">
            <div class="is-flex content is-small mb-0 p-3">
                <strong class="mb-0">{{ form.name }}</strong>
            </div>
            <div class="is-flex m-2">
                <b-button v-if="state.progress.name === 'En Curso' && form.form_responses.length === 0" type="is-sucess"  @click="openModalAddFormResponse(state.id,form)">
                    Añadir Respuesta
                    <i class="fas fa-poll-h ml-2"></i></b-button>
            </div>
        </div>
        <b-message v-if="form.form_responses.length === 0" type="is-info" has-icon class="m-3">
            Este Formulario aún no ha sido respondido.
        </b-message>
    </dt>
    <hr v-if="form.form_responses.length > 0" class="solid mb-3 m-0">
    <dd v-for="(response, response_index) in form.form_responses" :key="response_index" class="">
        <div class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-1">
            <div class="is-flex content my-auto">
                Respuesta
            </div>
            <div class="is-flex is-flex-wrap-wrap my-2">
                <b-button type="is-primary mx-1" outlined  @click="openModalShowFormResponse(response)">
                    Ver Respuesta
                    <i class="fas fa-poll-h ml-2"></i>
                </b-button>
            </div>

        </div>

    </dd>

</dl>
</div>

<div v-if="state.documents.length > 0 && state.progress.name === 'En Curso'" class="column is-full is-flex is-align-items-center is-justify-content-space-between ">
<h1 class="title is-4 has-text-secondary mb-0 ml-3 ">
    Documentos
</h1>
</div>

<div v-if="state.documents.length > 0 && state.progress.name === 'En Curso'" class="m-0 p-0">
<div v-for="(document, document_index) in state.documents" :key="document_index" class="column is-full ml-5">
    <div class="card is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-1">
        <div class="is-flex card-content">
            {{ document.name }}
        </div>
        <div v-if="(document.id_document_drive == null || document.id_document_drive === '') && state.progress.name === 'En Curso'" class="is-flex m-2">
            <b-button type="is-primary mx-1" outlined  @click="openModalUploadDocuments(state.id, document.id)">
                Subir Documento
                <i class="fas fa-upload ml-1"></i>
            </b-button>
        </div>
        <div v-else class="is-flex m-2">
            <b-button type="is-primary mx-1" outlined  @click="openModalShowDocument(document)">
                Ver Documento
                <i class="fas fa-eye m l-1"></i>
            </b-button>
            <b-button type="is-secondary mx-1" outlined  @click="downloadFile(document.id_document_drive)">
                Descargar
                <i class="fas fa-download ml-1"></i>
            </b-button>
             <b-button type="is-danger mx-1" outlined  @click="deleteFileOfDocument(document, index_state)">
                 Eliminar
                 <i class="fas fa-trash-alt ml-1"></i>
             </b-button>
        </div>
    </div>
</div>
</div>

<div class="column is-full is-flex is-align-items-center is-justify-content-space-between ">
<h1 class="title is-4 has-text-secondary mb-0 ml-3 ">
    Observaciones
</h1>

</div>
<div class="column is-full ml-5">
<div class="card">
    <div class="is-flex is-flex-wrap-wrap p-5">
        <span v-if="state.observation" style="white-space: pre-line">{{ state.observation }}</span>
        <span v-else style="white-space: pre-line; color: darkgrey">Aún no se han realizado observaciones</span>
    </div>
</div>
</div>

</div>
</div>
</b-step-item>

</b-steps>

===============================TABLA ESTADOS======================
<b-table :paginated="states.length < 15? false:true"
                     :pagination-simple=false
                     pagination-position="bottom"
                     :current-page="currentPageTable"
                     per-page="15"
                     v-if="switchVisualization"
                     :data="states == null ? [] : states"
                     :bordered="false"
                     :striped="true"
                     :narrowed="false"
                     :hoverable="true"
                     :focusable="false"
                     :mobile-cards="true"
                     :detailed="true"
                     detail-key="id"
                     :show-detail-icon="showDetailIcon"
                     :opened-detailed="defaultOpenedDetails"
                     @details-open="(row) => $buefy.toast.open(`${row.real_name}`)">

<b-table-column field="area" :visible="true" label="Estado" width="200" v-slot="props">
<template v-if="showDetailIcon">
    {{ props.row.real_name }}
</template>
<template v-else>
    <a @click="props.toggleDetails(props.row)">
        {{ props.row.real_name }}
    </a>
</template>
</b-table-column>

<b-table-column field="info" :visible="true" label="Información">
<template v-slot="props">
    {{ props.row.information }}
</template>

</b-table-column>


<b-table-column field="progress" :visible="true" label="Progreso">
<template v-slot="props">
    <b-tag v-if="props.row.progress.name === 'En Curso'" type="is-secondary">
        {{ props.row.progress.name }}
    </b-tag>
    <b-tag v-if="props.row.progress.name === 'Detenido'" type="is-dark">
        {{ props.row.progress.name }}
    </b-tag>
    <b-tag v-if="props.row.progress.name === 'Aprobado'" type="is-success">
        {{ props.row.progress.name }}
    </b-tag>

</template>

</b-table-column>

<b-table-column field="start_date" :visible="true" label="Fecha">
<template slot="searchable" slot-scope="props">
    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por tipo de solicitud"
             icon="search"/>
</template>
<template v-slot="props">
    {{ formatDate(props.row.start_date) }}
</template>

</b-table-column>

<b-table-column field="action" :visible="isAttentionrequired" label="Acción">
<template v-slot="props">
    <b-button type="is-warning" outlined
              v-if="props.row.progress.name === 'En Curso' && requireAttention(props.row)"
              @click="reverseStates();switchVisualization = !switchVisualization;">
        <i class="fas fa-exclamation-triangle mr-2"></i>Requiere Atención
    </b-button>
</template>
</b-table-column>

<template #detail="props">

    <div class="media-content">
        <div class="content">
            <div class="columns is-multiline">
                <div class="column is-three-quarters">
                    <p>
                        <strong>Observacion Inicial</strong>
                        <br>
                    </p>
                </div>

                <div v-if="props.row.progress.name === 'Detenido'"
                     class="column has-text-right is-one-quarter">
                    <b-button type="is-primary"
                              icon-left="plus"
                              @click="openModalObservation(props.row.id)"
                    >
                        Añadir/Ver correcciones
                    </b-button>
                </div>

                <div class="column is-full">

                    <p>
                        {{
                            props.row.observation === null ? 'Aún no se han realizado observaciones.' : props.row.observation
                        }}
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<template #empty>
    <b-skeleton v-if="isLoading" height="80px"></b-skeleton>
    <div v-else class="has-text-centered">No hay estados para mostrar</div>
</template>
</b-table>