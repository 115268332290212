<template>
  <!-- header -->
    <div style="background-color: white">
        <img src="../../../../public/gsd/home_student_images/headers/contactos.png" class="responsive">
        <!-- body -->
        <div class="columns is-centered">
            <div class="column is-9">

                <b-collapse
                        class="card"
                        animation="slide"
                        v-for="(collapse, index) of collapses"
                        :key="index"
                        :open="isOpen == index"
                        @open="isOpen = index"
                        :aria-id="'contentIdForA11y5-' + index">
                    <template #trigger="props">
                        <div
                                class="card-header"
                                role="button"
                                :aria-controls="'contentIdForA11y5-' + index"
                                :aria-expanded="props.open">
                            <p class="card-header-title is-flex is-justify-content-left subtitle is-3-desktop is-5-mobile has-text-secondary has-text-weight-bold is-family-secondary ">

                                {{ collapse.titulo }}

                            </p>

                            <a class="card-header-icon">
                                <b-icon
                                        :icon="props.open ? 'fa-chevron-down' : 'fa-chevron-up'">
                                </b-icon>
                            </a>

                        </div>

                    </template>

                    <div class="card-content" v-for="(imagen,index) of collapse.imagenes" :key="index">
                        <img v-bind:src=imagen class="responsive"><img>

                    </div>

                </b-collapse>
            </div>
        </div>
        <!-- Footer -->

        <div style="background-color: #003B71" align="center">
            <img src="../../../../public/logo_sin_texto.png"/>
            <div>
                <a href="https://www.fing.usach.cl/" target="_blank">
                    <h1 class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold">
                        WEB OFICIAL FING USACH | Entra aquí
                    </h1>
                </a>
                <br/>
            </div>

        </div>
        <div style="background-color: #FAC633" align="center">
            <br/>
            <h1 class="is-size-6 has-text-black has-text-centered is-family-secondary ">
                Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins nº 3363. Estación Central.
                Santiago. Chile.
            </h1>
            <a href="https://www.usach.cl/contacto" target="_blank">
                <h1 class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold">
                    Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
                </h1>
            </a>
            <br/>

        </div>
    </div>

</template>

<script>

import Accordion from "../Tools/accordion";
import AccordionItem from "../Tools/accordion-item";


export default {
    name: "GsdContactUs",
    components: {},
    data() {
        return {
            isOpen: 0,
            collapses: [
                {
                    'titulo': 'CONTACTOS GENERALES',
                    'imagenes': [require('../../../../public/gsd/home_student_images/contact_us/contactos_generales.png')]
                },
                {
                    'titulo': 'CONTACTOS POR DEPARTAMENTOS ACADÉMICOS',
                    'imagenes': [require('../../../../public/gsd/home_student_images/contact_us/Contactos_departamentos.png'), require('../../../../public/gsd/home_student_images/contact_us/coordinadores_programas.png')]
                },
                {
                    'titulo': 'CONTACTOS ASISTENTES SOCIALES',
                    'imagenes': [require('../../../../public/gsd/home_student_images/contact_us/Asistentes_Sociales.png')]
                }
            ]
        }
    }  ,
  created() {
    document.title = "GSD | Contáctanos"
  },

};
</script>


<style>


.responsive {
    width: 100%;
    height: auto;
}

/* Solid border */

</style>
