<template>
  <!-- header -->

    <div style="background-color: white">
        <img src="../../../../public/gsd/home_student_images/headers/reserva_hora.png" class="responsive">
        <div class="columns is-centered ">
            <div class="column is-9">
                <!-- body -->
                <div>
                    <p class="is-size-5 has-text-secondary is-family-secondary has-text-centered has-text-weight-bold">
                        Si tienes dudas externas sobre las solicitudes de Docencia, que no sean resueltas en esta
                        página, te invitamos a reservar una hora de consulta, donde se responderá a tus inquietudes.
                        <br/>
                        <br/>
                        Una vez entres a la plataforma deberás seleccionar el tipo de consulta que mejor se ajuste a tus
                        necesidades y luego emplear tu correo institucional para reservar la hora. Los datos solicitados
                        serán usados única y exclusivamente para poder agendar la hora dentro de la plataforma.<br/>
                        <br/>
                        <br/>

                        Una vez hecha la reservación, te llegará un correo confirmando la cita y, en caso de no poder
                        asistir, dentro del mismo correo podrás cancelarla. Solo es posible reservar hora con 10 horas
                        de anticipación.
                        <br/>
                        <br/>

                        Recuerda que las solicitudes que se realizan en este portal, son dirigidas para estudiantes de
                        la FING USACH de la modalidad DIURNO.
                        <br/><br/><br/>

                    </p>
                    <a
                            href="https://www.google.com/url?q=https%3A%2F%2Fes.fresha.com%2Fa%2Fconsultas-secretaria-de-docencia-santiago-avenida-libertador-bernardo-ohiggins-3363-r3veczca%3FpId%3D620310&sa=D&sntz=1&usg=AOvVaw14Rlv4ndymZHMR21rVOTaW"
                            target="_blank">
                        <img src="../../../../public/gsd/home_student_images/appointment/appointment.png"/>
                    </a>
                </div>

            </div>
        </div>
        <!-- Footer -->

        <div style="background-color: #003B71" align="center">
            <img src="../../../../public/logo_sin_texto.png"/>
            <div>
                <a href="https://www.fing.usach.cl/" target="_blank">
                    <h1 class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold">
                        WEB OFICIAL FING USACH | Entra aquí
                    </h1>
                </a>
                <br/>
            </div>

        </div>
        <div style="background-color: #FAC633" align="center">
            <br/>
            <h1 class="is-size-6 has-text-black has-text-centered is-family-secondary ">
                Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins nº 3363. Estación Central.
                Santiago. Chile.
            </h1>
            <a href="https://www.usach.cl/contacto" target="_blank">
                <h1 class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold">
                    Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
                </h1>
            </a>
            <br/>

        </div>
    </div>

</template>
<script>
import Accordion from "../Tools/accordion";
import AccordionItem from "../Tools/accordion-item";

export default {
    name: "GsdAppointment",
    components: {},
  created() {
    document.title = "GSD | Reserva de hora"
  },

};
</script>


<style>


.responsive {
    width: 100%;
    height: auto;
}

/* Solid border */

</style>
