<template>
  <div>
    <div class="modal-card" style="width: 100%">
      <header class="modal-card-head has-background-secondary">
        <p class="modal-card-title has-text-white">Reasignar revisión</p>

        <button
          type="button"
          class="delete"
          @click="$emit('close')"
          style="background: #ff3860"
        />
      </header>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(confirmChange)">
          <section class="modal-card-body" style="height: 23rem">
            <div class="columns my-2 px-5">
              <div class="column">
                <b-field label="Persona asignada">
                  <b-input
                    :placeholder="
                      actualReviewer.full_name +
                      ' - ' +
                      actualReviewer.internal_position_name
                    "
                    readonly
                    disabled
                  >
                  </b-input>
                </b-field>
              </div>
              <!-- Unit filter -->
              <div class="column">
                <b-field label="Nueva persona asignada">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <multiselect
                      v-model="selectedReviewer"
                      :options="possibleReviewers"
                      :custom-label="(opt) => newLabel(opt)"
                      :multiple="false"
                      placeholder="Seleccione un nuevo revisor"
                      selectLabel=""
                      selectedLabel=""
                      deselectLabel=""
                      track-by="id"
                    >
                      <template v-slot:noOptions>
                        Sin revisores disponibles para este paso
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot has-background-secondary">
            <b-button
              type="is-danger"
              icon-left="times"
              @click="$emit('close')"
            >
              Cerrar
            </b-button>
            <b-button type="is-success" native-type="submit" icon-left="save">
              Guardar cambios
            </b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import {
  openBuefyAlertDialog,
  openBuefyConfirmDialog,
} from "../../../packs/utilities";
export default {
  name: "ModalRevisionHistory",
  props: ["requestStateId"],
  components: {},
  data: function () {
    return {
      historyReview: null,
      request: null,
      possibleReviewers: [],
      actualReviewer: { full_name: "", internal_position_name: "" },
      selectedReviewer: null,
      currentPageTable: 1,
      isLoading: false,
    };
  },

  created() {
    this.getPossibleUsers();
  },

  methods: {
    newLabel(reviewer) {
      let result = this.possibleReviewers.find((x) => x.id === reviewer.id);
      return result.full_name + " - " + result.internal_position_name;
    },
    async getPossibleUsers() {
      this.isLoading = true;
      return await axios
        .get(
          "/request_states/possible-reviewers/" + this.requestStateId + ".json"
        )
        .then((response) => {
          this.possibleReviewers = response.data.possible_reviewers;
          this.actualReviewer = response.data.actual_reviewer;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    confirmChange() {
      this.$buefy.dialog.confirm({
        title: "¡Precaución!",
        message: `¿Desea confirmar el cambio de la persona que revisa? Esta acción debe ser informada a todas las parte afectadas`,
        cancelText: "Cancelar",
        confirmText: "Aceptar",
        type: "is-warning",
        onConfirm: () => this.updateReviewer(),
      });
    },
    updateReviewer() {
      this.isLoading = true;
      this.formData = new FormData();
      this.formData.append("user_id", this.selectedReviewer.id);
      this.formData.append("id", this.requestStateId);

      axios
        .post("/request_states/change-reviewer", this.formData)
        .then((response) => {
          openBuefyConfirmDialog(
            this,
            "Revisión actualizada",
            "Se ha cambiado la persona quien revisa el paso."
          );
          this.$emit("updated");
        })
        .catch((error) => {
          console.log(error);
          this.validationErrors = error.response.data.error;
          openBuefyAlertDialog(this, "error", this.validationErrors);
        })
        .finally(() => {
          this.actualReviewer = this.selectedReviewer;
          this.selectedReviewer = null;
          this.getPossibleUsers();
          this.isLoading = false;
        });
    },
  },
};
</script>
