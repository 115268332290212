<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitFormResponse)">
                <!-- Box de Formulario Appointment -->
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head has-background-secondary">
                        <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                        <button type="button" class="delete" @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body" ref="ModalCreateState">
                        <div class="columns">
                            <div class="column">
                                <div class="mb-2" v-if="Object.keys(validationErrors).length !== 0">
                                    <b-message type="is-danger" has-icon>
                                        <div v-for="(item,index) in validationErrors" :key="index">
                                            {{ item[0] }}
                                        </div>
                                    </b-message>
                                </div>
                            </div>
                        </div>

                        <div class="columns is-multiline">

                            <div v-for="(element, index) in formToFill" :key="index"
                                 class="field column is-half-tablet is-half-desktop">
                                <label class="label">{{ element.item_title }}</label>
                                <div v-if="element.itemType === 'TEXT'" class="control">
                                    <ValidationProvider :rules="element.is_required ? 'required|max:250' : 'max:250'"
                                                        v-slot="{errors}">
                                        <input class="input" v-model="element.item_response" type="text"
                                               :placeholder="element.item_title ">
                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'DATE'" class="control">
                                    <ValidationProvider :rules="element.is_required ? 'required' : ''"
                                                        v-slot="{errors}">
                                        <b-datepicker v-model="element.item_response" placeholder="ingresa una fecha"
                                                      icon="calendar-alt"
                                                      :icon-right="element.item_response ? 'close-circle' : ''"
                                                      icon-right-clickable
                                                      :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                                                      locale="es"
                                                      @icon-right-click="element.item_response = null" trap-focus>
                                        </b-datepicker>
                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'MULTIPLE_CHOICE'" class="control">
                                    <ValidationProvider :rules="element.is_required ? 'required' : ''"
                                                        v-slot="{errors}">
                                        <multiselect v-model="element.item_response" :options="element.options"
                                                     :multiple="false" placeholder="Seleccione una opción"
                                                     selectLabel="Presione para seleccionar"
                                                     selectedLabel="Seleccionado"
                                                     deselectLabel="Presione para deseleccionar">
                                            <template v-slot:noOptions>
                                                No existen datos
                                            </template>
                                            <span slot="noResult">
                                            No se encontraron elementos.
                                        </span>
                                        </multiselect>

                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'CHECKBOX'" class="control">
                                    <ValidationProvider :rules="element.is_required ? 'required' : ''"
                                                        v-slot="{errors}">
                                        <div class="">
                                            <b-checkbox v-for="(option, ch_index) in element.options" :key="ch_index"
                                                        v-model="element.item_response" :native-value="option">
                                                {{ option }}
                                            </b-checkbox>
                                        </div>
                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div v-if="element.itemType === 'PARAGRAPH_TEXT'" class="control">
                                    <ValidationProvider :rules="element.is_required ? 'required|max:500' : 'max:500'"
                                                        v-slot="{errors}">
                                        <input class="input" v-model="element.item_response" type="textarea"
                                               :placeholder="element.item_title ">
                                        <span class="validation-alert">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

                <footer class="modal-card-foot actions has-background-secondary">
                    <div class="field is-grouped is-pulled-right">
                        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                        <button class="button is-primary" type="submit">Guardar</button>
                    </div>
                </footer>
            </form>
        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalAddFormResponse",
    props: ["modalHeader", "request_type_id", "request_state_id", "request_id", "google_form_id"],
    components: {},
    data: function () {
        return {
            selectedForm: [],
            optionsForms: ['list', 'of', 'options'],
            internalPositions: {},
            validationErrors: {},
            isLoading: false,
            form_link: '',
            formToFill: [],
        }
    },

    created() {
        this.isLoading = true;
        axios
            .get("/google_forms/get_form/" + this.google_form_id + ".json")
            .then(response => {
                this.formToFill = response.data;
                this.isLoading = false;
            })
            .catch(error => {
                console.log(error)
                this.isLoading = false;
            });
        /*
        this.isLoading = true;
        axios
            .get("/request_types/" + this.request_type_id + "/google_forms.json")
            .then(response => {
                this.optionsForms = response.data;
                this.isLoading = false;
            })
            .catch(error => {
                console.log("No se pudieron cargar los formularios disponibles para responder.")
            });
            */
    },

    methods: {
        submitFormResponse() {
            this.isLoading = true;
            let formData = new FormData();
            formData.append("response_to_submit", JSON.stringify(this.formToFill))
            formData.append("form_id", this.google_form_id)
            formData.append("request_state_id", this.request_state_id)
            axios
                .post("/google_form_responses.json", formData)
                .then(response => {
                    this.$buefy.dialog.confirm({
                        title: 'Listo',
                        message: 'La respuesta se ha guardado exitosamente.',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'check-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true,
                        canCancel: false,
                        onConfirm: () => {
                            window.location.href = "/requests/" + this.request_id
                        }
                    });
                    this.isLoading = false;
                })
                .catch(error => {
                    this.validationErrors = error.response.data
                    let elmnt = document.getElementById("app");
                    elmnt.scrollIntoView();
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al crear al guardar tu respuesta, favor revisar formato',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                    this.isLoading = false;
                });
        }
    },

    watch: {
        selectedForm: function (form) {
            this.isLoading = true;
            axios
                .get("/google_forms/get_form/" + form.google_form_id + ".json")
                .then(response => {
                    this.formToFill = response.data;

                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error)
                    this.isLoading = false;
                });
        }
    }
}
/*
OLD WATCHER--
* watch: {
    selectedForm: function (form){
      this.isLoading = true;
      axios
        .get("/google_forms/get_form_link/"+form.google_form_id+".json")
        .then(response => {
          this.form_link = response.data.link;
          console.log(this.form_link)
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
    }
* */
</script>
