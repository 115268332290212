<template>
  <div>
    <div class="modal-card" style="width: 100%">
      <header class="modal-card-head">

        <p class="modal-card-title">Historial de respuestas</p>

        <button
          type="button"
          class="delete"
          @click="$emit('close')"
          style="background: #ff3860"
        />
      </header>

      <!---->
      <section class="modal-card-body">

        <div>

          <b-table :data="historyReview == null ? [] : historyReview" :paginated="true"
                   pagination-position="bottom" :current-page="currentPageTable" per-page="5"
                   :striped="true" :narrowed="true" :hoverable="true" :loading="isLoading == null ? true : false"
                   :mobile-cards="true">


            <!-- Column number of requests -->
            <b-table-column field="reviewer" :visible="true"  label="Revisor" searchable>
              <template slot="searchable" slot-scope="props">
                <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por nombre"
                         icon="search" size="is-small" expanded />
              </template>
              <template v-slot="props">
                    <span class="is-flex ">
                        {{ props.row.reviewer }}
                    </span>
              </template>

            </b-table-column>


            <!-- Column Name -->
            <b-table-column field="state" :visible="true" label="Pasos"  sortable searchable>
              <template slot="searchable" slot-scope="props">
                <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por paso" icon="search"
                         size="is-small" expanded/>
              </template>
              <template v-slot="props">
                {{ props.row.state }}
              </template>
            </b-table-column>

            <!-- Column number of requests detenidas-->
            <b-table-column field="description" :visible="true"  label="Acción" searchable>
              <template slot="searchable" slot-scope="props">
                <b-input v-model="props.filters[props.column.field]" placeholder="Buscar acción"
                         icon="search" size="is-small" expanded/>
              </template>
              <template v-slot="props">
                    <span class="is-flex ">
                        {{ props.row.description }}
                    </span>
              </template>


            </b-table-column>

            <!-- Column number of requests finalizadas-->
            <b-table-column field="date" :visible="true" label="Fecha" searchable :custom-search="dateParcialSearch">
              <template slot="searchable" slot-scope="props">
                <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por fecha u hora"
                         icon="search" size="is-small" expanded/>
              </template>
              <template v-slot="props">
                    <span class="is-flex ">
                        {{props.row.date }}

                    </span>
              </template>

            </b-table-column>

            <template #empty>
              <div class="has-text-centered">Esta solicitud aún no está siendo revisada</div>
            </template>
          </b-table>
        </div>
      </section>

      <footer class="modal-card-foot columns">
        <div class="column has-text-right">
          <b-button
            type="is-danger"
            icon-left="times"
            native-type="submit"
            @click="$emit('close')"

          >
            Cerrar
          </b-button>

        </div>
      </footer>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/moment";
import {parcialSearch} from "../../../packs/utilities";

export default {
  name: "ModalRevisionHistory",
  props: ["requestId"],
  components: {},
  data: function () {
    return {
      historyReview:null,
      request: null,
      requestType:null,
      currentPageTable: 1,
      isLoading:false,
    };
  },

  created() {
    this.isLoading = true;
    axios
        .get("/requests/" + this.requestId + "/revision_history.json")
        .then(response => {
          //console.log(response)
          this.historyReview = response.data.history_reviews;
          this.historyReview = this.historyReview.map((history) => {
            var newHistory = history;
            newHistory["date"] = moment(
                newHistory["date"],
                "YYYY-MM-DD HH:mm:ss"
            ).format("DD-MM-YYYY HH:mm:ss");
            return newHistory;
          });
          this.request = response.data.request
          this.requestType = response.data.request_type

          this.isLoading = false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading = false;
        });

  },

  methods: {
    dateParcialSearch(row,input){
      return parcialSearch(row.date,input)
    },
    formatDate(date) {

      if (date != null && date !== "" && date !== undefined) {
        return new moment(date).format('DD-MM-YYYY');
      }
    },
    formatHour(date) {
      let fecha_hora = date.split("T");
      date = fecha_hora[1];
      if (date != null && date !== "" && date !== undefined) {
        return date.substring(0, 5)

      }
    },

  },
};
</script>
