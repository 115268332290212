<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(submitFormResponse)">
        <!-- Box de Formulario Appointment -->
        <div v-if="Object.keys(validationErrors).length !== 0" class="columns">
          <div class="column">
            <div class="mb-2">
              <b-message type="is-danger" has-icon>
                <div
                  v-for="(item, error_index) in validationErrors"
                  :key="error_index"
                >
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
          </div>
        </div>

        <div class="columns is-multiline">
          <div
            v-for="(element, index) in formToFill"
            :key="index"
            class="field column is-full-desktop is-full-mobile is-full-tablet"
          >
            <div v-if="element.itemType === 'TEXT'" class="control">
              <label :class="{ label: true, 'is-grey': element.prefilled }"
                >{{ element.item_title }}
              </label>

              <div class="control">
                <ValidationProvider
                  :rules="element.is_required ? 'required|max:250' : 'max:250'"
                  v-slot="{ errors }"
                >
                  <input
                    class="input"
                    v-model="element.item_response"
                    type="text"
                    :placeholder="element.item_title"
                    :disabled="element.prefilled"
                    @input="handleInput"
                  />
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div v-if="element.itemType === 'DATE'" class="control">
              <label :class="{ label: true, 'is-grey': element.prefilled }"
                >{{ element.item_title }}
              </label>
              <ValidationProvider
                :rules="element.is_required ? 'required' : ''"
                v-slot="{ errors }"
              >
                <b-datepicker
                  v-model="element.item_response"
                  placeholder="Ingrese una fecha"
                  icon="calendar"
                  :icon-right="selected ? 'close-circle' : ''"
                  icon-right-clickable
                  @icon-right-click="element.item_response = null"
                  trap-focus
                  :disabled="element.prefilled"
                  @input="handleInput"
                  :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                  locale="es"
                >
                </b-datepicker>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div v-if="element.itemType === 'MULTIPLE_CHOICE'" class="control">
              <label :class="{ label: true, 'is-grey': element.prefilled }"
                >{{ element.item_title }}
              </label>
              <ValidationProvider
                :rules="element.is_required ? 'required' : ''"
                v-slot="{ errors }"
              >
                <multiselect
                  v-model="element.item_response"
                  :options="element.options"
                  :multiple="false"
                  placeholder="Seleccione una opción"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione para deseleccionar"
                  :disabled="element.prefilled"
                  @select="handleInput"
                >
                  <template v-slot:noOptions> No existen datos </template>
                  <span slot="noResult"> No se encontraron elementos. </span>
                </multiselect>

                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div v-if="element.itemType === 'LIST'" class="control">
              <label :class="{ label: true, 'is-grey': element.prefilled }"
                >{{ element.item_title }}
              </label>
              <ValidationProvider
                :rules="element.is_required ? 'required' : ''"
                v-slot="{ errors }"
              >
                <multiselect
                  v-model="element.item_response"
                  :options="element.options"
                  :multiple="false"
                  placeholder="Seleccione una opción"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione para deseleccionar"
                  :disabled="element.prefilled"
                  @select="handleInput"
                >
                  <template v-slot:noOptions> No existen datos </template>
                  <span slot="noResult"> No se encontraron elementos. </span>
                </multiselect>

                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div v-if="element.itemType === 'CHECKBOX'" class="control">
              <label :class="{ label: true, 'is-grey': element.prefilled }"
                >{{ element.item_title }}
              </label>
              <ValidationProvider
                :rules="element.is_required ? 'required' : ''"
                v-slot="{ errors }"
              >
                <div class="">
                  <b-checkbox
                    v-for="(option, ch_index) in element.options"
                    :key="ch_index"
                    v-model="element.item_response"
                    :native-value="option"
                    :disabled="element.prefilled"
                    @input="handleInput"
                  >
                    {{ option }}
                  </b-checkbox>
                </div>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div v-if="element.itemType === 'PARAGRAPH_TEXT'" class="control">
              <label :class="{ label: true, 'is-grey': element.prefilled }"
                >{{ element.item_title }}
              </label>
              <ValidationProvider
                :rules="element.is_required ? 'required|max:500' : 'max:500'"
                v-slot="{ errors }"
              >
                <input
                  class="input"
                  v-model="element.item_response"
                  type="textarea"
                  :placeholder="element.item_title"
                  :disabled="element.prefilled"
                  @input="handleInput"
                />
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: ["form_id", "request_type_id", "formToFill"],
  model: {
    prop: "formToFill",
    event: "filled",
  },
  components: {},
  data() {
    return {
      localFormToFill: this.formToFill,
      validationErrors: {},
      selected: false,
      isLoading: false,
      actualFilled: 0,
      notPrefilled: 0,
    }
  },

  created() {
    this.isLoading = true;

    axios
      .get("/google_forms/get_form/" + this.form_id + ".json", {
        params: {
          request_type_id: this.request_type_id,
        },
      })
      .then((response) => {
        this.localFormToFill = response.data;
        this.handleInput()
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },

  methods: {
    handleInput() {
      this.$emit("filled", this.localFormToFill);
    },
    submitFormResponse() {
      this.$emit("filled", this.formToFill);
      this.$emit("close");
    },
  },
  watch: {
    localFormToFill: {
      handler() {
        this.$emit("filled", this.localFormToFill);
      },
      deep: true,
    },
    value: {
      handler() {
        this.localFormToFill = this.formToFill;
      },
      deep: true,
    },
  },
  mounted() {
    this.$emit("validationObserverRef", this.$refs.observer);
  }
};
</script>
