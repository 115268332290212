<template>
    <div>
        <section class="pt-6">
            <div class="columns is-centered">
                <div class="column is-three-quarters has-background-white">
                    <!-- Titulo de la sección -->
                    <div class="columns is-vcentered ">
                        <div class="column is-flex is-justify-content-flex-start">
                            <h1 class="subtitle is-1 has-text-secondary">
                                Administración de estudiantes

                            </h1>
                        </div>
                      <div class="column has-text-right">
                        <b-button tag ="a"
                                  href="/dashboard/user-management"
                                  type="is-link"
                                  icon-left="user"

                        >
                          Administración de usuarios
                        </b-button>
                      </div>
                    </div>
                    <!-- Separador -->
                    <hr class="solid">
                </div>
            </div>
            <div class="columns is-centered is-multiline ">
                <div class="column is centered is-three-quarters has-background-white">
                    <b-table
                            :data="users"
                            :paginated="isPaginated"
                            :per-page="perPage"
                            :current-page.sync="currentPage"
                            :pagination-position="paginationPosition"
                            :default-sort-direction="defaultSortDirection"
                            :sort-icon="sortIcon"
                            :sort-icon-size="sortIconSize"
                            default-sort="created_at"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            selectable



                    >
                        <b-table-column field="full_name" label="Nombre Completo" sortable
                                        searchable :custom-search="nameParcialSearch">
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por nombre"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template v-slot="props">
                            {{ props.row.full_name }}
                          </template>

                        </b-table-column>
                      <b-table-column field="" label="RUN" sortable  searchable :custom-search="runParcialSearch" centered>
                        <template slot="searchable" slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder="Buscar por RUN"
                              icon="search"
                              size="is-small"
                          />
                        </template>
                        <template v-slot="props">
                          {{ props.row.run.rut +"-" + props.row.run.dv }}
                        </template>

                      </b-table-column>
                        <b-table-column field="email" label="Correo Corporativo" sortable    searchable  :custom-search="emailParcialSearch">
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por email"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template v-slot="props">
                            {{ props.row.email }}
                          </template>
                        </b-table-column>
<!--                        <b-table-column field="created_at" label="Fecha de Creación" sortable searchable :custom-search="dateParcialSearch" centered>-->
<!--                          <template slot="searchable" slot-scope="props">-->
<!--                            <b-input-->
<!--                                v-model="props.filters[props.column.field]"-->
<!--                                placeholder="Buscar por fecha"-->
<!--                                icon="search"-->
<!--                                size="is-small"-->
<!--                            />-->
<!--                          </template>-->
<!--                          <template v-slot="props">-->
<!--                            {{ props.row.created_at }}-->
<!--                          </template>-->
<!--                        </b-table-column>-->
                        <b-table-column label="Cargos disponibles" v-slot="props" width="20%">
                          <div class="columns">
                            <div class="column">
                              <div v-if="props.row.internal_positions.length!==0">
                                <multiselect v-model="props.row.cargo_activo" :options="props.row.internal_positions"
                                             :custom-label="opt => props.row.internal_positions.find(x => x.id === opt.id) != null ? props.row.internal_positions.find(x => x.id === opt.id) .name:null "
                                             :multiple="false"
                                             placeholder="Seleccione un rol" selectLabel="Presione para seleccionar"
                                             selectedLabel="Seleccionado"
                                             deselectLabel="Presione para deseleccionar"
                                             track-by="id"
                                             label="name">
                                  <template v-slot:noOptions>
                                    No existen datos
                                  </template>
                                  <span slot="noResult">
                                            No se encontraron elementos.
                                          </span>
                                </multiselect>
                              </div>
                              <div v-else>
                                NO POSEE CARGOS
                              </div>
                            </div>
                          </div>
                        </b-table-column>
                      <b-table-column label="Acciones" v-slot="props" width="40%">
                        <b-button type="is-secondary" @click="openEditUserModal(props.row)"
                                  icon-left="edit">Editar usuario y roles
                        </b-button>

                        <b-button v-if="!props.row.disabled" class= "mr-1 is-danger is-light is-border-darkred" @click="disableUser(props.row.id)">
                          <b-icon            type="is-danger"
                                             icon="user-times"
                                             size="is-medium"
                                             class="mr-1"

                          >
                          </b-icon> Deshabilitar usuario
                        </b-button>
                        <b-button v-else class= "mr-1 is-success is-light is-border-darkgreen" @click="disableUser(props.row.id)">
                          <b-icon            type="is-success"
                                             icon="user-check"
                                             size="is-medium"
                                             class="mr-1"

                          >
                          </b-icon> Habilitar usuario
                        </b-button>


                      </b-table-column>
                      <template #empty>
                        <div class="has-text-centered">No se encontraron coincidencias de estudiantes</div>
                      </template>
                    </b-table>

                </div>
            </div>

          <b-modal  has-modal-card v-model="isModalActive"
                    trap-focus
                    :destroy-on-hide="true"
                    aria-role="dialog"
                    aria-modal
                    :can-cancel="true"
                    width="100%"
                    style="height:auto"
          >
            <template #default="props">
              <modal-edit-user v-if="roles.length>0" :usuario="userSelect"
                               :assignedInternalPositions="roles" @close ="closeModalEditInfo()"
                               style="width: 90vw"
              ></modal-edit-user>
            </template>
          </b-modal>
        </section>


        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>

import axios from "axios";
import moment from "moment/moment";
import ModalEditUser from './ModalEditUser.vue'
import ModalAssignCargo from './ModalAssignCargo.vue'
import {parcialSearch,openBuefyAlertDialog, openBuefyConfirmDialog} from "../../packs/utilities";

export default {
    name: "StudentManagement",
    data() {
        return {
            validationErrors: {},
            isPaginated: true,
            paginationPosition: 'bottom',
            defaultSortDirection: 'desc',
            sortIcon: 'arrow-up',
            sortIconSize: 'is-small',
            currentPage: 1,
            perPage: 10,
            isLoading: false,
            isModalActive: false,
            roles: [],
            corporative_email: "",
            selectedCargo: {
                id: "",
                name: ""
            },
            internalPositions: [],
            userSelect:
                {
                    full_name: "",
                    email: "",
                    created_at: ""
                },
            users: [],
            all_internal_positions: [],
            isModalInternalPositionActive: false,



        }
    },
   async  created() {
      document.title="Administración de estudiantes"
       await this.getUserInfo();
        await this.getInternalPositions()


    },
    components: {
      ModalEditUser,
    },
    methods: {
     async getInternalPositions(){
        this.isLoading=true;
       return await axios.get('/internal_positions.json')
            .then(response => {
              this.all_internal_positions = response.data

            })
            .catch(error => {
              //console.log(error);
              this.all_internal_positions = [];
            }).finally(()=> {
              this.isLoading = false
            }
        );
      },
      async getUserInfo(){
        this.isLoading=true
        return await axios.get('/dashboard/student-management.json')
            .then(response => {
              this.users = response.data
              this.users = this.users.map(user => {
                var newUser = user
                newUser["created_at"] = moment(newUser["created_at"], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
                /*
                if(newUser["cargo_activo"].id==null && newUser["internal_positions"].length!=0){
                  newUser["cargo_activo"]=newUser["internal_positions"][0]
                }*/
                return newUser;
              });
              this.userSelect = this.users[0]
            })
            .catch(error => {
              //console.log(error);
              this.user = [];
            }).finally(()=>{
              this.isLoading=false;
        });
      },
      openEditUserModal(user_selected) {
        this.isModalActive = true;
        this.userSelect = user_selected;
        this.getCargos(user_selected);
      },
      closeModalEditInfo(){
        this.isModalActive=false;
        this.getUserInfo();
        this.getCargos(this.userSelect);
      },
      getCargos(user) {

        axios.get(`/user_internal_positions/user/${user.id}.json`)
            .then(response => {
              this.roles = response.data
              this.roles = this.roles.map(role => {
                var newRoles = role
                newRoles["start_date"] =  newRoles["start_date"] !== null ? new Date(newRoles["start_date"]) : null;
                newRoles["end_date"] =  newRoles["end_date"] !== null ? new Date(newRoles["end_date"]) : null;
                return newRoles;
              });


            })
            .catch(error => {
              console.log(error);

            });

      },
      disableUser(userId,disabled) {

        let title = "Deshabilitar usuario"
        let message = "Esta acción deshabilitará al usuario."
        if (disabled) {
          title = "Habilitar usuario"
          message = "Esta acción habilitará al usuario."
        }
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          onConfirm: () =>{
            this.isLoading=true
            axios
                .post("/users/toggle-access/"+userId)
                .then(()=> {

                  openBuefyConfirmDialog(this,"Listo","Se ha cambiado la prioridad")
                  window.location.reload()
                      }
                ).catch( () => {
                    openBuefyAlertDialog(this,"Error","No se ha podido "+ title.toLowerCase())
                }
            ).finally(()=>{
              this.isLoading=false
            }
            );
          }
        });
      },
      idParcialSearch(row,input){
        return parcialSearch(row.id.toString(),input)
      },
      runParcialSearch(row,input){
         return parcialSearch(row.run.rut+"-"+row.run.dv,input)
      },
      nameParcialSearch(row,input){
        return parcialSearch(row.full_name,input)
      },
      emailParcialSearch(row,input){
        return parcialSearch(row.email,input)
      },
      dateParcialSearch(row,input){
        return parcialSearch(row.created_at,input)
      },

      formatDate(date) {
            if (date != null && date !== "" && date !== undefined) {
                var arreglo = date.split("-")
                return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0]
            } else {
                return ""
            }
        }
    }

}
</script>