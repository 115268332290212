<template>
  <div>
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>
    <div class="container box my-5">
      <div class="title">Firma digital simple</div>
      <div class="columns mb-3">
        <div class="column is-6 has-text-centered">
          <b-field extended label="Archivo PDF*">
            <b-upload v-model="file" drag-drop @input="onAddFile">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="file-pdf" size="is-large"></b-icon>
                </p>
                <p>Arrastra tu PDF o haz click para subir.</p>
              </div>
            </b-upload>
          </b-field>
          <div class="columns tags is-centered">
            <span v-if="file !== null" class="tag is-primary mt-1">
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="onDeleteFile"
              ></button>
            </span>
          </div>
        </div>
        <div class="column is-6 has-text-centered">
          <b-field extended label="Firma (formato PNG)*">
            <b-upload
              ref="fileUpload"
              v-model="signImage"
              drag-drop
              @input="onAddSign"
            >
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="file-signature" size="is-large"></b-icon>
                </p>
                <p>Arrastra tu firma o haz click para subir.</p>
              </div>
            </b-upload>
          </b-field>
          <div class="columns tags is-centered">
            <span v-if="signImage !== null" class="tag is-primary mt-1">
              {{ signImage.name }}
              <button
                class="delete is-small"
                type="button"
                @click="onDeleteImage"
              ></button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="src.length > 0 && sign !== null" class="container box mb-5">
      <div class="columns">
        <div class="column is-8">
          <div class="title">PDF</div>
          <div class="columns">
            <div class="column is-half has-text-right">
              <b-button
                icon-left="arrow-left"
                type="is-primary"
                @click="backPage"
                >Retroceder página</b-button
              >
            </div>
            <div class="column is-half has-text-left">
              <b-button
                icon-right="arrow-right"
                type="is-primary"
                @click="nextPage"
                >Siguiente página</b-button
              >
            </div>
          </div>
          <div class="columns has-text-centered">
            <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
          </div>
          <div class="pdf-container" @click="onClickVuePDF">
            <pdf
              :page="currentPage"
              :src="src.at(-1)"
              @num-pages="pageCount = $event"
            />
          </div>
          <div class="columns has-text-centered mt-4">
            <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
          </div>

          <div class="columns">
            <div class="column is-half has-text-right">
              <b-button
                icon-left="arrow-left"
                type="is-primary"
                @click="backPage"
                >Retroceder página</b-button
              >
            </div>
            <div class="column is-half has-text-left">
              <b-button
                icon-right="arrow-right"
                type="is-primary"
                @click="nextPage"
                >Siguiente página</b-button
              >
            </div>
          </div>
        </div>

        <div class="column is-4">
          <div class="title">Firma</div>
          <div class="columns">
            <div class="column is-12">
              <div class="content has-text-centered signature-container">
                <img :src="url" class="signature-preview" />
              </div>
            </div>
          </div>
          <div class="column is-12 is-vcentered">
            <b-field :label="'Tamaño de firma (' + targetHeight + 'px)'">
              <b-slider
                v-model="targetHeight"
                :max="500"
                :min="50"
                :step="20"
                :ticks="true"
                @change="onChangeImageSize"
              ></b-slider>
            </b-field>
          </div>
          <div>
            <b-button icon-left="undo" type="is-danger" @click="undoSign"
              >Deshacer última firma</b-button
            >
            <b-button icon-left="save" type="is-success" @click="download"
              >Descargar</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PDFDocument } from "pdf-lib";
import pdf from "vue-pdf";

export default {
  name: "SimpleDigitalSignatureView",
  components: {
    pdf,
  },
  data() {
    return {
      file: null,
      currentPage: 1,
      pageCount: null,
      fileBinary: null,
      src: [],
      isLoading: false,
      signImage: null,
      sign: null,
      targetHeight: 200,
      url: null,
      aspectRatio: null,
    };
  },
  computed: {},
  created() {
    window.addEventListener("keyup", this.handleKeyup, null);
  },
  methods: {
    async signFile(xPercent, yPercent) {
      if (this.sign === null) {
        this.$buefy.dialog.alert({
          message: "No se ha cargado una firma",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      if (this.file === null) {
        this.$buefy.dialog.alert({
          message: "No se ha cargado un archivo PDF",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      try {
        const pdfDoc = await PDFDocument.load(this.src.at(-1));
        const signImage = await pdfDoc.embedPng(this.sign);

        const pages = pdfDoc.getPages();
        const page = pages[this.currentPage - 1];

        const signDims = signImage.scale(1);

        const aspectRatio = signDims.height / signDims.width;
        page.drawImage(signImage, {
          x: xPercent * page.getWidth() - this.targetHeight / 2,
          y:
            yPercent * page.getHeight() - (this.targetHeight * aspectRatio) / 2,
          width: this.targetHeight,
          height: Math.ceil(this.targetHeight * aspectRatio),
        });

        return await pdfDoc.saveAsBase64({
          dataUri: true,
        });
      } catch (error) {
        this.$buefy.dialog.alert({
          message: "Este archivo no se puede firmar",
          type: "is-danger",
          hasIcon: true,
        });
        this.onDeleteFile();
      }
      return;
    },
    async onAddFile() {
      if (this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
        this.file = null;
        this.$buefy.dialog.alert({
          message: "Este archivo no tiene formato PDF",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      this.isLoading = true;
      const buffer = await this.file.arrayBuffer();
      this.fileBinary = buffer;
      this.src = [new Uint8Array(buffer)];
      this.isLoading = false;
    },
    async onClickVuePDF(e) {
      this.isLoading = true;
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      let xPosPercent = x / rect.width;
      let yPosPercent = 1 - y / rect.height;
      this.src.push(await this.signFile(xPosPercent, yPosPercent));
      this.isLoading = false;
    },
    undoSign() {
      if (this.src.length === 1)
        return this.$buefy.dialog.alert({
          message: "Ya se deshicieron todas las firmas.",
          type: "is-primary",
          hasIcon: true,
        });
      this.src.pop();
    },
    backPage() {
      if (this.pageCount === null) return;
      if (this.currentPage - 1 < 1) return;
      this.currentPage -= 1;
    },
    nextPage() {
      if (this.pageCount === null) return;
      if (this.currentPage + 1 > this.pageCount) return;
      this.currentPage += 1;
    },
    handleKeyup(e) {
      if (e.repeat) {
        return;
      }
      switch (e.keyCode) {
        case 37:
          this.backPage();
          break;
        case 39:
          this.nextPage();
          break;
      }
    },
    async onAddSign() {
      if (this.signImage.name.split(".").at(-1).toLowerCase() !== "png") {
        this.signImage = null;
        this.$buefy.dialog.alert({
          message: "Este archivo no tiene formato PNG",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      this.isLoading = true;

      const buffer = await this.signImage.arrayBuffer();
      this.sign = new Uint8Array(buffer);
      this.url = URL.createObjectURL(this.signImage);
      let img = new Image();
      let vm = this;
      img.onload = function () {
        vm.aspectRatio = this.height / this.width;
      };
      img.src = this.url;
      this.isLoading = false;
    },
    download() {
      if (this.src.length === 1)
        return this.$buefy.dialog.alert({
          message: "No se han realizado modificaciones",
          type: "is-warning",
          hasIcon: true,
        });
      const linkSource = this.src.at(-1);
      const downloadLink = document.createElement("a");
      const fileName = this.file.name;
      downloadLink.href = linkSource;
      downloadLink.download = fileName.split(".")[0] + "-firmado";
      downloadLink.click();
    },
    onDeleteFile() {
      this.src = [];
      this.file = null;
    },
    onDeleteImage() {
      this.signImage = null;
      this.sign = null;
    },
    onChangeImageSize() {
      var img = new Image();
      let vm = this;
      img.onload = function () {
        vm.aspectRatio = this.height / this.width;
      };
      img.src = this.url;
    },

    downloadModifiedPDF(pdfBytes) {
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace para descargar
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "documento-firmado.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Limpiar la URL para liberar recursos
      URL.revokeObjectURL(url);
    },
  },
};
</script>
<style scoped>
.pdf-container {
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 16px;
  overflow-y: auto;
}

.signature-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  overflow: auto;
  width: 100%;
}

.signature-preview {
  max-height: 300px;
  max-width: 100%;
  object-fit: contain;
}
</style>
