<template>
  <div
    class="container is-fullheight"
    style="padding: 2rem; background-color: #f2f2f2"
  >
    <div class="section" style="background-color: white; height: 100%">
      <!-- Titulo de la sección -->
      <div class="is-flex is-justify-content-flex-start is-align-items-center">
        <div class="is-flex is-flex-wrap-nowrap">
          <h1 class="subtitle is-1 has-text-secondary mb-0 mr-5">
            <b>Editar Tipo de Solicitud </b>
          </h1>
        </div>
      </div>
      <!-- Separador -->
      <hr class="solid" />
      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item, index) in errores_validacion" :key="index">
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(setStatesStep)">
          <div class="columns is-multiline my-2 px-5">
            <div class="column is-full">
              <b>Fecha de Creación:</b> {{ formatDate(requestType.created_at) }}
            </div>

            <!-- Input Nombre de la solicitud -->
            <div class="column">
              <label class="label">Nombre de la Solicitud *</label>
              <div class="control">
                <ValidationProvider
                  rules="required|max:250"
                  v-slot="{ errors }"
                >
                  <input
                    class="input"
                    v-model="requestType.real_name"
                    type="text"
                    placeholder="Debe Ingrese un nombre"
                  />
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- Input Descripción pequeña de la solicitud -->
            <div class="column is-full">
              <label class="label">Descripción pequeña de la Solicitud *</label>
              <div class="control">
                <ValidationProvider
                  rules="required|max:250"
                  v-slot="{ errors }"
                >
                  <b-input
                    v-model="requestType.description"
                    type="textarea"
                    placeholder="Debe Ingrese una descripión"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <!-- Input description larga de la solicitud -->
            <div class="column is-full">
              <label class="label"
                >Descripción larga (Bienvenida) de la Solicitud *</label
              >
              <div class="control">
                <ValidationProvider
                  rules="required|max:4000"
                  v-slot="{ errors }"
                >
                  <b-input
                    v-model="requestType.long_description"
                    type="textarea"
                    placeholder="Debe Ingrese una descripión"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- habilitar o desabiliatr-->
            <div class="column is-full">
              <label class="label"
                >Habilitar o deshabilitar tipo de solicitud *</label
              >
              <div class="block">
                <b-radio
                  v-model="requestType.disabled"
                  name="disabled"
                  :native-value="true"
                >
                  Deshabilitada
                </b-radio>
                <b-radio
                  v-model="requestType.disabled"
                  name="disabled"
                  :native-value="false"
                >
                  Habilitada
                </b-radio>
              </div>
            </div>

            <div class="my-4">
              <b-field label="¿Crear un código de verificación?">
                <div>
                  Si desea que el documento de respuesta final incluya un
                  código de verificación, por favor marque esta opción. Si el
                  documento no lo necesita, desmarquela.
                </div>
              </b-field>
              <div class="columns">
                <div class="column has-text-left">
                  <b-switch v-model="requestType.needs_token" type="is-primary">
                    ¿Crear código de verificación?
                  </b-switch>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <b-message
                v-if="alertInternalPosition !== ''"
                type="is-danger"
                has-icon
                aria-close-label="Close message"
              >
                {{ alertInternalPosition }}
              </b-message>
              <div class="control my-3">
                <label class="label mb-3"
                  >Seleccione los roles que podrán iniciar esta solicitud
                  *</label
                >

                <b-table
                  :data="
                    optionsInternalPositions == null
                      ? []
                      : optionsInternalPositions
                  "
                  :paginated="true"
                  :pagination-simple="false"
                  pagination-position="bottom"
                  :current-page="currentPageTable"
                  per-page="15"
                  :checked-rows.sync="selectedInternalPositions"
                  :checkable="true"
                  :bordered="false"
                  :striped="true"
                  :narrowed="false"
                  :hoverable="true"
                  :loading="isLoading == null ? true : false"
                  :focusable="false"
                  :mobile-cards="true"
                  @check-all="selectAll"
                >
                  <!-- Column Name -->
                  <b-table-column
                    field="name"
                    :visible="true"
                    label="Nombre"
                    width="400"
                    sortable
                    searchable
                  >
                    <template slot="searchable" slot-scope="props">
                      <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar por nombre"
                        icon="search"
                        size="is-small"
                      />
                    </template>
                    <template v-slot="props">
                      {{ props.row.name }}
                    </template>
                  </b-table-column>

                  <!-- Column Description -->
                  <b-table-column
                    field="descripción"
                    :visible="true"
                    width="550"
                    label="Descripción"
                  >
                    <template v-slot="props">
                      <span class="is-flex is-align-content-center">
                        {{ props.row.description }}
                      </span>
                    </template>
                  </b-table-column>

                  <template #empty>
                    <div class="has-text-centered">
                      No se encontraron solicitudes
                    </div>
                  </template>
                </b-table>
              </div>
            </div>

            <div class="column is-flex is-justify-content-center">
              <a
                :href="'/request_types/show-admin/' + requestType.id"
                class="button is-secondary mx-1"
                >Salir sin guardar</a
              >
              <a @click="updateRequestType()" class="button is-primary mx-1"
                >Guardar cambios</a
              >
            </div>
          </div>
        </form>
      </ValidationObserver>
      <!--
                                                            <div v-if="documentTemplates.length > 0" class="columns is-multiline my-2 px-5">
                                                                <div class="column is-full">
                                                                    <h1 class="subtitle is-2 has-text-secondary">
                                                                        Plantillas
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                            <div v-for="(template, template_index) in documentTemplates" :key="template_index">
                                                                <div class="card is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-1">
                                                                    <div class="is-flex card-content">
                                                                        {{ template.name }}
                                                                    </div>
                                                                    <div class="is-flex m-2">
                                                                        <button class="button is-primary mr-2 is-small is-inverted" @click="openModalShowDocument(template)">
                                                                            Ver Documento
                                                                            <i class="fas fa-eye ml-1"></i>
                                                                        </button>
                                                                      <button class="button is-secondary mr-2 is-small is-inverted" @click="downloadFile(template.id_document_drive)">
                                                                        Descargar
                                                                        <i class="fas fa-download ml-1"></i>
                                                                      </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                    
                                                            <div  class="columns is-multiline my-2 px-5">
                                                                <div class="column is-full">
                                                                    <h1 class="subtitle is-2 has-text-secondary">
                                                                        Estados
                                                                    </h1>
                                                                </div>
                                                            </div>
                                    
                                                            <div class=" my-2" v-for="(state, state_index) in states" :key="state_index">
                                                                <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                                                    <template #trigger="props">
                                                                        <div class="card-header" :class="state_index === 0 ? 'has-background-secondary' : ''" role="button" aria-controls="contentIdForA11y3">
                                                                            <p class="card-header-title" :class="state_index === 0 ? 'has-text-white' : ''">
                                                                                {{ state.real_name }}
                                                                                <a v-if="false" @click="editState(state_index)"><i class="fas fa-pencil-alt mx-3" :class="state_index === 0 ? 'has-text-white' : ''"></i> </a>
                                                                                <a v-if="false">
                                                                                    <span class="icon has-text-danger ">
                                                                                        <i class="fas fa-trash-alt is-danger mx-3">
                                                                                        </i>
                                                                                    </span>
                                                                                </a>
                                                                            </p>
                                                                            <a class="card-header-icon" :class="state_index === 0 ? 'has-text-white' : ''">
                                                                                <b-icon icon-pack="fas" :icon="props.open ? 'caret-down' : 'caret-up'">
                                                                                </b-icon>
                                                                            </a>
                                                                        </div>
                                                                    </template>
                                    
                                                                    <div class="card-content">
                                                                        <div class="content">
                                                                            {{ state.information }}
                                                                            <br>
                                                                            <br>
                                                                            <div class="is-flex mb-1">
                                                                                <p style="color:gray;font-size:14px">Duración: {{state.duration === 0 ? 'No tiene' : state.duration+' días'}}</p>
                                                                            </div>
                                                                            <div class="is-flex is-flex-wrap-wrap">
                                                                                <p class="my-auto" style="color:gray;font-size:14px">Revisores: </p>
                                                                                <b-tag class="m-1" v-for="(ip, index) in state.internal_positions" :key="index" type="is-primary" :closable="false" aria-close-label="Close tag" @close="() => {state.internal_positions.splice(index, 1)}">
                                                                                    {{ ip.name }}
                                                                                </b-tag>
                                                                            </div>
                                                                            <div class="is-flex is-flex-wrap-wrap">
                                                                                <p class="my-auto" style="color:gray;font-size:14px">Documentos: </p>
                                                                                <b-tag class="is-secondary m-1 " v-for="(ip, index) in state.documents" :key="index" type="is-primary" :closable="false" aria-close-label="Close tag" @close="() => {state.documents.splice(index, 1)}">
                                                                                    {{ ip.name }}
                                                                                </b-tag>
                                                                            </div>
                                                                            <div class="is-flex is-flex-wrap-wrap">
                                                                                <p class="my-auto" style="color:gray;font-size:14px">Formularios: </p>
                                                                                <b-tag class="is-info m-1" v-for="(ip, index) in state.google_forms" :key="index" type="is-primary" :closable="false" aria-close-label="Close tag" @close="() => {state.forms.splice(index, 1)}">
                                                                                    {{ ip.name }}
                                                                                </b-tag>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <footer v-if="false" class="card-footer is-flex is-flex-wrap-wrap">
                                                                        <a class="card-footer-item" @click="addItemsToState(state_index, 'Revisores')">Añadir Revisores</a>
                                                                        <a class="card-footer-item" @click="addItemsToState(state_index, 'Documentos')">Añadir Documentos</a>
                                                                        <a class="card-footer-item" @click="addItemsToState(state_index, 'Formularios')">Añadir Formularios</a>
                                                                    </footer>
                                                                </b-collapse>
                                                            </div>
                                    
                                                            <b-modal v-model="isModalShowDocumentActive" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal>
                                                                <template #default="props">
                                                                    <modal-show-document modal-header="Ver Documento" :documentId="currentDocumentIdDrive" @close="props.close">
                                                                    </modal-show-document>
                                                                </template>
                                                            </b-modal>
                                    
                                                            -->
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ModalShowDocument from "../Documents/ModalShowDocument";

export default {
  name: "EditRequestType",
  props: ["requestTypeId"],
  components: {
    ModalShowDocument,
  },
  data: () => {
    return {
      isLoading: false,
      requestType: {},
      documentTemplates: [],
      states: [],
      errores_validacion: [],
      currentDocumentIdDrive: "",
      isModalShowDocumentActive: false,
      selectedInternalPositions: [],
      optionsInternalPositions: [],
      currentPageTable: 1,
      alertInternalPosition: "",
    };
  },
  created() {
    document.title="Edición de solicitud"
    this.isLoading = true;
    this.getInternalPositions();

    return axios
      .get("/request_types/" + this.requestTypeId + ".json")
      .then((response) => {
        this.requestType = response.data.request_type;
        this.documentTemplates = response.data.templates;
        this.states = response.data.states;
        let internal_positions = response.data.internal_positions;
        internal_positions.forEach((ip) => {
          this.selectedInternalPositions.push(
            this.optionsInternalPositions.filter((e) => {
              return e.id == ip.id;
            })[0]
          );
        });
        this.isLoading = false;
      })
      .catch((e) => {
        console.log(e);
        this.isLoading = false;
      });
  },
  methods: {
    async getInternalPositions() {
      await axios
        .get("/internal_positions.json")
        .then((response) => {
          this.optionsInternalPositions = response.data.filter((x) => {
            return x.name != "SUPERADMIN";
          });
        })
        .catch((error) => {
          console.log(error);
          this.optionsInternalPositions = [];
        });
    },
    updateRequestType() {
      if (!this.checkInternalPositions()) {
        return;
      }

      this.isLoading = true;
      this.requestType.name = this.requestType.real_name
        .toUpperCase()
        .normalize("NFD");
      let url_update = "/request_types/" + this.requestType.id + ".json";
      this.formData = new FormData();
      this.formData.append("request_type", JSON.stringify(this.requestType));
      this.formData.append(
        "internal_positions",
        JSON.stringify(this.selectedInternalPositions)
      );
      return axios
        .patch(url_update, this.formData)
        .then((response) => {
          window.location.href =
            "/request_types/show-admin/" + this.requestType.id;
          this.isLoading = false;
        })
        .catch((error) => {
          this.errores_validacion = error;
          console.log(error);
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: "Error",
            message:
              "No es posible guardar los cambios, por favor revise los campos del formulario e intente nuevamente.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
        });
    },
    openModalShowDocument(document) {
      this.currentDocumentIdDrive = document.id_document_drive;
      this.isModalShowDocumentActive = true;
    },
    downloadFile(document_id) {
      this.isLoading = true;

      axios
        .request({
          url: "/documents/download_file/" + document_id,
          responseType: "blob",
        })
        .then((response) => {
          //var blobUrl = URL.createObjectURL(response.data);
          //window.open(blobUrl)

          let info = response.headers["content-disposition"];
          let name = info.split('"')[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        });
    },

    formatDate(date) {
      if (date != null && date !== "" && date !== undefined) {
        date = date.substring(0, 10);
        var arreglo = date.split("-");
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
      } else {
        return "";
      }
    },
    selectAll(rows) {
      if (rows.length > this.selectedInternalPositions.length) {
        this.$nextTick(() => {
          this.selectedInternalPositions = this.optionsInternalPositions;
        });
      } else {
        this.$nextTick(() => {
          this.selectedInternalPositions = [];
        });
      }
    },

    checkInternalPositions() {
      if (this.selectedInternalPositions.length === 0) {
        this.alertInternalPosition =
          "Debes seleccionar al menos un rol que pueda crear este tipo de solicitud.";
        return false;
      }
      if (this.selectedInternalPositions.length > 0) {
        this.alertInternalPosition = "";
        return true;
      }
    },
  },
};
</script>
