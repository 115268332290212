<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(saveState)">
                <!-- Box de Formulario Appointment -->
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head has-background-secondary">
                        <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                        <button
                                type="button"
                                class="delete"
                                @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body" ref="ModalCreateState">
                        <div class="columns">
                            <div class="column">
                                <div class="mb-2" v-if="Object.keys(validationErrors).length !== 0">
                                    <b-message type="is-danger" has-icon>
                                        <div v-for="(item,index) in validationErrors" :key="index">
                                            {{ item[0] }}
                                        </div>
                                    </b-message>
                                </div>
                            </div>
                        </div>

                        <iframe v-if="form_link !== ''" :src="form_link" width="640" height="2610" frameborder="0"
                                marginheight="0" marginwidth="0">Cargando…
                        </iframe>
                    </section>
                </div>

                <footer class="modal-card-foot actions has-background-secondary">
                    <div class="field is-grouped is-pulled-right">
                        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                    </div>
                </footer>
            </form>
        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalEditFormResponse",
    props: ["modalHeader", "google_form_id", "google_form_response_id"],
    components: {},
    data: function () {
        return {
            responseData: [],
            //---------------------------------
            validationErrors: {},
            isLoading: false,
            form_link: '',
        }
    },

    created() {
        this.isLoading = true;
        axios
            .get("/google_form_responses/edit_response/" + this.google_form_id + "/" + this.google_form_response_id + ".json")
            .then(response => {
                this.form_link = response.data + "&embedded=true";
                this.isLoading = false;
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'No puedes editar este formulario.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                });
                this.$emit('close')
                console.log("No se pudieron cargar los formularios disponibles para responder.")
                this.isLoading = false;
            });
    },

    methods: {},
}
</script>