<template>
    <div class="container is-fluid" style="padding: 2rem;background-color: #F2F2F2;">
        <div class="section pt-0" style="background-color: 525351; height: 100%" ref="createRequestType">

            <!-- Titulo de la sección -->
            <div class="is-flex is-justify-content-center">
                <h1 class="subtitle is-1 has-text-secondary">
                    Manual de Uso
                </h1>
            </div>

            <b-tabs v-model="tab_index" expanded>
                <!-- Tab de solicitudes -->
                <b-tab-item label="Solicitudes" icon="file-invoice">
                    <div class="container px-5 py-0">
                        <div class="columns is-multiline">
                            <div class="column is-3 py-0">
                                <b-sidebar position="static" type="is-light" open>
                                    <div class="p-1 pt-4">
                                        <div class="block mt-5">
                                            <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                                        </div>
                                        <b-menu class="is-custom-mobile">
                                            <b-menu-list>
                                                <b-menu-item :active="tutorial === 'create_request' ? true : false"
                                                             @click="tutorial = 'create_request'"
                                                             label="¿Cómo crear una solicitud?"></b-menu-item>
                                                <b-menu-item :active="tutorial === 'view_own_request' ? true : false"
                                                             @click="tutorial = 'view_own_request'"
                                                             label="¿Cómo ver las solicitudes que he creado?"></b-menu-item>
                                                <b-menu-item :active="tutorial === 'show_request' ? true : false"
                                                             @click="tutorial = 'show_request'"
                                                             label="¿Cómo ver una solicitud específica?"></b-menu-item>


                                            </b-menu-list>
                                        </b-menu>
                                    </div>
                                </b-sidebar>

                            </div>

                            <div v-if="tutorial === 'create_request'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Creación de Solicitud
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite crear solicitudes</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">Seleccionar la opción "Crear Solicitud", la cual se puede
                                        seleccionar de dos formas:
                                    </p>
                                    <ul class=" ml-4">
                                        <li>1. Abriendo el menú lateral y seleccionando la opción</li>
                                        <img border="2" class="m-2"
                                             src="tutorial_images/create_request/boton_abrir_sidebar.png"
                                             style="width:35%">
                                        <img border="2" class="m-2"
                                             src="tutorial_images/create_request/boton_sidebar_crear_solicitud.png"
                                             style="width:35%">
                                        <li>2. Directamente del menú inicial</li>
                                        <img border="2" class="m-2"
                                             src="tutorial_images/create_request/boton_menu_crear_solicitud.png"
                                             style="width:80%">
                                    </ul>

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Escoger el tipo de solicitud a crear
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request/seleccionar_tipo_solicitud.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 3
                                    </h2>
                                    <p class="my-3 ">Agregar información adicional a la solicitud y luego presionar el
                                        botón siguiente
                                    </p>
                                    <img border="2" class="m-2" src="tutorial_images/create_request/paso1_solicitud.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 4
                                    </h2>
                                    <p class="my-3 ">Presionar botón responder formulario
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request/boton_completar_formulario.png"
                                         style="width:80%">
                                    <p class="my-3 ">Responder el/los formulario(s) asociado(s) y luego presionar
                                        guardar
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request/completar_formulario.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 5
                                    </h2>
                                    <p class="my-3 ">Presionar botón crear solicitud
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request/boton_crear_solicitud.png"
                                         style="width:20%">
                                </div>
                            </div>

                            <!-- search_request -->
                            <div v-if="tutorial === 'view_own_request'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Ver mis solicitudes creadas
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema agrupa las solicitudes creadas por uno mismo, para facilitar su
                                            acceso</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">Seleccionar el botón "Mis solicitudes", ubicado arriba a la derecha
                                        de la página, al lado de su usuario.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/boton_mis_solicitudes.png">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Al cargar, usted podrá aplicar los filtros que considere necesarios
                                        para acotar su búsqueda.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/filtros_mis_solicitudes.png"
                                         style="width:80%">

                                    <p class="my-3 ">Así podrá visualizar las solicitudes que usted haya creado, y podrá
                                        acceder a estas utilizando los botones <i size="is-medium"
                                                                                  class="fas fa-eye"></i> para ver, y <i
                                                size="is-medium" class="fas fa-edit"></i> para revisar.
                                    </p>
                                </div>
                            </div>

                            <!-- show request -->
                            <div v-if="tutorial === 'show_request'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Ver solicitud específica
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite acceder al detalle y avance de las solciitudes que haz
                                            creado. Para ello se tienen 2 opciones:</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Opción 1
                                    </h2>
                                    <p class="my-3 ">Buscar la solicitud por su código (
                                        <button class="button is-ghost  p-0 m-0 pb-3 pl-1"
                                                @click="tutorial3='search_request';tab_index=2">Tutorial para buscar
                                            solicitud
                                        </button>
                                        )
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/search_request/vista_buscar_solicitud.png">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Opción 2
                                    </h2>
                                    <p class="my-3 ">Ir a "Mis Solicitudes" y seleccionar alguna de las que se muestran
                                        (
                                        <button class="button is-ghost p-0 m-0 pb-3 pl-1"
                                                @click="tutorial='view_own_request'">Tutorial para ver "Mis Solicitudes"
                                        </button>
                                        )
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/mis_solicitudes.png" style="width:80%">

                                    <p class="my-3 "> Finalmente, la vista que se debería observar es la siguiente:
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_request/show_request_applicant.png"
                                         style="width:80%">
                                </div>
                            </div>
                        </div>

                    </div>
                </b-tab-item>

                <!-- Tab de Revisión -->
                <b-tab-item v-if="isReviewer" label="Revisión de solicitud" icon="edit">
                    <div class="container px-5 py-0">
                        <div class="columns is-multiline">
                            <div class="column is-3 py-0">
                                <b-sidebar position="static" type="is-light" open>
                                    <div class="p-1 pt-5">
                                        <div class="block mt-5">
                                            <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                                        </div>
                                        <b-menu class="is-custom-mobile">
                                            <b-menu-list>
                                                <b-menu-item active @click="tutorial2 = 'view_request'"
                                                             label="¿Cómo ver todas las solicitudes que se hayan creado?"></b-menu-item>
                                                <b-menu-item @click="tutorial2 = 'review_request'"
                                                             label="¿Cómo revisar una solicitud específica?"></b-menu-item>
                                                <b-menu-item @click="tutorial2 = 'start_stop_request'"
                                                             label="¿Cómo detener y reanudar una solicitud?"></b-menu-item>

                                            </b-menu-list>
                                        </b-menu>
                                    </div>
                                </b-sidebar>
                            </div>
                            <div v-if="tutorial2 === 'view_request'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Ver todas las solicitudes creadas
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>Además de permitir buscar solicitudes específicas y ver las solicitudes
                                            propias, el sistema permite ver las solicitudes a las que usted tiene
                                            permiso para acceder.</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">Abrir el menú lateral y seleccionar la opción "Ver todas las
                                        solicitudes".
                                    </p>
                                    <img border="2" class="m-2" src="tutorial_images/view_request/ver_solicitudes.png">
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Dentro de la página de solicitudes, ubique la solicitud que desee
                                        ver, para lo cual se puede apoyar mediante los filtros disponibles.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_request/filtros_ver_solicitudes.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 3
                                    </h2>
                                    <p class="my-3 ">Una vez encuentre la solicitud que estaba buscando, acceda a esta
                                        presionando el botón <i size="is-medium" class="fas fa-eye"></i>.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_request/seleccionar_solicitud_ver.png"
                                         style="width:80%">
                                </div>
                            </div>

                            <div v-if="tutorial2 === 'review_request'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Revisar solicitud específica
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite revisar solicitudes, mientras usted tenga acceso a
                                            estas.</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">Abrir el menú lateral y seleccionar la opción "Ver todas las
                                        solicitudes".
                                    </p>
                                    <img border="2" class="m-2" src="tutorial_images/view_request/ver_solicitudes.png">
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Dentro de la página de solicitudes, ubique la solicitud que desee
                                        revisar, para lo cual se puede apoyar mediante los filtros disponibles.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_request/filtros_ver_solicitudes.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 3
                                    </h2>
                                    <p class="my-3 ">Una vez encuentre la solicitud que estaba buscando, y esta se
                                        encuentre disponible para revisar, acceda a esta presionando el botón <i
                                                size="is-medium" class="fas fa-edit"></i>.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/review_request/seleccionar_solicitud_revisar.png"
                                         style="width:80%">
                                </div>
                            </div>

                            <div v-if="tutorial2 === 'start_stop_request'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Detener y reanudar solicitudes
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite detener solicitudes, para representar que por motivos
                                            externos, esta solicitud no puede avanzar temporalmente.</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Prerrequisitos
                                    </h2>
                                    <div class="is-flex is-flex-wrap-wrap mb-3">
                                        <p class="my-3 ">Acceder a una solicitud que </p>
                                        <a @click="tutorial2 = 'review_request'" class="button is-ghost pb-0 mb-0 pl-1">usted
                                            esté revisando.</a>
                                    </div>

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Detener solicitud
                                    </h2>
                                    <p class="my-3 ">Dentro de la revisión de una solicitud, baje hasta el final de la
                                        página y seleccione el botón "Detener solicitud".
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/start_stop_request/detener_solicitud.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Reanudar solicitud
                                    </h2>
                                    <p class="my-3 ">Una vez considere que la solicitud se puede continuar normalmente,
                                        el proceso de reanudación es idéntico al de detención, requiriendo acceder a una
                                        solicitud detenida y presionando el botón "Reanudar paso".
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/start_stop_request/reanudar_solicitud.png"
                                         style="width:80%">
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab-item>

                <b-tab-item label="Filtros y búsqueda" icon="search">
                    <div class="container px-5 py-0">
                        <div class="columns is-multiline">
                            <div class="column is-3 py-0">
                                <b-sidebar position="static" type="is-light" open>
                                    <div class="p-1 pt-4">
                                        <div class="block mt-5">
                                            <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                                        </div>
                                        <b-menu class="is-custom-mobile">
                                            <b-menu-list>
                                                <b-menu-item :active="tutorial3 === 'filter_requests' ? true : false"
                                                             @click="tutorial3 = 'filter_requests'"
                                                             label="¿Cómo filtrar solicitudes?"></b-menu-item>
                                                <b-menu-item :active="tutorial3 === 'search_request' ? true : false"
                                                             @click="tutorial3 = 'search_request'"
                                                             label="¿Cómo buscar una solicitud?"></b-menu-item>
                                            </b-menu-list>
                                        </b-menu>
                                    </div>
                                </b-sidebar>
                            </div>


                            <div v-if="tutorial3 === 'search_request'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Búsqueda de Solicitud
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite buscar solicitudes creadas</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">Seleccionar la opción "Crear Solicitud", la cual se puede
                                        seleccionar de dos formas:
                                    </p>
                                    <ul class=" ml-4">
                                        <li>1. Abriendo el menú lateral y seleccionando la opción</li>
                                        <img border="2" class="m-2"
                                             src="tutorial_images/create_request/boton_abrir_sidebar.png"
                                             style="width:35%">
                                        <img border="2" class="m-2"
                                             src="tutorial_images/search_request/boton_sidebar_buscar_solicitud.png"
                                             style="width:35%">
                                        <li>2. Directamente del menú inicial</li>
                                        <img border="2" class="m-2"
                                             src="tutorial_images/search_request/boton_menu_buscar_solicitud.png"
                                             style="width:80%">
                                    </ul>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Escribir el código de la solicitud y luego presionar buscar
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/search_request/vista_buscar_solicitud.png"
                                         style="width:80%">
                                </div>
                            </div>

                            <div v-if="tutorial3 === 'filter_requests'" class="column is-9 px-5">
                                <div class="section p-5 pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Filtrar Solicitudes
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite filtrar solicitudes por fecha y progreso de la
                                            solicitud</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">Seleccionar la opción "Mis Solicitudes" ubicado en el navbar
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/boton_mis_solicitudes.png">
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/mis_solicitudes.png" style="width:80%">
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">En la parte superior seleccionar el filtro que se desea aplicar:
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/filtro_progreso.png" style="width:25%">
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/filtro_fecha_desde.png"
                                         style="width:25%">
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/filtro_fecha_hasta.png"
                                         style="width:25%">
                                    <p class="my-3 ">Es importante mencionar que los filtros se pueden combinar de
                                        cualquier forma
                                    </p>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 3
                                    </h2>
                                    <p class="my-3 ">Presionar el botón "Aplicar Filtros" y se mostrarán en la misma
                                        vista las solicitudes que coincidan con la búsqueda.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/view_own_request/boton_aplicar_filtros.png"
                                         style="width:25%">
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab-item>

                <!-- Tab de Administración -->
                <b-tab-item v-if="isAdmin" label="Administración" icon="user">
                    <div class="container px-5 py-0">
                        <div class="columns is-multiline">
                            <div class="column is-3 py-0">
                                <b-sidebar position="static" type="is-light" open>
                                    <div class="p-1 pt-4">
                                        <div class="block mt-5">
                                            <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                                        </div>
                                        <b-menu class="is-custom-mobile">
                                            <b-menu-list>
                                                <b-menu-item active @click="tutorial4 = 'create_request_type'"
                                                             label="¿Cómo crear un nuevo tipo de solicitud?"></b-menu-item>
                                                <b-menu-item @click="tutorial4 = 'show_request_type'"
                                                             label="¿Como ver un tipo de solicitud específico?"></b-menu-item>
                                                <b-menu-item @click="tutorial4 = 'index_request_type'"
                                                             label="¿Como ver todos los tipos de solicitudes disponibles?"></b-menu-item>
                                                <b-menu-item @click="tutorial4 = 'disable_request_type'"
                                                             label="¿Cómo deshabilitar un tipo de solicitud?"></b-menu-item>


                                            </b-menu-list>
                                        </b-menu>
                                    </div>
                                </b-sidebar>
                            </div>

                            <div v-if="tutorial4 === 'create_request_type'" class="column is-9 px-5">
                                <div class="section pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Creación Tipo de Solicitud
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite crear tipos de solicitudes</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">1. Abrir el menú lateral y seleccionar el botón Administración</p>
                                    <p class="my-3 ">2. Luego presionar el botón "Crear Tipo de Solicitud"</p>

                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_sidebar_administracion.png"
                                         style="width:30%">
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_sidebar_crear_tipo_solicitud.png"
                                         style="width:30%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Escribir el nombre del tipo de solicitud, una descripción general y
                                        asociar documentos (OPCIONAL) que se podrían descargar, como por ejemplo,
                                        plantillas, formularios en formato word, manuales, etc.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/paso1_crear_tipo_solicitud.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 3
                                    </h2>
                                    <p class="my-3 "> En este paso se deben definir los pasos de la solicitud y los
                                        encargados, documentos y fomularios asociados a cada uno.</p>
                                    <p class="mb-3">Entonces primero se debe presionar el botón "Añadir Encargados"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_anadir_encargados.png"
                                         style="width:80%">

                                    <p class="mb-3">Se abrirá un modal en donde se debe seleccionar al encargado de
                                        revisar el paso y luego presionar "Guardar"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/modal_anadir_encargados.png"
                                         style="width:80%">

                                    <p class="mb-3">Luego se deben definir que documentos se van a requerir por parte
                                        del solicitante (persona que inicie la solicitud) en este paso específico</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_anadir_documentos.png"
                                         style="width:80%">

                                    <p class="mb-3">Esto abrirá un modal en donde se debe presionar el botón "Añadir
                                        Documento" para agregar documentos (PUEDE SER MAS DE UNO)</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/modal_anadir_documentos_1.png"
                                         style="width:80%">

                                    <p class="mb-3">Luego se debe escribir el nombre del documento y una descripción de
                                        lo que se requiere. Luego presionar "Guardar"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/modal_anadir_documento_2.png"
                                         style="width:80%">

                                    <p class="mb-3">Luego se deben añadir los formularios que se van a requerir por
                                        parte del solicitante en este paso específico</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_anadir_formularios.png"
                                         style="width:80%">

                                    <p class="mb-3">Esto abrirá un modal en donde se debe presionar el botón "Añadir
                                        Formulario" para agregar formularios (PUEDE SER MAS DE UNO)</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/modal_anadir_formularios_1.png"
                                         style="width:80%">

                                    <p class="mb-3">Luego se debe escribir el nombre del formulario y el link del Google
                                        Forms que contiene el formulario</p>
                                    <p class="mb-3">Este link debe ser el link del modo edición del formulario.
                                        Ejemplo: </p>
                                    <p class="mb-3"><strong>https://docs.google.com/forms/d/1L63u_qOfawCw8hBM87WgXKOv7E6kApABSsyuQR11YFQ</strong>
                                    </p>
                                    <p class="bm-3">Luego se debe presionar "Guardar Cambios"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/modal_anadir_formularios_2.png"
                                         style="width:80%">

                                    <p class="mb-3">La solicitud debe tener más de un paso por lo que se debe
                                        presionar el botón "Crear paso" para crear un nuevo estado</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_crear_otro_estado.png"
                                         style="width:80%">

                                    <p class="mb-3">Luego, se deben completar los campos indicados para crear un nuevo
                                        estado</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/modal_crear_estado.png"
                                         style="width:80%">

                                    <p class="mb-3">Y se deben repetir los pasos anteriores para añadir encargado,
                                        documentos y formularios al nuevo estado</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/nuevo_estado_creado.png"
                                         style="width:80%">

                                    <p class="mb-3">Una vez que se tengan todos los pasos deseados se debe presionar
                                        "Siguiente"</p>

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 4
                                    </h2>
                                    <p class="mb-3">En este paso se debe definir el flujo de la solicitud (orden de los
                                        estados). Para esto se tienen dos opciones:</p>
                                    <ul class=" ml-4">
                                        <li>1. Arrastrando los pasos al lado derecho en el orden deseado</li>
                                        <li>2. Presionar el botón "Mantener el mismo orden" el cual trasladará los
                                            pasos automaticamente en el mismo orden en el que fueron creados
                                        </li>
                                    </ul>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/ordenar_estados.png"
                                         style="width:80%">
                                    <p class="mb-3">Una vez que se tienen los pasos ordenados se debe presionar
                                        "Siguiente"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/estados_ordenados.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 5
                                    </h2>
                                    <p class="mb-3">En este paso se deben seleccionar los roles que tendrán permisos
                                        para crear este tipo de solicitud. Para esto se deben marcar
                                        las casillas correspondientes a los roles que se desean.
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/seleccionar_quien_puede_crear.png"
                                         style="width:80%">
                                    <p class="mb-3">Por último, presionar "Crear Solicitud"
                                    </p>
                                    <p class="mb-3">Cuando la solicitud haya sido creada con éxito se mostrará el
                                        siguiente mensaje</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/modal_solicitud_creada.png"
                                         style="width:80%">
                                </div>
                            </div>

                            <div v-if="tutorial4 === 'index_request_type'" class="column is-9 px-5">
                                <div class="section pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Listar tipos de solicitudes
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite ver los tipos de solicitudes creados</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">1. Abrir el menú lateral y seleccionar el botón Administración</p>
                                    <p class="my-3 ">2. Luego presionar el botón "Ver Tipos de Solicitudes"</p>

                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_sidebar_administracion.png"
                                         style="width:30%">
                                    <img border="2" class="m-2"
                                         src="tutorial_images/list_request_types/boton_sidebar_listar_tipos_solicitudes.png"
                                         style="width:30%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Luego ya se pueden ver todos los tipos de solicitudes creados
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/list_request_types/listar_tipos_solicitudes.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso Opcional
                                    </h2>
                                    <p class="mb-3">En caso de requerirse se puede ver la carpeta de drive asociada al
                                        tipo de solicitud, presionando el botón "Abrir en Drive"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/list_request_types/boton_abrir_en_drive.png"
                                         style="width:80%">

                                </div>
                            </div>

                            <div v-if="tutorial4 === 'show_request_type'" class="column is-9 px-5">
                                <div class="section pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Ver un tipo de solicitud específico
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite ver un tipo de solicitud específica que se haya creado</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">1. Abrir el menú lateral y seleccionar el botón Administración</p>
                                    <p class="my-3 ">2. Luego presionar el botón "Ver Tipos de Solicitudes"</p>

                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_sidebar_administracion.png"
                                         style="width:30%">
                                    <img border="2" class="m-2"
                                         src="tutorial_images/list_request_types/boton_sidebar_listar_tipos_solicitudes.png"
                                         style="width:30%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3">Se abrirá una vista que muestra todos los tipos de solicitudes.
                                        Luego se debe presionar el botón con icono de ojo para ver alguna de las
                                        solicitudes que se muestra.</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/show_request_type/boton_ver_request_type.png"
                                         style="width:80%">

                                    <p class="my-3">Finalmente, se abrirá una nueva pestaña con el tipo de solicitud
                                        seleccionado. </p>
                                    <p class="my-3">En esta vista se muestran información del tipo de solicitud, los
                                        recursos descargables asociados (plantillas) y
                                        los estados junto con el encargado, documentos y formularios asociados a cada
                                        estado. </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/show_request_type/ver_request_type.png" style="width:80%">

                                </div>
                            </div>

                            <div v-if="tutorial4 === 'disable_request_type'" class="column is-9 px-5">
                                <div class="section pt-0">
                                    <h1 class="subtitle is-2 has-text-primary">
                                        Deshabilitar un tipo de solicitud
                                    </h1>
                                    <div class="mb-3 ">
                                        <p>El sistema permite deshabilitar tipos de solicitudes creadas</p>
                                    </div>
                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 1
                                    </h2>
                                    <p class="my-3 ">1. Abrir el menú lateral y seleccionar el botón Administración</p>
                                    <p class="my-3 ">2. Luego presionar el botón "Ver Tipos de Solicitudes"</p>

                                    <img border="2" class="m-2"
                                         src="tutorial_images/create_request_type/boton_sidebar_administracion.png"
                                         style="width:30%">
                                    <img border="2" class="m-2"
                                         src="tutorial_images/list_request_types/boton_sidebar_listar_tipos_solicitudes.png"
                                         style="width:30%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 2
                                    </h2>
                                    <p class="my-3 ">Luego se abrirá una vista donde se muestran todos los tipos de
                                        solciitudes existentes. Se debe escoger el que se quiere deshabilitar y
                                        presionar el botón que tiene un icono de lapiz (editar)
                                    </p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/disable_request_type/boton_editar_request_type.png"
                                         style="width:80%">

                                    <h2 class="subtitle is-3 mt-5 mb-2">
                                        Paso 3
                                    </h2>
                                    <p class="my-3">Se abrirá una vista de edición. En esta vista se debe seleccionar el
                                        botón "Deshabilitada"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/disable_request_type/boton_deshabilitar_request_type.png"
                                         style="width:80%">

                                    <p class="my-3">Y por último presionar el botón "Guardar Cambios"</p>
                                    <img border="2" class="m-2"
                                         src="tutorial_images/disable_request_type/boton_guardar_cambios.png"
                                         style="width:80%">

                                </div>
                            </div>
                        </div>
                    </div>

                </b-tab-item>

            </b-tabs>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: "TutorialCreateRequest",
    props: ['isAdmin', 'isReviewer'],
    data: () => {
        return {

            isLoading: false,
            tutorial: "create_request",
            tutorial2: "view_request",
            tutorial3: "filter_requests",
            tutorial4: "create_request_type",
            tab_index: 0
        }
    },
    created() {
      document.title="Tutoriales"
        this.isLoading = true;

        return;
        axios.get("/requests/request_types.json").then(
            response => {
                this.requestTypes = response.data.request_types;
                this.isLoading = false;
            }
        ).catch(
            error => {
                console.log(error);
                this.isLoading = false;
            }
        );
    },
    methods: {}
}
</script>

<style>
.b-sidebar .sidebar-content.is-absolute,
.b-sidebar .sidebar-content.is-static {
    width: 100%;
    background-color: #f2f2f2;
    box-shadow: none;
}
</style>
