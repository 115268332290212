<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(saveEditedState)">
                <!-- Box de Formulario Appointment -->
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head has-background-secondary ">
                        <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                        <button
                                type="button"
                                class="delete"
                                @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body" ref="ModalCreateState">
                        <div class="columns">
                            <div class="column">
                                <div class="mb-2" v-if="Object.keys(validationErrors).length !== 0">
                                    <b-message type="is-danger" has-icon>
                                        <div v-for="(item,index) in validationErrors" :key="index">
                                            {{ item[0] }}
                                        </div>
                                    </b-message>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Nombre del paso *</label>
                            <ValidationProvider rules="required|max:250" v-slot="{errors}">
                                <input class="input" type="text" v-model="stateToEdit.real_name"
                                       placeholder="Ingrese un nombre">
                                <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="field mt-4">
                            <label class="label">Descripción *</label>
                            <div class="control">
                                <ValidationProvider rules="required|max:250" v-slot="{errors}">
                                    <textarea class="textarea" v-model="stateToEdit.information"
                                              placeholder="Ingrese una descripcion"></textarea>
                                    <span class="validation-alert">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="field mt-4">
                            <label class="label">¿El paso tiene duración específica?</label>
                            <b-radio v-model="haveDuration"
                                     native-value="no">
                                No
                            </b-radio>
                            <b-radio v-model="haveDuration"
                                     native-value="si">
                                Si
                            </b-radio>
                        </div>
                        <div v-if="haveDuration === 'si'" class="field mt-4">
                            <label class="label">Ingrese la duración (en días) *</label>
                            <ValidationProvider rules="required|min_value:1|max_value:365" v-slot="{errors}">
                                <b-input placeholder="Number"
                                         type="number"
                                         min="1"
                                         max="200"
                                         v-model="stateToEdit.duration">
                                </b-input>
                                <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </section>
                </div>

                <footer class="modal-card-foot actions has-background-secondary">
                    <div class="field is-grouped is-pulled-right">
                        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                        <button class="button is-primary" type="submit">Guardar</button>
                    </div>
                </footer>
            </form>
        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalEditState",
    props: ["modalHeader", "stateToEdit"],
    emits: ['newstate'],
    components: {},
    data: function () {
        return {
            validationErrors: {},
            isLoading: false,
            haveDuration: "no",
            stateDuration: 1,
            stateName: "",
            stateDescription: ""
        }
    },

    created() {
        this.isLoading = true;
        if (this.stateToEdit.duration !== 0) {
            this.haveDuration = "si";
        }
        this.isLoading = false;
    },

    methods: {
        saveEditedState() {
            this.$emit('close');
        },
    }
}
</script>