<template>
    <section>
        <div class="container">
            <div class="box">
                <div class="columns">
                    <div class="column">
                        <h3 class="is-size-3 has-text-weight-bold">Auditoría SAS</h3>
                    </div>
                </div>
                <b-tabs @input="changeTab" type="is-boxed" :destroy-on-hide="true" v-model="currentTab"
                        position="is-centered" expanded>
                    <b-tab-item v-for="item in items"
                                :value="item.content"
                                v-bind:key="item.content"
                                :label="item.tab"
                                style="max-height: 100%">
                    </b-tab-item>
                </b-tabs>
                <!-- COMPONENTE AQUI -->

                <component v-bind:is="currentTabComponent" :person-id="personId"></component>

                <!-- FIN COMPONENTE -->
            </div>
        </div>
    </section>
</template>
<script>
import RequestAudit from "./RequestAudit"
import UserAudit from "./UserAudit"
import GeneralAudit from "./GeneralAudit";

export default {
    name: "audit",
    props: [
        'personId',
    ],
    data() {
        return {
            currentTab: 'GeneralAudit',
            items: [
                {tab: 'General', content: 'GeneralAudit'},
                {tab: 'Solicitudes', content: 'RequestAudit'},
                {tab: 'Usuarios', content: 'UserAudit'},
            ]
        }
    },
  created() {
    document.title = "Auditoría SAS"
  },
    components: {
        RequestAudit,
        UserAudit,
        GeneralAudit
    },
    methods: {
        changeTab: function (value) {
            this.currentTab = value
        },
        selectTab(tab) {
            this.currentTab = tab
        }
    },
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    },
}
</script>