<template>
  <div class="container">
    <section style="width: auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addItems)">
          <!-- Box de Formulario Appointment -->
          <div class="modal-card" style="width: auto; height: auto">
            <header class="modal-card-head has-background-secondary">
              <p class="modal-card-title has-text-white">Documentos internos</p>
              <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body" ref="ModalAddInternalPositions">
              <div class="control">
                <div
                  class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap mb-4"
                >
                  <div class="field is-flex mb-0 mt-2">
                    <h1 class="title is-5">
                      Documentos internos asociados a la observación
                      {{ observationCode }}
                    </h1>
                  </div>
                </div>
                <div class="subtitle is-6">
                  Observación realizada por <b>{{ observationBy }}</b> el
                  {{ dateToStringWithHour(date) }}
                </div>
                <b-table
                  :data="documents"
                  :bordered="true"
                  hoverable
                  :mobile-cards="true"
                >
                  <b-table-column
                    field="name"
                    label="Nombre del Documento"
                    v-slot="props"
                  >
                    {{ props.row.name }}
                  </b-table-column>
                  <!--
                  <b-table-column
                    field="name"
                    label="Descripción del Documento"
                    v-slot="props"
                  >
                    <b-field>
                      <b-input
                        v-model="props.row.description"
                        type="textarea"
                        aria-placeholder=""
                        readonly=""
                        disabled
                      ></b-input>
                    </b-field>
                  </b-table-column>
                   -->
                  <b-table-column
                    field="actions"
                    label="Acciones"
                    centered
                    v-slot="props"
                  >
                    <b-button
                      type="is-primary "
                      icon-left="eye"
                      @click="
                        openModalShowDocument(props.row.id_document_drive)
                      "
                    ></b-button>
                    <b-button
                      type="is-secondary "
                      icon-left="download"
                      @click="downloadFile(props.row.id_document_drive)"
                    ></b-button>
                  </b-table-column>

                  <template #empty>
                    <div class="has-text-centered">
                      Aún no hay documentos subidos
                    </div>
                  </template>
                </b-table>
              </div>
            </section>
            <div class="modal-card-foot has-background-secondary">
              <div class="field is-grouped is-pulled-right">
                <button class="button" type="button" @click="$emit('close')">
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </section>
    <b-modal
      v-model="isModalShowDocumentActive"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="70%"
    >
      <template #default="props">
        <preview-program-modal :docId="src" @close="props.close">
        </preview-program-modal>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import ModalShowDocument from "../../Documents/ModalShowDocument.vue";
import PreviewProgramModal from "../../Documents/PreviewProgramModal.vue";
import { dateToStringWithHour } from "../../../packs/utilities";

export default {
  name: "ModalViewAllDocs",
  props: ["request_id", "internal_observation_id", "observationBy", "date", "observationCode"],
  components: { PreviewProgramModal, ModalShowDocument },
  data: function () {
    return {
      isLoading: false,
      documents: [],
      isModalShowDocumentActive: false,
      src: null,
    };
  },

  created() {
    this.isLoading = true;
    this.getInternalDocuments();
  },

  methods: {
    dateToStringWithHour,
    async getInternalDocuments() {
      this.isLoading = true;
      await axios
        .get(
          "/internal_observations/internal-documents/" +
            this.internal_observation_id +
            ".json"
        )
        .then((response) => {
          this.documents = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    openModalShowDocument(id_document_drive) {
      this.src = id_document_drive;
      this.isModalShowDocumentActive = true;
    },
    downloadFile(document_id) {
      this.isLoading = true;

      axios
        .request({
          url: "/documents/download_file/" + document_id,
          responseType: "blob",
        })
        .then((response) => {
          //var blobUrl = URL.createObjectURL(response.data);
          //window.open(blobUrl)

          let info = response.headers["content-disposition"];
          let name = decodeURIComponent(info.split('"')[1])
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        });
    },
  },
};
</script>
