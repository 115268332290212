<template>
    <section>
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head has-background-secondary">
                <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
            </header>
            <section class="modal-card-body" ref="ModalCreateState">
                <iframe :src="documentPreviewLink" width="900" height="600" frameborder="0" marginheight="0"
                        marginwidth="0">Cargando…
                </iframe>
            </section>
        </div>

        <footer class="modal-card-foot actions has-background-secondary">
            <div class="field is-grouped is-pulled-right">
                <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            </div>
        </footer>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
export default {
    name: "ModalShowDocument",
    props: ["modalHeader", "documentId"],
    components: {},
    data: function () {
        return {
            validationErrors: {},
            isLoading: false,
            documentPreviewLink: ""
        }
    },

    created() {
        this.documentPreviewLink = "https://drive.google.com/file/d/" + this.documentId + "/preview";
    },

    methods: {}
}
</script>