<template>
    <div>
        <section class="pt-6">
            <div class="columns is-centered">
                <div class="column is-three-quarters has-background-white">
                    <!-- Titulo de la sección -->
                    <div class="columns is-vcentered is-multiline">
                        <div class="column is-four-fifths ">
                            <h1 class="subtitle is-1 has-text-secondary">
                                Administración de usuarios
                            </h1>

                        </div>
                        <div class="column is-one-fifth">
                          <b-button type="is-success is-light is-border-darkgreen  mr-6"  @click="isModalAddUserActive = true"
                                    icon-left="plus">Añadir Usuario
                          </b-button>
                        </div>
                        <div class="column is-full has-text-right ">
                            <div class="columns is-multiline">
                              <div class="column is-narrow is-pulled-left">
                                <b-button v-if = "!siacStatus" type="is-info is-light is-border-cornflowerblue  mr-1"  @click="confirmToggleAccessSIAC"
                                          icon-left="plus">Activar creacion cuentas por SIAC
                                </b-button>
                                <b-button v-else type="is-danger is-light is-border-darkred mr-1"  @click="confirmToggleAccessSIAC"
                                          icon-left="times">Desactivar creacion cuentas por SIAC
                                </b-button>
                                <p v-if="siacStatus && siacStatus!=totalAccessStatus" class="has-text-danger has-text-left">
                                  <b>Active el acceso de estudiantes para <br>habilitar a creación de cuentas</b>
                                </p>
                              </div>
                              <div class="column is-narrow">
                                <b-button v-if = "!totalAccessStatus" type="is-info is-light is-border-cornflowerblue  mr-1"  @click="confirmToggleAccessStudents"
                                          icon-left="plus">Activar acceso de estudiantes
                                </b-button>
                                <b-button v-else type="is-danger is-light is-border-darkred mr-1"  @click="confirmToggleAccessStudents"
                                          icon-left="times">Desactivar  acceso de estudiantes
                                </b-button>
                              </div>

                              <div class="column is-pulled-right">
                                <b-button tag ="a"
                                          href="/dashboard/student-management"
                                          type="is-link"
                                          icon-left="user"

                                >Administración de estudiantes
                                </b-button>
                              </div>
                            </div>
                        </div>
                    </div>
                    <!-- Separador -->
                    <hr class="solid">
                </div>
            </div>
            <div class="columns is-centered is-multiline ">
                <div class="column is centered is-three-quarters has-background-white">
                    <b-table
                            :data="users"
                            :paginated="isPaginated"
                            :per-page="perPage"
                            :current-page.sync="currentPage"
                            :pagination-position="paginationPosition"
                            :default-sort-direction="defaultSortDirection"
                            :sort-icon="sortIcon"
                            :sort-icon-size="sortIconSize"
                            default-sort="created_at"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            selectable
                            @row-selected="openEditUserModal"
                    >
                        <b-table-column field="id" label="ID" width="100" sortable searchable>
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por código"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template  v-slot="props">
                            {{props.row.id}}
                          </template>

                        </b-table-column>
                        <b-table-column field="full_name" label="Nombre Completo" sortable searchable>
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por nombre"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template  v-slot="props">
                            {{ props.row.full_name }}
                          </template>

                        </b-table-column>
                        <b-table-column field="email" label="Correo Corporativo" sortable searchable>
                          <template slot="searchable" slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder="Buscar por email"
                                icon="search"
                                size="is-small"
                            />
                          </template>
                          <template  v-slot="props">
                            {{ props.row.email }}
                          </template>

                        </b-table-column>
                        <b-table-column field="created_at" label="Fecha de Creación" sortable :custom-sort="customSortCreatedAt" v-slot="props">
                            {{ props.row.created_at }}
                        </b-table-column>
                        <b-table-column label="Cargos disponibles" v-slot="props" width="30%">
                          <div class="columns">
                            <div class="column">
                              <div v-if="props.row.internal_positions.length!==0">
                                <multiselect v-model="props.row.cargo_activo" :options="props.row.internal_positions"
                                             :custom-label="opt => props.row.internal_positions.find(x => x.id === opt.id) != null ? props.row.internal_positions.find(x => x.id === opt.id) .name:null "
                                             :multiple="false"
                                             placeholder="Seleccione un rol" selectLabel="Presione para seleccionar"
                                             selectedLabel="Seleccionado"
                                             deselectLabel="Presione para deseleccionar"
                                             track-by="id"
                                             label="name">
                                  <template v-slot:noOptions>
                                    No existen datos
                                  </template>
                                  <span slot="noResult">
                                            No se encontraron elementos.
                                          </span>
                                </multiselect>
                              </div>
                              <div v-else>
                                NO POSEE CARGOS
                              </div>
                            </div>

                          </div>


                        </b-table-column>
                        <b-table-column label="Acciones" v-slot="props" width="25%">
                            <b-button type="is-secondary" @click="openEditUserModal(props.row)"
                                      icon-left="edit">Editar usuario y roles
                            </b-button>
                          <b-button v-if="!props.row.disabled" class= "mr-1 is-danger is-light is-border-darkred" @click="disableUser(props.row.id,props.row.disabled)">
                            <b-icon            type="is-danger"
                                               icon="user-times"
                                               size="is-medium"

                            >
                            </b-icon>
                          </b-button>
                          <b-button v-else class= "mr-1 is-success is-light is-border-darkgreen" @click="disableUser(props.row.id,props.row.disabled)">
                            <b-icon            type="is-success"
                                               icon="user-check"
                                               size="is-medium"

                            >
                            </b-icon>
                          </b-button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
          <b-modal
              v-model="isModalActive"
              trap-focus
              :destroy-on-hide="true"
              aria-role="dialog"
              aria-modal
              width="80%"
          >
            <template #default="props">
            <modal-edit-user v-if="roles.length>0" :usuario="userSelect"
                           :assignedInternalPositions="roles" @close ="closeModalEditInfo()"
            ></modal-edit-user>
              </template>
          </b-modal>
          <b-modal  has-modal-card v-model="isModalInternalPositionActive"
                    trap-focus
                    :destroy-on-hide="true"
                    aria-role="dialog"
                    aria-modal
                    :can-cancel="true"
                    width="100%"
          >
            <template #default="props">
              <modal-assign-cargo :user_id="userSelect.id" :internal_positions="all_internal_positions"
                                @close="props.close;closeModalInternalPosition()"
                                  style="width: 90vw">

              </modal-assign-cargo>
            </template>
          </b-modal>
          <b-modal  v-model="isModalAddUserActive"
                    trap-focus
                    :destroy-on-hide="true"
                    aria-role="dialog"
                    aria-modal
                    width="80%"
          >
            <template #default="props">
              <modal-create-user
                                  @close="isModalAddUserActive = false;getUserInfo();">
              </modal-create-user>
            </template>
          </b-modal>


        </section>


        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>

import axios from "axios";
import moment from "moment/moment";
import ModalEditUser from './ModalEditUser.vue'
import ModalAssignCargo from './ModalAssignCargo.vue'
import ModalCreateUser from './ModalCreateUser.vue'
import {openBuefyAlertDialog, openBuefyConfirmDialog} from "../../packs/utilities";

export default {
    name: "UserManagement",
    data() {
        return {
            validationErrors: {},
            isPaginated: true,
            paginationPosition: 'bottom',
            defaultSortDirection: 'desc',
            sortIcon: 'arrow-up',
            sortIconSize: 'is-small',
            currentPage: 1,
            perPage: 10,
            isLoading: false,
            isModalActive: false,
           isModalAddUserActive: false,
            roles: [],
            corporative_email: "",
            selectedCargo: {
                id: "",
                name: ""
            },
            internalPositions: [],
            userSelect:
                {
                    full_name: "",
                    name:"",
                    last_name:"",
                  sur_name:"",
                    email: "",
                    created_at: ""
                },
            users: [],
            all_internal_positions: [],
            isModalInternalPositionActive: false,
            siacStatus:false,
          totalAccessStatus:false,

        }
    },
    created() {
      document.title="Administración de usuarios"
        this.getUserInfo();
        this.getInternalPositions()


    },
    components: {
      ModalCreateUser,
        ModalEditUser,
        ModalAssignCargo
        //ModalCreateUser
    },
    methods: {
      getInternalPositions(){
        this.isLoading=true;
        axios.get('/internal_positions.json')
            .then(response => {
              this.all_internal_positions = response.data

            })
            .catch(error => {
              //console.log(error);
              this.all_internal_positions = [];
            }).finally(()=> {
              this.isLoading = false
            }
        );
      },
      getUserInfo(){
        this.isLoading=true
        axios.get('/dashboard/user-management.json')
            .then(response => {
              this.users = response.data.users
              this.siacStatus = response.data.siac_status
              this.totalAccessStatus = response.data.total_access_status
              this.users = this.users.map(user => {
                var newUser = user
                newUser["created_at"] = moment(newUser["created_at"], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
                /*
                if(newUser["cargo_activo"].id==null && newUser["internal_positions"].length!=0){
                  newUser["cargo_activo"]=newUser["internal_positions"][0]
                }*/
                return newUser;
              });
              this.userSelect = this.users[0]
            })
            .catch(error => {
              //console.log(error);
              this.user = [];
            }).finally(()=>{
              this.isLoading=false;
        });
      },
        openAssignInternalPosition(user) {
            this.isModalInternalPositionActive = true;
            this.userSelect = user;
        },
      confirmToggleAccessStudents() {
        let title = "Deshabilitar ingreso de estudiantes"
        let message = "Esta acción no permitirá que los estudiantes entren a la plataforma ni creen cuentas por API SIAC."
        if (!this.totalAccessStatus) {
          title = "Habilitar ingreso de estudiantes"
          message = "Esta acción habilitará el acceso y la creación de usuarios por API SIAC."
        }
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          onConfirm: () => {
            this.isLoading = true
            axios
                .post("/users/access-control/toggle-access/total")
                .then(() => {

                      openBuefyConfirmDialog(this, "Listo", "Se ha cambiado el acceso")
                      window.location.reload()
                    }
                ).catch(() => {
                  openBuefyAlertDialog(this, "Error", "No se ha podido " + title.toLowerCase())
                }
            ).finally(() => {
                  this.isLoading = false
                }
            );
          }
        });
      },
      confirmToggleAccessSIAC() {
        let title = "Deshabilitar la creación de cuentas"
        let message = "Esta acción deshabilitará la creación de nuevos usuarios por <b>API SIAC</b>, sin embargo, los usuarios estudiantes que ya hayan entrado pueden seguir haciendolo."
        if (!this.siacStatus) {
          title = "Habilitar la creación de cuentas"
          message = "Esta acción habilitará la creación de nuevos usuarios por <b>API SIAC</b>."
        }
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          onConfirm: () => {
            this.isLoading = true
            axios
                .post("/users/access-control/toggle-access/siac")
                .then(() => {

                      openBuefyConfirmDialog(this, "Listo", "Se ha cambiado el acceso")
                      window.location.reload()


                    }
                ).catch(() => {
                  openBuefyAlertDialog(this, "Error", "No se ha podido " + title.toLowerCase())
                }
            ).finally(() => {
                  this.isLoading = false
                }
            );
          }
        });
      },
      closeModalEditInfo(){
        this.isModalActive=false;
        this.getUserInfo();
        this.getCargos(this.userSelect);
      },
      closeModalInternalPosition(){
        this.isModalInternalPositionActive=false;
        this.getUserInfo();

      },
        openEditUserModal(user_selected) {
            this.isModalActive = true;
            this.userSelect = user_selected;
            this.getCargos(user_selected);
        },

        getCargos(user) {

            axios.get(`/user_internal_positions/user/${user.id}.json`)
                .then(response => {
                    this.roles = response.data
                    this.roles = this.roles.map(role => {
                    var newRoles = role
                    newRoles["start_date"] =  newRoles["start_date"] !== null ? new Date(newRoles["start_date"]) : null;
                    newRoles["end_date"] =  newRoles["end_date"] !== null ? new Date(newRoles["end_date"]) : null;
                    return newRoles;
                    });


                })
                .catch(error => {
                    console.log(error);

                });

        },

        formatDate(date) {
            if (date != null && date !== "" && date !== undefined) {
                var arreglo = date.split("-")
                return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0]
            } else {
                return ""
            }
        },
      disableUser(userId,disabled) {
        let title = "Deshabilitar usuario"
        let message = "Esta acción deshabilitará al usuario."
        if (disabled) {
          title = "Habilitar usuario"
          message = "Esta acción habilitará al usuario."
        }
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          onConfirm: () =>{
            this.isLoading=true
            axios
                .post("/users/toggle-access/"+userId)
                .then(()=> {

                      openBuefyConfirmDialog(this,"Listo","Se ha cambiado el acceso")
                      window.location.reload()
                    }
                ).catch( () => {
                  openBuefyAlertDialog(this,"Error","No se ha podido "+ title.toLowerCase())
                }
            ).finally(()=>{
                  this.isLoading=false
                }
            );
          }
        });
      },
      compareDate(a, b) {
        if (a == null) {
          return -1;
        }
        if (b == null) {
          return 1;
        }
        const dateA = new Date(a.split('-').reverse().join('-'));
        const dateB = new Date(b.split('-').reverse().join('-'));
        return dateA - dateB;
      },

      customSortCreatedAt(a, b, isAsc) {
        return isAsc ? this.compareDate(a.created_at, b.created_at) : this.compareDate(b.created_at, a.created_at);
      },
    }

}
</script>