<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(uploadFiles)">
                <!-- Box de Formulario Appointment -->
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head has-background-secondary">
                        <p class="modal-card-title has-text-white">{{ modalHeader }}</p>
                        <button
                                type="button"
                                class="delete"
                                @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body" ref="ModalCreateState">
                        <!--------------------------------------------------------------------------------------------------------->
                        <!-- Section to upload files -->
                        <div class="mt-4">
                            <ValidationProvider rules="required" v-slot="{errors}">
                                <b-field>
                                    <b-upload v-model="dropFiles" drag-drop>
                                        <section class="section">
                                            <div class="content has-text-centered">
                                                <p>
                                                    <b-icon icon="upload" size="is-large">
                                                    </b-icon>
                                                </p>
                                                <p>Arrastre sus archivos aquí o haga click para subirlos</p>
                                            </div>
                                        </section>
                                    </b-upload>
                                </b-field>
                                <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div v-if="dropFiles !== null" class="tags">
                <span class="tag is-secondary">
                  {{ dropFiles.name }}
                </span>
                            </div>
                        </div>
                    </section>
                </div>
                <footer class="modal-card-foot actions has-background-secondary">
                    <div class="field is-grouped is-pulled-right">
                        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                        <button class="button is-primary" type="submit">Subir</button>
                    </div>
                </footer>
            </form>
        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";
import {verifyFile} from "../../packs/utilities";

export default {
    name: "ModalUploadDocument",
    props: ["modalHeader", "request_state_id", "request_id", "document_id", "multiple"],
    components: {},
    data: function () {
        return {
            validationErrors: {},
            dropFiles: null,
            isLoading: false,
        }
    },

    created() {
    },

    methods: {
        deleteDropFile(index) {
            this.dropFiles.splice(index, 1)
        },
        uploadFiles() {
            /*VERIFICACION FRON ARCHIVOS*/
            for (let doc in this.dropFiles) {
                let error = verifyFile(doc);
                if (error) {
                    this.$buefy.dialog.alert({
                        title: 'Error en archivos',
                        message: `Error en: ${doc.name}: ${error}`,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                    return;
                }

            }
            this.isLoading = true;
            console.log("uploading files...");
            let formData = new FormData();
            formData.append("request_state_id", this.request_state_id);
            formData.append("document_id", this.document_id);
            formData.append("doc", this.dropFiles)
            axios
                .post("/documents/upload_to_request_state.json", formData).then(
                response => {
                    this.$buefy.dialog.confirm({
                        title: 'Listo',
                        message: 'Los archivos se han subido exitosamente.',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'check-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true,
                        canCancel: false,
                        onConfirm: () => {
                            window.location.href = "/requests/" + this.request_id
                        }
                    });
                    this.isLoading = false;
                })
                .catch(error => {
                    this.validationErrors = error.response.data
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: this.validationErrors.message,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                    this.isLoading = false;
                });

        }
    }
}
</script>