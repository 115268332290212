var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('section',[(_vm.canAppeal())?_c('div',[_c('div',{staticClass:"columns is-multiline pt-2"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"column is-full"},[(_vm.appeals.length != 0)?_c('b-field',[_c('b',[_vm._v("Última observación:")]),_vm._v(" "+_vm._s(_vm.lastObservation())+"\n            ")]):_c('b-field',[_c('b',[_vm._v("Observación inicial de la detención:")]),_vm._v("\n              "+_vm._s(_vm.initial_observation)+"\n            ")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{attrs:{"label":"Escriba una respuesta a la detención*"}},[_c('b-input',{staticClass:"mx-2 pt-2",attrs:{"type":"textarea","maxlength":"500","placeholder":"Escriba aquí su respuesta a la detención..."},model:{value:(_vm.inputObservation),callback:function ($$v) {_vm.inputObservation=$$v},expression:"inputObservation"}})],1),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2919089066)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns is-multiline is-centered"},[_c('div',{staticClass:"column is-half has-text-centered"},[_c('b-upload',{attrs:{"size":"is-large","expanded":""},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}},[_c('a',{staticClass:"button is-secondary"},[_c('b-icon',{attrs:{"icon":"upload"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.document.name ||
                  "Click para subir un documento de respuesta a la detención"))])],1)])],1),_vm._v(" "),_c('div',{staticClass:"column is-half has-text-right"},[_c('b-button',{attrs:{"type":"is-success","icon-left":"file-import","disabled":!_vm.canAppeal() || _vm.disableButton || _vm.inputObservation === ''},on:{"click":_vm.confirmObservation}},[_vm._v("\n              Enviar respuesta\n            ")])],1),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('hr',{staticClass:"solid"})]):_vm._e(),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('b-table',{attrs:{"paginated":_vm.appeals.length < 5 ? false : true,"pagination-simple":false,"pagination-position":"bottom","current-page":_vm.currentPageTable,"per-page":"5","data":_vm.appeals,"bordered":false,"striped":true,"narrowed":false,"hoverable":true,"focusable":false,"mobile-cards":true}},[_c('b-table-column',{attrs:{"field":"applicant_observation","visible":true,"label":"Comentario solicitante"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v("\n          "+_vm._s(props.row.applicant_observation)+"\n        ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"reviewer_observation","visible":true,"label":"Comentario revisor"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v("\n          "+_vm._s(props.row.reviewer_observation != null
              ? props.row.reviewer_observation
              : "La corrección aún no ha sido contestada")+"\n        ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"centered":"","field":"is_accepted","visible":true,"label":"¿Aceptado?"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.reviewer_observation === null)?_c('b-tag',{attrs:{"icon":"clock","type":"is-warning","size":"is-medium","rounded":""}},[_vm._v("\n            Pendiente\n          ")]):(props.row.is_accepted)?_c('b-tag',{attrs:{"icon":"check","type":"is-success","size":"is-medium","rounded":""}},[_vm._v("\n            Sí\n          ")]):_c('b-tag',{attrs:{"icon":"times","type":"is-danger","size":"is-medium","rounded":""}},[_vm._v("\n            No\n          ")])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"id_drive","visible":true,"label":"Documento subido"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.id_drive !== null)?_c('b-button',{staticClass:"is-centered",attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.downloadFile(props.row.id_drive)}}},[_vm._v("\n            Descargar "),_c('i',{staticClass:"fas fa-download ml-1"})]):_c('div',[_vm._v("Sin Documento")])]}}])})],1)],1)]),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-full has-text-centered"},[_c('h1',{staticClass:"subtitle is-3 has-text-secondary"},[_c('b',[_vm._v("Información de la detención")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-full"},[_c('i',[_c('b',[_vm._v("Extensiones permitidas:")]),_vm._v(" '.pdf', '.doc', '.docx', '.png',\n              '.jpg', '.jpeg' "),_c('b',[_vm._v("Tamaño máximo:")]),_vm._v(" 10MB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns is-multiline pt-2"},[_c('div',{staticClass:"column is-full has-text-centered"},[_c('h1',{staticClass:"subtitle is-3 has-text-secondary"},[_vm._v("\n            Historial de respuestas\n          ")])])])
}]

export { render, staticRenderFns }