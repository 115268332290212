<template>
  <div class="container box my-5">
    <b-loading v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="title has-text-left">
      Gestión de estudiantes con correo alternativo habilitado
    </div>
    <hr class="solid" />

    <b-table
      :data="students"
      paginated
      per-page="10"
      striped
      hoverable
      default-sort-direction="asc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Página actual"
    >
      <b-table-column field="rut" label="RUT" searchable sortable>
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder=" Buscar"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.rut }}
        </template>
      </b-table-column>
      <b-table-column
        field="full_name"
        label="Nombre Completo"
        searchable
        sortable
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder=" Buscar"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.full_name }}
        </template>
      </b-table-column>
      <b-table-column
        field="email"
        label="Correo Institucional"
        searchable
        sortable
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder=" Buscar"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.email }}
          <b-tooltip
            v-if="props.row.corporate_email_active"
            label="El estudiante puede acceder al sistema SAS solo desde su correo institucional."
            multilined
            type="is-info"
          >
            <b-icon icon="check-circle" type="is-info"></b-icon>
          </b-tooltip>
        </template>
      </b-table-column>
      <b-table-column
        field="alt_email"
        label="Correo Alternativo"
        searchable
        sortable
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder=" Buscar"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.alt_email }}
          <b-tooltip
            v-if="!props.row.corporate_email_active"
            label="El estudiante puede acceder al sistema SAS solo desde su correo alternativo."
            multilined
            type="is-info"
          >
            <b-icon icon="check-circle" type="is-info"></b-icon>
          </b-tooltip>
        </template>
      </b-table-column>
      <b-table-column label="Acciones" width="19em" v-slot="props">
        <b-button
          v-if="!props.row.corporate_email_active"
          icon-left="university"
          class="is-warning is-light is-border-darkgoldenrod"
          @click="onSwitchEmailActive(props.row, 'institucional')"
          >Activar Correo Institucional</b-button
        >
        <b-button
          v-else
          icon-left="user"
          class="is-warning is-light is-border-darkgoldenrod"
          @click="onSwitchEmailActive(props.row, 'personal')"
          >Activar Correo Alternativo</b-button
        >
        <b-button
          icon-left="pen"
          class="is-success is-light is-border-darkgreen"
          @click="editStudent(props.row)"
        ></b-button>
      </b-table-column>
    </b-table>
    <b-modal
      v-model="showEditModal"
      trap-focus
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="100%"
    >
      <template #default="props">
        <div style="width: 80vw">
          <div class="container">
            <section style="width: auto">
              <div class="modal-card" style="width: auto; height: auto">
                <header class="modal-card-head has-background-secondary">
                  <p class="modal-card-title has-text-white">
                    Información del estudiante
                  </p>
                  <button type="button" class="delete" @click="onCloseModal" />
                </header>
              </div>
              <section class="modal-card-body">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(patchEnabledStudent)">
                    <div class="my-3">
                      <b>Nombre completo:</b> {{ studentSelected.full_name }}
                    </div>
                    <div class="my-3">
                      <b>RUT:</b> {{ studentSelected.rut }}
                    </div>
                    <div class="my-3">
                      <b>Correo institucional:</b> {{ studentSelected.email }}
                    </div>
                    <div class="my-3">
                      <b>Correo alternativo:</b> {{ studentSelected.alt_email }}
                    </div>
                    <div class="my-3"><b>Carreras cursadas:</b></div>
                    <ul class="ml-6">
                      <li v-for="career in studentCareers" class="my-3">
                        • {{ formatCareerName(career) }}
                        <b-icon
                          v-if="career.shift === 'Diurno'"
                          class="mr-1"
                          icon="sun"
                        ></b-icon>
                        <b-icon
                          v-else-if="career.shift === 'Vespertino'"
                          class="mr-1"
                          icon="moon"
                        ></b-icon>
                        <b-icon v-else class="mr-1" icon="question"></b-icon>
                        Ingreso {{ career.entry_semester }}-{{
                          career.entry_year
                        }}
                      </li>
                    </ul>
                    <div class="mt-6">
                      <b-field
                        label="Cambiar correo habilitado de este estudiante*"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            email: true,
                          }"
                        >
                          <b-input
                            v-model="email"
                            placeholder="Ej: nombre.apellido@gmail.com"
                          ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                      <b-field label="Confirmar correo ingresado*">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            email: true,
                            same_as: email,
                          }"
                        >
                          <b-input
                            v-model="emailConfirmation"
                            placeholder="Ej: nombre.apellido@gmail.com"
                          ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                      <div class="column has-text-right">
                        <b-button
                          class="has-text-right"
                          icon-left="check-circle"
                          native-type="submit"
                          type="is-success"
                          >Habilitar correo
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </section>
              <div class="modal-card-foot has-background-secondary">
                <div class="field is-grouped is-pulled-right">
                  <button class="button" type="button" @click="onCloseModal">
                    Cerrar
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { email } from "vee-validate/dist/rules";
export default {
  data() {
    return {
      students: [],
      isLoading: false,
      showEditModal: false,
      studentSelected: null,
      studentCareers: [],
      email: "",
      emailConfirmation: "",
    };
  },
  created() {
    this.fetchEnabledStudents();
  },
  methods: {
    fetchEnabledStudents() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/people/enabled.json")
        .then((response) => {
          vm.students = response.data.students;
        })
        .catch((error) => {
          console.log(error);
          vm.$buefy.alert.open({
            title: "Error",
            hasIcon: true,
            message: "Error al cargar estudiantes habilitados",
            type: "is-danger",
          });
        })
        .then(() => {
          vm.isLoading = false;
        });
    },
    editStudent(student) {
      this.studentSelected = student;
      this.fetchStudentInfo();
      this.showEditModal = true;
    },
    onCloseModal() {
      this.showEditModal = false;
      this.studentCareers = [];
      this.studentSelected = null;
      this.email = "";
      this.emailConfirmation = "";
    },
    patchEnabledStudent() {
      let vm = this;
      vm.isLoading = true;
      axios
        .patch(`/people/enabled/${vm.studentSelected.id}/change-email.json`, {
          personal_email: vm.email,
        })
        .then((response) => {
          vm.$buefy.dialog.alert({
            title: "¡Éxito!",
            message: "Correo actualizado con éxito.",
            type: "is-success",
            hasIcon: true,
          });
          vm.onCloseModal();
          vm.fetchEnabledStudents();
        })
        .catch((error) => {
          console.log(error);
          vm.$buefy.dialog.alert({
            title: "Error",
            message: "Error al actualizar correo habilitado.",
            type: "is-danger",
            hasIcon: true,
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    fetchStudentInfo() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/people/enable-email.json", {
          params: {
            rut: vm.studentSelected.rut.split("-")[0],
          },
        })
        .then((response) => {
          vm.studentCareers = response.data.careers;
        })
        .catch((error) => {
          console.log(error);
          vm.$buefy.alert.open({
            title: "Error",
            hasIcon: true,
            message: "Error al cargar información del estudiante",
            type: "is-danger",
          });
        })
        .then(() => {
          vm.isLoading = false;
        });
    },
    onSwitchEmailActive(student, emailType) {
      this.$buefy.dialog.confirm({
        title: "Confirmar acción",
        message: `¿Deseas activar el correo ${emailType} para el estudiante <b>${student.full_name}</b>? <br> Esta acción provoca que el <b>correo ${emailType}</b> sea el único medio de acceso y notificaciones del sistema SAS.`,
        confirmText: "Sí",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          this.patchSwitchCorporateEmailActive(student, emailType);
        },
      });
    },
    patchSwitchCorporateEmailActive(student, emailType) {
      let vm = this;
      vm.isLoading = true;
      axios
        .patch(`/people/enabled/${student.id}/switch-active-email.json`)
        .then((response) => {
          vm.$buefy.dialog.alert({
            title: "¡Éxito!",
            message: `Se activó el correo ${emailType} con éxito.`,
            type: "is-success",
            hasIcon: true,
          });
          vm.fetchEnabledStudents();
        })
        .catch((error) => {
          console.log(error);
          vm.$buefy.dialog.alert({
            title: "Error",
            message: `Error al activar el correo ${emailType}.`,
            type: "is-danger",
            hasIcon: true,
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    formatCareerName(career) {
      let jornada = career.shift;
      if (career.shift === "") jornada = "Jornada indefinida";
      return `[${career.career_code}] ${career.career_name} - ${career.type}, ${jornada} `;
    },
  },
};
</script>
