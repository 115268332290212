<template>
  <section>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <!-- Box de Formulario Appointment -->
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head has-background-secondary" style="">
        <p class="modal-card-title has-text-white" style="">
          Documentos de {{ requestTypeNameHeader }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" ref="ModalViewDocuments">
        <div
          class="my-2"
          v-for="(document, documentIndex) in previewDocuments"
          :key="documentIndex"
        >
          <div class="columns is-centered is-vcentered">
            <div class="column has-text-centered">
              <b-button
                type="is-primary"
                icon-left="download"
                @click="downloadFile(documentIndex)"
              >
                Descargar plantilla
              </b-button>
            </div>
          </div>
          <DocumentPrevisulizer
            :src="document"
            :pageCount="pageCounts[documentIndex]"
            :fileName="fileNames[documentIndex]">
          </DocumentPrevisulizer>

        </div>
      </section>
      <footer class="modal-card-foot actions has-background-secondary">
        <div class="field is-grouped is-pulled-right">
          <button class="button" type="button" @click="$emit('close')">
            Cerrar
          </button>
        </div>
      </footer>
    </div>

  </section>
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";
import DocumentPrevisulizer from "./DocumentPrevisulizer.vue";


export default {
  name: "ModalShowMultipleDocuments",
  props: ["requestTypeNameHeader", "requestTypeId"],
  components: {
    DocumentPrevisulizer,
    pdf,
  },
  data: function () {
    return {
      validationErrors: {},
      isLoading: true,
      requestType: {
        templates: [],
      },
      documents: [],
      previewDocuments: [],
      pageCounts: [],
      currentPages: [],
      fileNames: [],
    };
  },

  created() {
    this.getDocumentsFromRequestType();
  },

  methods: {
    setNumPages(index, event) {
      if (event === undefined) return;
      console.log("event", event, typeof event)
      this.pageCounts[index] = event;
    },
    async getDocumentsFromRequestType() {
      await axios
        .get("/request_types/" + this.requestTypeId + ".json")
        .then((response) => {
          this.requestType = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.requestType = null;
        })
        .finally(() => {
          this.downloadFiles();
        });
    },
    async downloadFiles() {
      for (let i = 0; i < this.requestType.templates.length; i++) {
        await this.downloadPreviewFile(
          this.requestType.templates[i].id_document_drive
        );
      }
      this.isLoading = false;
    },
    downloadFile(documentId) {
      let vm = this;
      vm.isLoading = true;
      axios
        .get(
          "/documents/download_file/" +
            this.requestType.templates[documentId].id_document_drive,
          {
            responseType: "blob",
          }
        )
        .then(async (response) => {
          let info = response.headers["content-disposition"];
          let name = info.split('"')[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          vm.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          vm.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    async downloadPreviewFile(idDrive) {
      let vm = this;
      return axios
        .get("/documents/download_pdf_file/" + idDrive, {
          responseType: "blob",
        })
        .then(async (response) => {
          const file = new File([new Blob([response.data])], document.name);
          const buffer = await file.arrayBuffer();
          vm.previewDocuments.push(new Uint8Array(buffer));
          let info = response.headers["content-disposition"];
          let name = decodeURIComponent(info.split('"')[1]);
          vm.pageCounts.push(1);
          vm.fileNames.push(name);
          vm.currentPages.push(1);
        })
        .catch((e) => {
          vm.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        });
    },
    backPage(documentIndex) {
      if (this.pageCounts[documentIndex] === null) return;
      if (this.currentPages[documentIndex] - 1 < 1) return;
      this.currentPages[documentIndex] -= 1;
    },
    nextPage(documentIndex) {
      if (this.pageCounts[documentIndex] === null) return;
      if (this.currentPages[documentIndex] + 1 > this.pageCounts[documentIndex])
        return;
      this.currentPages[documentIndex] += 1;
    },
  },
};
</script>
