import { render, staticRenderFns } from "./accordion.vue?vue&type=template&id=28cb3b79&scoped=true"
import script from "./accordion.vue?vue&type=script&lang=js"
export * from "./accordion.vue?vue&type=script&lang=js"
import style0 from "./accordion.vue?vue&type=style&index=0&id=28cb3b79&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28cb3b79",
  null
  
)

export default component.exports